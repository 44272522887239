import {SelectInput, useGetList} from "react-admin";
import React from "react";

export const InstitutionFilterSelectInput = ({ label, source }) => {
    let resource = 'institutions';

    if (source) {
        resource = source + '/' + resource;
    }

    const { data: institutions, isLoading} = useGetList(
        resource,
        {
            pagination: {page: 1, perPage: 1000},
            sort: { field: "name", order: "ASC"}
        }
    );

    return (
        <SelectInput
            key={Math.random()}
            label={label}
            choices={institutions}
            name="institution_id"
            source="institution_id"
            isLoading={isLoading}
            alwaysOn={true}
        />
    );
};