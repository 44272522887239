import {
    List,
    DateField,
    NumberField,
} from "react-admin";
import React from "react";
import {EmptyList} from "../../../../Components/EmpyList";
import {PageTitle} from "../../../../Components/PageTitle";
import {Datagrid} from "../../../../Components/Datagrid";

export const UserPaymentList = () => {
    return (
        <List
            title={<PageTitle label="Payments" />}
            resource="user/payments"
            sort={{ field: 'created_at', order: 'DESC' }}
            hasCreate={false}
            empty={<EmptyList resourceLabel="Payments" />}
            exporter={false}
        >
            <Datagrid bulkActionButtons={false} rowClick={false}>
                <NumberField source="amount" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} />
                <DateField showTime={true} source="created_at" label="Created"  />
                <DateField showTime={true} source="updated_at" label="Updated"  />
            </Datagrid>
        </List>
    );
};