import * as React from "react";
import {Admin, Resource, defaultTheme, Loading} from 'react-admin';
import appPackage from '../package.json';

import authProvider from './Providers/authProvider';
import dataProvider from "./Providers/dataProvider";

import MyLoginPage from './Pages/MyLoginPage';
import Dashboard from './Pages/Dashboard/Dashboard';

import MyLayout from './Layouts/MyLayout';

import {AnnouncementList, AnnouncementShow, AnnouncementEdit, AnnouncementCreate} from "./Pages/Admin/Announcements/Announcements";
import {BranchList, BranchShow, BranchEdit, BranchCreate} from "./Pages/Admin/Entities/Branches";
import {BranchCreditList, BranchCreditShow, BranchCreditEdit, BranchCreditCreate} from "./Pages/Admin/Billing/BranchCredits";
import {ExpandedIvesRequestList, ExpandedIvesRequestShow} from "./Pages/Admin/IVES/ExpandedIvesRequests";
import {InstitutionList, InstitutionShow, InstitutionEdit, InstitutionCreate} from "./Pages/Admin/Entities/Institutions";
import {InstitutionCreditList, InstitutionCreditShow, InstitutionCreditEdit, InstitutionCreditCreate} from "./Pages/Admin/Billing/InstitutionCredits";
import {InvoiceList, InvoiceShow, InvoiceEdit, InvoiceCreate} from "./Pages/Admin/Billing/Invoices";
import {IvesRequestList, IvesRequestShow} from "./Pages/Admin/IVES/IvesRequests";
import {LogsList, LogsShow} from "./Pages/Admin/Advanced/Logs";
import {PaymentList, PaymentShow, PaymentEdit, PaymentCreate} from "./Pages/Admin/Billing/Payments";
import {ReportsList} from "./Pages/Admin/Reports/Reports";
import {SettingList, SettingEdit} from "./Pages/Admin/Advanced/Settings";
import {UserList, UserShow, UserEdit, UserCreate} from "./Pages/Admin/Entities/Users";

import {AffiliateInstitutionList, AffiliateInstitutionShow} from "./Pages/Affiliate/Entities/AffiliateInstitutions";
import {AffiliateIvesRequestList, AffiliateIvesRequestShow} from "./Pages/Affiliate/IVES/AffiliateIvesRequests";

import {UserBranchCreate, UserBranchEdit, UserBranchList, UserBranchShow} from "./Pages/User/Admin/UserBranches";
import {UserBranchCreditList} from "./Pages/User/Admin/Billing/UserBranchCredits";
import {UserInstitutionCreditList} from "./Pages/User/Admin/Billing/UserInstitutionCredits";
import {UserInvoiceList, UserInvoiceShow} from "./Pages/User/Admin/Billing/UserInvoices";
import {UserIvesClientList, UserIvesClientShow, UserIvesClientCreate, UserIvesClientEdit} from "./Pages/User/IVES/UserIvesClients";
import {UserIvesRequestList, UserIvesRequestShow, UserIvesRequestCreate} from "./Pages/User/IVES/UserIvesRequests";
import {UserIvesSettingsShow, UserIvesSettingsEdit} from "./Pages/User/IVES/UserIvesSettings";
import {UserPaymentList} from "./Pages/User/Admin/Billing/UserPayments";
import {UserProfileShow, UserProfileEdit} from "./Pages/User/Profile";
import {UserUserList, UserUserShow, UserUserEdit, UserUserCreate} from "./Pages/User/Admin/UserUsers";
import {UserGuide} from "./Pages/User/UserGuide/UserGuide";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import StoreIcon from "@mui/icons-material/Store";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ReorderIcon from '@mui/icons-material/Reorder';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TuneIcon from '@mui/icons-material/Tune';
import ReceiptIcon from '@mui/icons-material/Receipt';
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import PaidIcon from '@mui/icons-material/Paid';
import CampaignIcon from '@mui/icons-material/Campaign';
import QuizIcon from '@mui/icons-material/Quiz';
import InsightsIcon from '@mui/icons-material/Insights';
import CacheBuster from "react-cache-buster";
import {QueryClient} from "react-query";

const theme = {
    ...defaultTheme,
    components: {
        ...defaultTheme.components,
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: 14
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: 13
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: 14
                }
            }
        },
    },
    sidebar: {
        width: 225,
        closedWidth: 55
    },
};

const App = () => {
    const cacheBusterEnabled = process.env.REACT_APP_ENVIRONMENT === 'production';

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    })

    return (
        <CacheBuster
            currentVersion={appPackage.version}
            isEnabled={cacheBusterEnabled}
            isVerboseMode={false}
            loadingComponent={<Loading />}
            metaFileDirectory={'.'}
        >
            <Admin
                title="TrueData"
                theme={theme}
                dashboard={Dashboard}
                layout={MyLayout}
                loginPage={MyLoginPage}
                dataProvider={dataProvider}
                authProvider={authProvider}
                queryClient={queryClient}
                requireAuth
                disableTelemetry
            >
                <Resource name="announcements" list={AnnouncementList} show={AnnouncementShow} edit={AnnouncementEdit} create={AnnouncementCreate} icon={CampaignIcon} />
                <Resource name="branches" list={BranchList} show={BranchShow} edit={BranchEdit} create={BranchCreate} icon={StoreIcon} />
                <Resource name="branch-credits" list={BranchCreditList} show={BranchCreditShow} edit={BranchCreditEdit} create={BranchCreditCreate} icon={MoneyOffIcon} />
                <Resource name="expanded-ives-requests" list={ExpandedIvesRequestList} show={ExpandedIvesRequestShow} icon={ReorderIcon} />
                <Resource name="institutions" list={InstitutionList} show={InstitutionShow} edit={InstitutionEdit} create={InstitutionCreate} icon={AccountBalanceIcon} />
                <Resource name="institution-credits" list={InstitutionCreditList} show={InstitutionCreditShow} edit={InstitutionCreditEdit} create={InstitutionCreditCreate} icon={MoneyOffIcon} />
                <Resource name="invoices" list={InvoiceList} show={InvoiceShow} edit={InvoiceEdit} create={InvoiceCreate} icon={ReceiptIcon} />
                <Resource name="ives-requests" list={IvesRequestList} show={IvesRequestShow} icon={ReorderIcon} />
                <Resource name="logs" list={LogsList} show={LogsShow} icon={FormatListBulletedIcon} />
                <Resource name="payments" list={PaymentList} show={PaymentShow} edit={PaymentEdit} create={PaymentCreate} icon={PaidIcon} />
                <Resource name="reports" list={ReportsList} icon={InsightsIcon} />
                <Resource name="settings" list={SettingList} edit={SettingEdit} icon={TuneIcon} />
                <Resource name="users" list={UserList} show={UserShow} edit={UserEdit} create={UserCreate} icon={ManageAccountsIcon} />

                <Resource name="affiliate/institutions" list={AffiliateInstitutionList} show={AffiliateInstitutionShow} icon={AccountBalanceIcon} options={{ label: "Institutions" }}/>
                <Resource name="affiliate/ives-requests" list={AffiliateIvesRequestList} show={AffiliateIvesRequestShow} icon={ReorderIcon} />

                <Resource name="user/branches" list={UserBranchList} show={UserBranchShow} edit={UserBranchEdit} create={UserBranchCreate} icon={StoreIcon} />
                <Resource name="user/branch-credits" list={UserBranchCreditList} icon={MoneyOffIcon} />
                <Resource name="user/institution-credits" list={UserInstitutionCreditList} icon={MoneyOffIcon} />
                <Resource name="user/invoices" list={UserInvoiceList} show={UserInvoiceShow} icon={ReceiptIcon} />
                <Resource name="user/ives-clients" list={UserIvesClientList} show={UserIvesClientShow} create={UserIvesClientCreate} edit={UserIvesClientEdit} icon={ReorderIcon} />
                <Resource name="user/ives-requests" list={UserIvesRequestList} show={UserIvesRequestShow} create={UserIvesRequestCreate} icon={ReorderIcon} />
                <Resource name="user/ives-settings" show={UserIvesSettingsShow} edit={UserIvesSettingsEdit} icon={ReorderIcon} />
                <Resource name="user/payments" list={UserPaymentList} icon={PaidIcon} />
                <Resource name="user/profile" show={UserProfileShow} edit={UserProfileEdit} />
                <Resource name="user/users" list={UserUserList} show={UserUserShow} edit={UserUserEdit} create={UserUserCreate} icon={ManageAccountsIcon} />
                <Resource name="user/user-guide" list={UserGuide} icon={QuizIcon} />
            </Admin>
        </CacheBuster>
    );
}
    
export default App;