export const formatPhoneNumber = (value) => {
    if (!value) return value;
    const currentValue = value.replace(/\D/g, '');
    const cvLength = currentValue.length;

    if (cvLength < 4) return currentValue;
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3,6)}`;

    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
}

export const parsePhoneNumber = (value) => {
    if (!value) return value;
    let currentValue = value.replace(/\D/g, '');

    if (currentValue.length > 10) {
        currentValue = currentValue.slice(0, 10);
    }

    return currentValue;
}

export const renderPhoneNumber = (record) => {
    if (!record || !record.phone_number) return '';

    /** @var record.phone_number **/
    return `(${record.phone_number.slice(0, 3)}) ${record.phone_number.slice(3, 6)}-${record.phone_number.slice(6, 10)}`;
}

export const renderIvesClientPhoneNumber = (record) => {
    if (!record || !record?.ives_client) return '';

    /** @var record.phone_number **/
    return `(${record.ives_client.phone_number.slice(0, 3)}) ${record.ives_client.phone_number.slice(3, 6)}-${record.ives_client.phone_number.slice(6, 10)}`;
}