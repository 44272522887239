import {ItemCenter} from "../../../Components/Items";
import React from "react";
import {IvesRequestsByMonth} from "./Reports/IvesRequestsByMonth";
import {IvesRequestsByDay} from "./Reports/IvesRequestsByDay";
import {Grid} from "@mui/material";

export const ReportsList = () => {
    return (
        <ItemCenter sx={{ marginTop: 1 }}>
            <Grid container spacing={1} direction="column" justifyContent="flex-start" alignItems="center">
                <Grid item>
                    <IvesRequestsByMonth />
                </Grid>
                <Grid item>
                    <IvesRequestsByDay />
                </Grid>
            </Grid>
        </ItemCenter>
    )
}