export const IvesRequestStatusTooltipText = {
    'Pending Submission': 'The request has been queued for submission to the IRS.<BR /><BR />This step usually lasts less than 5 minutes.',
    'Failed Submission': 'The IRS has rejected the request based on taxpayer information mismatch.<BR />' + 
        'The IRS verifies identities of taxpayers based on their Social Security Number (SSN), real name, and address.<BR /><BR />' +
        'Please ensure that requests are submitted with the applicant\'s:<BR />' +
        '<UL>' +
        '<LI>Real first and last name (nicknames are prohibited)</LI>' +
        '<LI>Address the applicant used when filing their <B>most recent</B> tax returns</LI>' +
        '</UL>',
    'Pending Authorization': 'The request is waiting for taxpayer authorization.<BR /><BR />' +
        'If TrueData is responsible for notifying the taxpayer, notifications are listed on this screen. If you wish to remind the taxpayer that the request is awaiting their' +
        ' authorization, you may send another notification using the button above.<BR /><BR />',
    'Processing': 'The request has been approved by the taxpayer and is being processed by the IRS.<BR /><BR />This step usually last less than 10 minutes.',
    'Complete': 'The results of the request are ready.',
    'Rejected': 'The request has been rejected by the taxpayer.',
    'Pending Withdrawal': 'The request has been queued for withdrawal.',
    'Failed Withdrawal': 'Withdrawal of request has failed.<BR /><BR />This usually occurs when the taxpayer approves or rejects the request while it is queued for withdrawal.',
    'Withdrawn': 'The request has been withdrawn.',
    'Expired': 'The request has expired due to inactivity by the taxpayer.',
};