import {Typography} from "@mui/material";
import React from "react";

export const renderYearsFromRecord = (record) => {
    if (!record) return '';

    /** @var record.ives_request_years */
    if (record?.ives_request_years && record.ives_request_years.length > 0) {
        return (
            <Typography variant="body2">
                {
                    record.ives_request_years.map(
                        t => <span key={Math.random()}>{t.year}</span>
                    ).reduce((prev, curr) => [prev, ', ', curr])
                }
            </Typography>
        );
    }

    /** @var record.expanded_ives_request_years */
    if (record?.expanded_ives_request_years) {
        return (
            <Typography variant="body2">
                {
                    record.expanded_ives_request_years.map(
                        t => <span key={Math.random()}>{t.year}</span>
                    ).reduce((prev, curr) => [prev, ', ', curr])
                }
            </Typography>
        );
    }

    return '';
}