import {
    Show,
    SimpleShowLayout,
    List,
    TextField,
    DateField,
    SimpleForm,
    Edit,
    TextInput,
    Create,
    ReferenceField,
    NumberField,
    SelectInput,
    ReferenceArrayInput,
    EditButton,
    NumberInput,
    useShowController,
} from "react-admin";
import {PageTitle} from "../../../Components/PageTitle";
import {EditActions} from "../../../Components/Actions";
import React from "react";
import {InstitutionFilterSelectInput} from "../../../Components/Filters/InstitutionFilterSelectInput";
import {Grid} from "@mui/material";
import {Datagrid} from "../../../Components/Datagrid";
import {TopToolbar} from "../../../Components/TopToolbar";

export const PaymentList = () => {
    return (
        <List filters={postFilters} resource="payments" sort={{ field: 'created_at', order: 'DESC' }} hasCreate={true} empty={false} exporter={false} >
            <Datagrid bulkActionButtons={false} rowClick="show">
                <TextField source="invoice.institution.name" label="Institution" />
                <NumberField source="amount" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} />
                <DateField showTime={true} source="created_at" label="Created"  />
                <DateField showTime={true} source="updated_at" label="Updated"  />
            </Datagrid>
        </List>
    );
};

const postFilters = [
    <InstitutionFilterSelectInput label="Institution" alwaysOn={true} key={Math.random()} />,
];

export const PaymentCreate = () => (
    <Create>
        <SimpleForm>
            <ReferenceArrayInput name="Invoices" source="invoice_id" reference="invoices" perPage={1000} sort={{ field: "name", order: "ASC" }}>
                <SelectInput optionText="name" required={true} style={{ width : '300px' }} />
            </ReferenceArrayInput>
            <TextInput name="description" source="description" style={{ width : '300px' }} />
            <NumberInput name="amount" source="amount" required={true} style={{ width : '300px' }} />
        </SimpleForm>
    </Create>
);

const PaymentShowActions = () => {
    const showController = useShowController();
    const showEditButton = showController?.record?.invoice?.invoice_status_id === 1 || showController?.record?.invoice?.invoice_status_id === 2;
    return (
        <TopToolbar backButton={true}>
            { showEditButton ? (<EditButton />) : (<></>)}
        </TopToolbar>
    );
};

export const PaymentShow = () => {
    return (
        <Show title={<PageTitle />} actions={<PaymentShowActions />}>
            <SimpleShowLayout>
                <ReferenceField source="invoice.institution_id" reference="institutions" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="description"/>
                <NumberField source="amount" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} />
                <DateField showTime={true} source="created_at" label="Created" />
                <DateField showTime={true} source="updated_at" label="Updated" />
            </SimpleShowLayout>
        </Show>
    );
};

export const PaymentEdit = () => (
    <Edit title={<PageTitle />} mutationMode="pessimistic" actions={<EditActions />} redirect="show" >
        <SimpleForm>
            <Grid container>
                <Grid item xl={12}>
                    <TextInput name="description" source="description" style={{ width : '300px' }} />
                </Grid>
                <Grid item xl={12}>
                    <NumberInput name="amount" source="amount" required={true} style={{ width : '300px' }} />
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);