import {useNotify, useRecordContext, useRefresh} from "react-admin";
import React from "react";
import {useMutation} from "react-query";
import dataProvider from "../../../../Providers/dataProvider";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import LoadingButton from "@mui/lab/LoadingButton";

export const PopulateButton = ({ disabled }) => {
    const refresh = useRefresh();
    const record = useRecordContext();
    const invoiceId = record?.id;
    const notify = useNotify();
    const { mutate, isSuccess, isLoading, isError } = useMutation(
        () => dataProvider.populateInvoice(invoiceId)
    );

    if (!record) {
        return '';
    }

    const handleFormSubmit = () => {
        mutate();
    }

    if (isError) {
        notify('Could not populate invoice', { type: "error"});
    }

    if (isSuccess) {
        refresh();
    }

    return (
        <LoadingButton
            loading={isLoading}
            type="submit"
            textalign="center"
            onClick={handleFormSubmit}
            startIcon={<ChecklistRtlIcon />}
            size="small"
            sx={{lineHeight: 1.5}}
            disabled={disabled}
        >
            Populate
        </LoadingButton>
    );
};