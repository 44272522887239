import {
    Show,
    SimpleShowLayout,
    List,
    TextField,
    DateField,
    SimpleForm,
    Edit,
    TextInput,
    Create,
    RichTextField,
} from "react-admin";
import { RichTextInput } from 'ra-input-rich-text';
import {PageTitle} from "../../../Components/PageTitle";
import {EditActions} from "../../../Components/Actions";
import React from "react";
import {Grid} from "@mui/material";
import {Datagrid} from "../../../Components/Datagrid";

export const AnnouncementList = () => {
    return (
        <List resource="announcements" sort={{ field: 'id', order: 'DESC' }} hasCreate={true} empty={false} exporter={false} >
            <Datagrid bulkActionButtons={false} rowClick="show">
                <TextField source="title" />
                <TextField source="message" />
                <DateField showTime={true} source="created_at" label="Created"  />
                <DateField showTime={true} source="updated_at" label="Updated"  />
            </Datagrid>
        </List>
    );
};

export const AnnouncementCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput name="title" source="title" required={true} style={{ width : '300px' }} />
            <RichTextInput name="message" source="message" isRequired={true} style={{ width : '300px' }} />
        </SimpleForm>
    </Create>
);

export const AnnouncementShow = () => {
    return (
        <Show title={<PageTitle />} >
            <SimpleShowLayout>
                <TextField source="title" />
                <RichTextField source="message" />
                <DateField showTime={true} source="created_at" label="Created" />
                <DateField showTime={true} source="updated_at" label="Updated" />
            </SimpleShowLayout>
        </Show>
    );
};

export const AnnouncementEdit = () => (
    <Edit title={<PageTitle />} mutationMode="pessimistic" actions={<EditActions />} redirect="show" >
        <SimpleForm>
            <Grid container>
                <Grid item xl={12}>
                    <TextInput name="title" source="title" required={true} style={{ width : '300px' }} />
                </Grid>
                <Grid item xl={12}>
                    <RichTextInput name="message" source="message" isRequired={true} style={{ width : '300px' }} />
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);