import {Typography} from "@mui/material";
import React from "react";

export const renderFormFromRecord = (record) => {
    if (!record) return '';

    /** @var record.ives_request_form */
    if (record?.ives_request_form) {
        const name = record.ives_request_form.id === 45 ? 'ALL' : record.ives_request_form.name;

        return (
            <Typography variant="body2">
                {name}
            </Typography>
        );
    }

    /** @var record.expanded_ives_request_form */
    if (record?.expanded_ives_request_form) {
        const name = record.expanded_ives_request_form.id === 45 ? 'ALL' : record.expanded_ives_request_form.name;

        return (
            <Typography variant="body2">
                {name}
            </Typography>
        );
    }

    return '';
}