import {useNotify, useRecordContext, useRefresh} from "react-admin";
import React from "react";
import {useMutation} from "react-query";
import dataProvider from "../../../../Providers/dataProvider";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import LoadingButton from "@mui/lab/LoadingButton";

export const ResetButton = ({ disabled }) => {
    const refresh = useRefresh();
    const record = useRecordContext();
    const invoiceId = record?.id;
    const notify = useNotify();
    const { mutate, isSuccess, isLoading, isError } = useMutation(
        () => dataProvider.resetInvoice(invoiceId)
    );

    const handleFormSubmit = () => {
        mutate();
    }

    if (isError) {
        notify('Could not reset invoice', { type: "error"});
    }

    if (isSuccess) {
        refresh();
    }

    return (
        <LoadingButton
            loading={isLoading}
            type="submit"
            textalign="center"
            onClick={handleFormSubmit}
            startIcon={<RotateLeftIcon />}
            size="small"
            sx={{lineHeight: 1.5}}
            disabled={disabled}
        >
            Reset
        </LoadingButton>
    );
};