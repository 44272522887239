import {Show, List, Edit, Create, SearchInput, useGetList, useGetOne, AutocompleteInput, useNotify, useRedirect} from "react-admin";
import { SimpleShowLayout, TextField, DateField, SimpleForm, TextInput, ArrayField, ReferenceField, } from "react-admin";
import {Datagrid} from "../../../Components/Datagrid";
import {PageTitle} from "../../../Components/PageTitle";
import {EditActions, ShowActions} from "../../../Components/Actions";
import React from "react";
import {InstitutionFilterSelectInput} from "../../../Components/Filters/InstitutionFilterSelectInput";
import {useWatch} from "react-hook-form";
import {required} from "react-admin";

export const BranchList = () => {
    return (
        <List filters={postFilters} title={<PageTitle label="Branches" />} resource="branches" hasCreate={true} exporter={false}>
            <Datagrid bulkActionButtons={false} rowClick="show">
                <TextField source="name" />
                <TextField source="institution.name" />
                <DateField showTime={true} source="created_at" label="Created"  />
                <DateField showTime={true} source="updated_at" label="Updated"  />
            </Datagrid>
        </List>
    );
};

const postFilters = [
    <SearchInput placeholder="Name" source="name" name="name" alwaysOn={true} key={Math.random()} />,
    <InstitutionFilterSelectInput label="Institution" alwaysOn={true} key={Math.random()} />,
];

export const BranchCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data) => {
        notify(`Branch created successfully`, { type: "success"});
        redirect('show', 'branches', data.id, data);
    };

    return (
        <Create mutationOptions={{ onSuccess }}>
            <SimpleForm>
                <InstitutionInput />
                <TextInput source="name" required={true} name={"name"} style={{ width : '300px' }} />
            </SimpleForm>
        </Create>
    );
}

const InstitutionInput = () => {
    const { data: choices, isLoading: isLoadingChoices } = useGetList(
        'institutions',
{
            pagination: {page: 1, perPage: 1000},
            sort: { field: "name", order: "ASC" },
        },
    );

    const institutionId = useWatch({ name: 'institution_id'}) ?? 1;

    const { data: currentInstitution } = useGetOne('institutions', { id: institutionId });

    const choicesWithCurrentInstitution = choices
        ? choices.find(choice => choice.id === institutionId)
            ? choices
            : [...choices, currentInstitution]
        : [];

    return (
        <AutocompleteInput
            name="institution_id"
            label="Institution"
            source="institution_id"
            choices={choicesWithCurrentInstitution}
            optionText="name"
            disabled={isLoadingChoices}
            validate={required()}
            style={{ width : '300px' }}
        />
    );
};

export const BranchShow = () => (
    <Show title={<PageTitle />} actions={<ShowActions />}>
        <SimpleShowLayout>
            <TextField source="name"/>
            <ReferenceField source="institution_id" reference="institutions" link="show">
                <TextField source="name" />
            </ReferenceField>
            <DateField showTime={true} source="created_at" label="Created" />
            <DateField showTime={true} source="updated_at" label="Updated" />
            <ArrayField source="users">
                <Datagrid bulkActionButtons={false} rowClick={userRowClick}>
                    <TextField source="name" />
                    <TextField source="email" />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);

export const BranchEdit = () => (
    <Edit title={<PageTitle />} mutationMode="pessimistic" actions={<EditActions />} redirect="show">
        <SimpleForm>
            <TextInput source="name" required={true} name={"name"} style={{ width : '300px' }} />
            <InstitutionInput />
        </SimpleForm>
    </Edit>
);

const userRowClick = (id) => {
    return "/users/" + id + "/show";
};