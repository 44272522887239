import React from "react";
import {AutocompleteInput} from "react-admin";
import {States} from "../States";

export const StateInput = (props) => {
    return (
        <AutocompleteInput
            optionText="name"
            choices={States}
            {...props}
        />
    );
};