import {SelectInput, useGetList} from "react-admin";
import React from "react";

export const IvesRequestBranchFilterSelectInput = (props) => {
    const { data : branches, isLoading} = useGetList(
        'user/branches',
        {
            pagination: {page: 1, perPage: 1000},
            sort: { field: "name", order: "ASC"}
        }
    );

    return (
        <SelectInput
            key={Math.random()}
            label={props.label}
            choices={branches}
            name="branch_id"
            source="branch_id"
            isLoading={isLoading}
            alwaysOn={true}
        />
    );
};