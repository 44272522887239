import {useNotify, useRecordContext, useRefresh} from "react-admin";
import React, {useState} from "react";
import {useMutation} from "react-query";
import dataProvider from "../../../../Providers/dataProvider";
import {Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";

const defaultPreviewData = {
    from: {
        name: "",
        email: "",
    },
    to: [
        {
            name: "",
            email: "",
        },
    ],
    bcc: [
    ],
    attachments: {
        invoice: {
            name: "",
            contents: "",
        },
        details: {
            name: "",
            contents: "",
        },
    },
    subject: "",
    body: "",
};

export const SendButton = ({ disabled }) => {
    const record = useRecordContext();
    const refresh = useRefresh();
    const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
    const notify = useNotify();
    const [previewData, setPreviewData] = useState(defaultPreviewData);
    const invoiceId = record?.id;
    const { mutate: getPreview, isLoading: isPreviewLoading, isError: isPreviewError } = useMutation(
        ['getPreviewForInvoice'],
        () => dataProvider.getInvoicePreview(invoiceId),
        {
            onSuccess: (data) => {
                setPreviewData(JSON.parse(data.body));
                setPreviewDialogOpen(true);
            },
            onError: () => {
                notify('Could not load invoice preview', { type: "error"});
            }
        }
    );
    const { mutate: send, isLoading: isSending } = useMutation(
        ['getPreviewForInvoice'],
        () => dataProvider.sendInvoice(invoiceId),
        {
            onSuccess: () => {
                refresh();
                notify('Invoice Sent', { type: "success"});
                setPreviewDialogOpen(false);
            },
            onError: () => {
                notify('Could not send invoice', { type: "error"});
            }
        }
    );

    if (isPreviewError) {
        return;
    }

    const handleClickSend = () => {
        send();
    };

    const handleClickOpen = () => {
        getPreview();
    };

    const handleClose = () => {
        setPreviewDialogOpen(false);
    };

    return (
        <div>
            <LoadingButton
                loading={isPreviewLoading}
                type="submit"
                textalign="center"
                onClick={handleClickOpen}
                startIcon={<SendIcon />}
                size="small"
                sx={{lineHeight: 1.5}}
                disabled={disabled}
            >
                Send
            </LoadingButton>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={previewDialogOpen}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Preview Invoice Email
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Grid container direction="row" justify="space-between" spacing={1}>
                        <Grid item xl={2}>
                            <Typography variant="subtitle1">
                                From
                            </Typography>
                        </Grid>
                        <Grid item xl={10}>
                            <Chip label={previewData.from.name + ' (' + previewData.from.email + ')'} variant="outlined" />
                        </Grid>
                        <Grid item xl={2}>
                            <Typography variant="subtitle1">
                                To
                            </Typography>
                        </Grid>
                        <Grid item xl={10}>
                            {
                                previewData.to.map(to => {
                                        return (
                                            <Grid container key={Math.random()}>
                                                <Grid xl={12} padding={0.1} key={Math.random()}>
                                                    <Chip label={to.name + ' (' + to.email + ')'} color="info" variant="outlined" key={Math.random()} />
                                                </Grid>
                                            </Grid>
                                        );
                                    }
                                )
                            }
                        </Grid>
                        <Grid item xl={2}>
                            <Typography variant="subtitle1">
                                Bcc
                            </Typography>
                        </Grid>
                        <Grid item xl={10}>
                            {
                                previewData.bcc.map(bcc => {
                                        return (
                                            <Grid container key={Math.random()}>
                                                <Grid xl={12} padding={0.1} key={Math.random()}>
                                                    <Chip label={bcc} color="error" variant="outlined" key={Math.random()} />
                                                </Grid>
                                            </Grid>
                                        );
                                    }
                                )
                            }
                        </Grid>
                        <Grid item xl={2}>
                            <Typography variant="subtitle1">
                                Attachments
                            </Typography>
                        </Grid>
                        <Grid item xl={10}>
                            <Grid container>
                                <Grid xl={12} padding={0.1}>
                                    <a download={previewData.attachments.invoice.name} href={`data:application/pdf;base64,${previewData.attachments.invoice.content}`}>
                                        <Chip label={previewData.attachments.invoice.name} sx={{textDecoration: "underline"}} color="success" variant="filled"/>
                                    </a>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xl={12} padding={0.1}>
                                    <a download={previewData.attachments.details.name} href={`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${previewData.attachments.details.content}`}>
                                        <Chip label={previewData.attachments.details.name} sx={{textDecoration: "underline"}} color="success" variant="filled"/>
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xl={2}>
                            <Typography variant="subtitle1" sx={{ verticalAlign: 'middle' }} >
                                Subject
                            </Typography>
                        </Grid>
                        <Grid item xl={10}>
                            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} >
                                {previewData.subject}
                            </Typography>
                        </Grid>
                        <Grid item xl={12} sx={{ border: 1, marginTop: 2, padding: 2 }}>
                            <div dangerouslySetInnerHTML={{__html: previewData.body}}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={isSending}
                        type="submit"
                        textalign="center"
                        onClick={handleClickSend}
                        startIcon={<SendIcon />}
                        size="small"
                        sx={{lineHeight: 1.5}}
                    >
                        Send
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}