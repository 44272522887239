import {Confirm, useNotify, useRecordContext, useRefresh} from "react-admin";
import React, {useState} from "react";
import {useMutation} from "react-query";
import dataProvider from "../../../../Providers/dataProvider";
import {Button} from "ra-ui-materialui";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";

/** @var record.can_send_taxpayer_email_notification **/

export const SendTaxpayerEmailNotificationButton = ({ role }) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const [hideGreenArrow, setHideGreenArrow] = useState(true);
    const [ notified, setNotified ] = React.useState(false);
    const { mutate, isSuccess, isLoading } = useMutation(
        () => dataProvider.sendTaxpayerEmailNotificationRequest(role, record.id)
    );

    let isDisabled = false;
   
    if (!record || !record.can_send_taxpayer_email_notification) {
        isDisabled = true;
    }

    const SendTaxpayerNotificationEmail = () => {
        setNotified(false);
        mutate();
    }

    if (isSuccess && !notified) {
        refresh();
        isDisabled = true;
        setNotified(true);
        notify('Taxpayer Email Notification Sent', { type: "success"});
        setOpen(false);
    }

    if (isLoading) {
        isDisabled = true;
    }

    const HandleClick = () => setOpen(true);

    const HandleDialogClose = () => setOpen(false);

    const showGreenArrow = JSON.parse(localStorage.getItem("show-green-arrow")) || false;
    if (showGreenArrow === true && hideGreenArrow === true) {
        setHideGreenArrow(false);

        setTimeout(() => {
                localStorage.removeItem("show-green-arrow");
            }, 1000
        );

        setTimeout(() => {
                setHideGreenArrow(true);
            }, 4000
        );
    }

    return (
        <>
            <div
                style={{
                    position: "relative",
                    overflow: "visible",
                    width: 1,
                    height: 1,
                }}
            >
                <img
                    id="green-arrow"
                    alt="green-arrow"
                    src="/green_arrow_anim.gif"
                    style={{
                        position: "relative",
                        width: 100,
                        height: 62,
                        left: -70,
                        top: -45,
                    }}
                    hidden={hideGreenArrow}
                />
            </div>
            <Button
                label='Send Email Notification'
                disabled={isDisabled}
                onClick={HandleClick}
            >
                <ForwardToInboxIcon/>
            </Button>
            <Confirm
                confirm={'Send'}
                fullWidth={false}
                isOpen={open}
                loading={isLoading}
                title={`Send Email Notification?`}
                content={<>Are you sure you wish to send an email notification to the taxpayer?</>}
                onConfirm={SendTaxpayerNotificationEmail}
                onClose={HandleDialogClose}
            />
        </>
    )
}