import { fetchUtils } from "react-admin";

export const makeApiCall = (url, options = {}, body) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
        options.headers.set('Authorization', `Bearer ${user.token}`);
    }

    if (body) {
        options.body = JSON.stringify(body);
    }

    return fetchUtils.fetchJson(process.env.REACT_APP_BASE_API_URL + url, options)
        .then(response => {
            return Promise.resolve(response);
        })
        .catch(response => {
            let responseToReturn = response.toString().replace('HttpError: ', '');
            if (response.status === 401) {
                localStorage.removeItem('user');
                responseToReturn = response;
            }

            return Promise.reject(responseToReturn);
        });
};