import {SelectInput, useGetOne} from "react-admin";
import {useWatch} from "react-hook-form";
import React from "react";

export const BranchInput = () => {
    let branches = [];

    const originalInstitutionId = useWatch({ name: 'InstitutionSelect'})
    let institutionId = !originalInstitutionId ? 1 : originalInstitutionId;

    const { data: currentInstitution, isLoading: isLoadingCurrentInstitution } = useGetOne('institutions', { id: institutionId });
    
    if (currentInstitution?.branches?.length > 0) {
        branches = currentInstitution.branches;
    }

    const isLoading = isLoadingCurrentInstitution || !originalInstitutionId;

    return (
        <SelectInput
            label="Branch"
            source="branch_id"
            choices={branches}
            optionText="name"
            disabled={isLoading}
            required={true}
            style={{ width : '300px' }}
        />
    );
}