import {SelectInput, useGetList} from "react-admin";
import React from "react";

export const ExpandedIvesRequestFormFilterSelectInput = (props) => {
    const { data, isSuccess, isLoading, isError} = useGetList('ives-request-forms');

    if (isLoading || isError || !data) {
        return <span key={Math.random()}>Loading...</span>
    }

    if (isSuccess) {
        return (
            <SelectInput
                key={Math.random()}
                label={props.label}
                choices={data}
                name="expanded_ives_request_form_id"
                source="expanded_ives_request_form_id"
                isLoading={isLoading}
                alwaysOn={true}
            />
        );
    }
};