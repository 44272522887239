export function TrackingNumberStringForIndustry () {
    let string = "Tracking Number";

    const userSettings = JSON.parse(localStorage.getItem('user'));

    if (userSettings && "industry" in userSettings) {
        /** @var userSettings.industry **/
        if (userSettings.industry === 'Financial') {
            string = "Loan Number";
        }
    }

    return string;
}