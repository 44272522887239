import {Confirm, useNotify, useRecordContext, useRefresh} from "react-admin";
import React, {useState} from "react";
import {useMutation} from "react-query";
import dataProvider from "../../../../Providers/dataProvider";
import {Button} from "ra-ui-materialui";
import DeleteIcon from '@mui/icons-material/Delete';

/** @var record.can_withdraw **/

export const WithdrawButton = ({ role }) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const [ notified, setNotified ] = React.useState(false);
    const { mutate, isSuccess, isLoading } = useMutation(
        () => dataProvider.withdrawRequest(role, record.id)
    );

    let isDisabled = false;
   
    if (!record || !record.can_withdraw) {
        isDisabled = true;
    }

    const Withdraw = () => {
        setNotified(false);
        mutate();
    }

    if (isSuccess && !notified) {
        refresh();
        isDisabled = true;
        setNotified(true);
        notify('Request has been withdrawn', { type: "success"});
        setOpen(false);
    }

    if (isLoading) {
        isDisabled = true;
    }

    const HandleClick = () => setOpen(true);

    const HandleDialogClose = () => setOpen(false);

    return (
        <>
            <Button
                label='Withdraw'
                disabled={isDisabled}
                onClick={HandleClick}
            >
                <DeleteIcon />
            </Button>
            <Confirm
                confirm={'Withdraw'}
                fullWidth={false}
                isOpen={open}
                loading={isLoading}
                title={`Withdraw Request?`}
                content={<>Are you sure you wish to withdraw this request?</>}
                onConfirm={Withdraw}
                onClose={HandleDialogClose}
            />
        </>
    )
}