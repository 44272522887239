import * as React from "react";
import Welcome from "./Components/Welcome";
import {Box, Grid, Typography, useMediaQuery} from "@mui/material";
import Announcements from "./Components/Announcements";
import IvesRequests from "./Components/IvesRequests";
import {Item, ItemCenter} from "../../Components/Items";

const Dashboard = () => {
    const condensed = useMediaQuery((theme) => theme.breakpoints.down("md"));

    return (
        <>
            <Welcome />
            <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="stretch">
                <Grid item xs={12}>
                    <ItemCenter>
                        <Box>
                            <Typography variant="h6">Announcements</Typography>
                        </Box>
                        <Announcements condensed={condensed} />
                    </ItemCenter>
                </Grid>
                <Grid item xs={12}>
                    <Item>
                        <Box>
                            <Typography variant="h6">IVES Requests (last 5)</Typography>
                        </Box>
                        <IvesRequests condensed={condensed} />
                    </Item>
                </Grid>
            </Grid>
        </>
    );
};

export default Dashboard;