import AddIcon from "@mui/icons-material/Add";
import {Button} from "@mui/material";
import React from "react";

export const CreateButton = ({ onClick }) => {
    return (
        <Button
            startIcon={<AddIcon sx={{fontSize:"20px"}} />}
            className="RaCreateButton"
            onClick={onClick}
        >
            Create
        </Button>
    );
};