import {DateField, List, NumberField, SearchInput, SelectInput, TextField, useShowController} from "react-admin";
import {CircularProgress, Grid, Typography} from "@mui/material";
import {useQuery} from "react-query";
import dataProvider from "../../Providers/dataProvider";
import React from "react";
import {formatSocialSecurityNumber, parseSocialSecurityNumber} from "../Functions/SocialSecurityNumber";
import {Datagrid} from "../Datagrid";
import {TrackingNumberStringForIndustry} from "../Functions/Labels";

export const InvoiceLineItemsShow = ({ role }) => {
    const show = useShowController();
    if (!show?.record || !role) {
        return '';
    }

    const record = show.record;

    let resource = "invoices/" + record.id + "/line-items";

    if (role === 'user') {
        resource = 'user/' + resource;
    }

    return (
        <Grid container>
            <Grid item xl={12}>
                <Typography variant="h6">Line Items</Typography>
            </Grid>
            <Grid item xl={12}>
                <List
                    filters={LineItemFilters(role)}
                    title=" "
                    resource={resource}
                    sort={{ field: 'created_at', order: 'DESC' }}
                    hasCreate={false}
                    actions={false}
                    empty={false}
                    exporter={false}
                >
                    <Datagrid bulkActionButtons={false} rowClick={false}>
                        <TextField source="id" label="ID" sortable={false} />
                        <TextField source="tracking_number" label={TrackingNumberStringForIndustry()} sortable={false} />
                        <TextField source="invoiceable_type.name" label="Type" sortable={false} />
                        <TextField source="taxpayer_name" label="Taxpayer" sortable={false} />
                        <TextField source="ssn" label="SSN" sortable={false} />
                        <TextField source="form_name" label="Form" sortable={false} />
                        <TextField source="years" label="Years" sortable={false} />
                        <DateField showTime={false} source="created" label="Created" sortable={false} />
                        <DateField showTime={false} source="became_billable" label="Became Billable" sortable={false} />
                        <NumberField source="amount" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} sortable={false} />
                    </Datagrid>
                </List>
            </Grid>
        </Grid>
    )
}

const LineItemTypeFilterSelectInput = (props) => {
    const { data, isLoading} = useQuery(
        ['getInvoiceableTypes'],
        () => dataProvider.getInvoiceableTypes(props.role)
    );

    const invoiceableTypes = data?.json;

    if (!invoiceableTypes) {
        return <CircularProgress key={Math.random()} />
    }

    return (
        <SelectInput
            key={Math.random()}
            label={props.label}
            choices={invoiceableTypes}
            name="invoiceable_type"
            source="invoiceable_type"
            isLoading={isLoading}
            alwaysOn={true}
        />
    );
};

function LineItemFilters (role) {
    return [
        <LineItemTypeFilterSelectInput label="Type" alwaysOn={true} key={Math.random()} role={role} />,
        <SearchInput placeholder="SSN" source="ssn" name="ssn" alwaysOn={true} format={formatSocialSecurityNumber} parse={parseSocialSecurityNumber} key={Math.random()} />,
        <SearchInput placeholder={TrackingNumberStringForIndustry()} source="tracking_number" name="tracking_number" alwaysOn={true} key={Math.random()} />,
    ];
}