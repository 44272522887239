import {Box, Grid, Typography} from "@mui/material";
import {maxLength, minLength, number, regex, required, TextInput} from "react-admin";
import {formatSocialSecurityNumber, parseSocialSecurityNumber} from "../../../../Components/Functions/SocialSecurityNumber";
import {StateInput} from "../../../../Components/Inputs/StateInput";
import React, {useEffect} from "react";
import {ToolTip} from "../../../../Components/ToolTip";
import {useFormContext} from "react-hook-form";
import {AutocompleteStreetNameInput} from "../../../Admin/Entities/AutocompleteStreetNameInput";

export const RequestCreateTaxpayerInput = ({ retryData }) => {
    const retry_taxpayer_street_name = (!retryData) ? '' : retryData.taxpayer_street_name;
    const retry_taxpayer_previous_street_name = (!retryData) ? '' : retryData.taxpayer_previous_street_name;

    const validateTaxpayerFirstName = [required(), minLength(1), maxLength(128)];
    const validateTaxpayerMiddleInitial = [maxLength(1)];
    const validateTaxpayerLastName = [required(), minLength(1), maxLength(128)];
    const validateTaxpayerSsn = [required(), number(), minLength(9, 'Must be exactly 9 digits'), maxLength(9, 'Must be exactly 9 digits')];
    const validateTaxpayerStreetName = [required(), minLength(1), maxLength(256)];
    const validateTaxpayerCityName = [required(), minLength(1), maxLength(64)];
    const validateTaxpayerZip = [required(), regex(/^\d{5}$/, 'Must be a valid ZIP Code')];
    const validateTaxpayerPreviousStreetName = [ minLength(1), maxLength(256)];
    const validateTaxpayerPreviousCityName = [minLength(1), maxLength(64)];
    const validateTaxpayerPreviousZip = [regex(/^\d{5}$/, 'Must be a valid ZIP Code')];
    const { setValue } = useFormContext();

    useEffect(() => {
        if (retryData) {
            setTimeout(() => setValue('taxpayer_first_name', retryData.taxpayer_first_name));
            setTimeout(() => setValue('taxpayer_middle_initial', retryData.taxpayer_middle_initial));
            setTimeout(() => setValue('taxpayer_last_name', retryData.taxpayer_last_name));
            setTimeout(() => setValue('taxpayer_ssn', retryData.taxpayer_ssn));
            setTimeout(() => setValue('taxpayer_street_name', retryData.taxpayer_street_name));
            setTimeout(() => setValue('taxpayer_city_name', retryData.taxpayer_city_name));
            setTimeout(() => setValue('taxpayer_state_abbreviation', retryData.taxpayer_state_abbreviation));
            setTimeout(() => setValue('taxpayer_zip', retryData.taxpayer_zip));
            setTimeout(() => setValue('taxpayer_previous_street_name', retryData.taxpayer_previous_street_name));
            setTimeout(() => setValue('taxpayer_previous_city_name', retryData.taxpayer_previous_city_name));
            setTimeout(() => setValue('taxpayer_previous_state_abbreviation', retryData.taxpayer_previous_state_abbreviation));
            setTimeout(() => setValue('taxpayer_previous_zip', retryData.taxpayer_previous_zip));
        }

    }, [setValue, retryData]);

    function onAutoFocus (event) {
        if (event.target.autocomplete) {
            event.target.autocomplete = "whatever";
        }
    }

    return (
        <Grid item md={3} sm={6} xs={12}>
            <Grid container direction="column" justifyContent="flex-start">
                <Grid item>
                    <Box>
                        <Typography variant="h6">Taxpayer/Applicant</Typography>
                    </Box>
                    <TextInput source="taxpayer_first_name" helperText={false} validate={validateTaxpayerFirstName} name={"taxpayer_first_name"} label="First Name" sx={{width: "100%"}} autoComplete="off" onFocus={onAutoFocus} />
                </Grid>
                <Grid item>
                    <TextInput source="taxpayer_middle_initial" helperText={false} validate={validateTaxpayerMiddleInitial} name={"taxpayer_middle_initial"} label="Middle Initial" sx={{width: "100%"}} autoComplete="off" onFocus={onAutoFocus} />
                </Grid>
                <Grid item>
                    <TextInput source="taxpayer_last_name" helperText={false} validate={validateTaxpayerLastName} name={"taxpayer_last_name"} label="Last Name" sx={{width: "100%"}} autoComplete="off" onFocus={onAutoFocus} />
                </Grid>
                <Grid item>
                    <TextInput
                        source="taxpayer_ssn"
                        helperText={false}
                        validate={validateTaxpayerSsn}
                        name={"taxpayer_ssn"}
                        label="SSN"
                        sx={{width: "100%"}}
                        format={formatSocialSecurityNumber}
                        parse={parseSocialSecurityNumber}
                        autoComplete="off"
                        onFocus={onAutoFocus}
                    />
                </Grid>
                <Grid item>
                    <Box paddingTop={1}>
                        <Typography variant="h6">
                            <>Address</>
                            <ToolTip
                                title="Current Address"
                                text="This is the address the taxpayer/applicant submitted with their most recent tax return."
                            />
                        </Typography>
                    </Box>
                    <AutocompleteStreetNameInput
                        streetNameInputLabel="Street *"
                        streetNameInputName="taxpayer_street_name"
                        cityNameInputName="taxpayer_city_name"
                        stateAbbreviationInputName="taxpayer_state_abbreviation"
                        zipInputName="taxpayer_zip"
                        validate={validateTaxpayerStreetName}
                        sx={{width: "100%"}}
                        value={retry_taxpayer_street_name}
                    />
                </Grid>
                <Grid item>
                    <TextInput source="taxpayer_city_name" helperText={false} validate={validateTaxpayerCityName} name={"taxpayer_city_name"} label="City" sx={{width: "100%"}} autoComplete="off" onFocus={onAutoFocus} />
                </Grid>
                <Grid item>
                    <Grid container spacing={1} direction="row" justifyContent="flex-start">
                        <Grid item md={12} lg={8}>
                            <StateInput
                                source="taxpayer_state_abbreviation"
                                helperText={false}
                                validate={[required()]}
                                name={"taxpayer_state_abbreviation"}
                                label="State"
                                style={{ width : '100%' }}
                            />
                        </Grid>
                        <Grid item md={12} lg={4}>
                            <TextInput source="taxpayer_zip" helperText={false} validate={validateTaxpayerZip} name={"taxpayer_zip"} label="ZIP Code" sx={{width: "100%"}} autoComplete="off" onFocus={onAutoFocus} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Box paddingTop={1}>
                        <Typography variant="h6">
                            <>Additional Address (optional)</>
                            <ToolTip
                                title="Previous Address"
                                text="Sometimes, taxpayers/applicants are not sure which address they submitted with their most recent tax return. Use these fields if they have another address they suspect could be the correct one."
                            />
                        </Typography>
                    </Box>
                    <AutocompleteStreetNameInput
                        streetNameInputLabel="Street"
                        streetNameInputName="taxpayer_previous_street_name"
                        cityNameInputName="taxpayer_previous_city_name"
                        stateAbbreviationInputName="taxpayer_previous_state_abbreviation"
                        zipInputName="taxpayer_previous_zip"
                        validate={validateTaxpayerPreviousStreetName}
                        sx={{width: "100%"}}
                        value={retry_taxpayer_previous_street_name}
                    />
                </Grid>
                <Grid item>
                    <TextInput source="taxpayer_previous_city_name" helperText={false} validate={validateTaxpayerPreviousCityName} name={"taxpayer_previous_city_name"} label="City" sx={{width: "100%"}} autoComplete="off" onFocus={onAutoFocus} />
                </Grid>
                <Grid item>
                    <Grid container spacing={1} direction="row" justifyContent="flex-start">
                        <Grid item md={12} lg={8}>
                            <StateInput
                                source="taxpayer_previous_state_abbreviation"
                                helperText={false}
                                name={"taxpayer_previous_state_abbreviation"}
                                label="State"
                                style={{ width : '100%' }}
                            />
                        </Grid>
                        <Grid item md={12} lg={4}>
                            <TextInput source="taxpayer_previous_zip" helperText={false} validate={validateTaxpayerPreviousZip} name={"taxpayer_previous_zip"} label="ZIP Code" sx={{width: "100%"}} autoComplete="off" onFocus={onAutoFocus} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}