import {SelectInput, useGetList} from "react-admin";
import React from "react";
import {CircularProgress} from "@mui/material";

export const BranchFilterSelectInput = (props) => {
    const resource = props.type === 'user' ? 'user/' : '';
    const { data, isSuccess, isLoading, isError} = useGetList(
        `${resource}branches`
    );

    if (isLoading || isError) {
        return <CircularProgress key={Math.random()} />
    }

    if (isSuccess) {
        return (
            <SelectInput
                key={Math.random()}
                label={props.label}
                choices={data}
                name="branch_id"
                source="branch_id"
                isLoading={isLoading}
                alwaysOn={true}
            />
        );
    }
};