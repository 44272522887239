import {SelectInput, useGetList} from "react-admin";
import React from "react";
import {useWatch} from "react-hook-form";

export const IvesRequestUserFilterSelectInput = (props) => {

    const rawBranchId = useWatch({ name: "branch_id" });

    let filter = {};

    if (rawBranchId) {
        filter.branch_id = rawBranchId;
    }

    const { data : users, isLoading} = useGetList(
        'user/users',
        {
            filter: filter,
            pagination: {page: 1, perPage: 1000},
            sort: { field: "name", order: "ASC"}
        }
    );

    return (
        <SelectInput
            key={Math.random()}
            label={props.label}
            choices={users}
            name="user_id"
            source="user_id"
            isLoading={isLoading}
            alwaysOn={true}
        />
    );
};