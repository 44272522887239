import {
    Show,
    SimpleShowLayout,
    List,
    TextField,
    DateField,
    SimpleForm,
    Edit,
    TextInput,
    Create,
    ReferenceField,
    NumberField,
    SelectInput,
    ReferenceArrayInput,
    NumberInput,
    useRecordContext, Link, FunctionField, useShowController
} from "react-admin";
import {PageTitle} from "../../../Components/PageTitle";
import {EditActions, ShowActions} from "../../../Components/Actions";
import React from "react";
import {InstitutionFilterSelectInput} from "../../../Components/Filters/InstitutionFilterSelectInput";
import {Datagrid} from "../../../Components/Datagrid";

const InvoiceLink = () => {
    const record = useRecordContext();

    if (!record || !record?.line_item) {
        return <></>;
    }

    const link = '/invoices/' + record?.line_item.invoice_id + '/show/'
    return (
        <Link to={link}>{record?.line_item.invoice_id}</Link>
    )
}

export const InstitutionCreditList = () => {
    return (
        <List
            title={<PageTitle label="Institution Credits" />}
            filters={postFilters}
            resource="institution-credits"
            sort={{ field: 'created_at', order: 'DESC' }}
            hasCreate={true}
            empty={false}
            exporter={false}
        >
            <Datagrid bulkActionButtons={false} rowClick="show">
                <TextField source="institution.name" label="Institution" />
                <TextField source="name" label="Name" sortable={false} />
                <NumberField source="amount" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} />
                <DateField showTime={true} source="created_at" label="Created"  />
                <DateField showTime={true} source="updated_at" label="Updated"  />
            </Datagrid>
        </List>
    );
};

const postFilters = [
    <InstitutionFilterSelectInput label="Institution" alwaysOn={true} key={Math.random()} />,
];

export const InstitutionCreditCreate = () => (
    <Create>
        <SimpleForm>
            <ReferenceArrayInput name="Institution" source="institution_id" reference="institutions" perPage={1000} sort={{ field: "name", order: "ASC" }}>
                <SelectInput optionText="name" required={true} style={{ width : '300px' }} />
            </ReferenceArrayInput>
            <TextInput name="name" source="name" required={true} style={{ width : '300px' }} />
            <TextInput name="description" source="description" style={{ width : '300px' }} />
            <NumberInput name="amount" source="amount" required={true} style={{ width : '300px' }} />
        </SimpleForm>
    </Create>
);

export const InstitutionCreditShow = () => {
    const record = useShowController().record;

    const showEditButton = !record?.line_item;

    return (
        <Show title={<PageTitle />} actions={<ShowActions showEditButton={showEditButton} />}>
            <SimpleShowLayout>
                <ReferenceField source="institution_id" reference="institutions" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="name"/>
                <TextField source="description"/>
                <NumberField source="amount" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} />
                <FunctionField
                    label="Invoice"
                    render={() => <InvoiceLink />}
                />
                <DateField showTime={true} source="created_at" label="Created" />
                <DateField showTime={true} source="updated_at" label="Updated" />
            </SimpleShowLayout>
        </Show>
    );
};

export const InstitutionCreditEdit = () => (
    <Edit title={<PageTitle />} mutationMode="pessimistic" actions={<EditActions />} redirect="show">
        <SimpleForm>
            <TextInput name="name" source="name" required={true} style={{ width : '300px' }} />
            <TextInput name="description" source="description" style={{ width : '300px' }} />
            <NumberInput name="amount" source="amount" required={true} style={{ width : '300px' }} />
        </SimpleForm>
    </Edit>
);