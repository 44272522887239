import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, useMediaQuery} from "@mui/material";
import React, {useState} from "react";
import {
    DeleteWithConfirmButton,
    FunctionField,
    List,
    ReferenceArrayInput,
    regex,
    required,
    SelectInput,
    SimpleForm,
    SimpleList,
    TextField,
    TextInput,
    useListContext,
    useNotify,
    useRecordContext,
} from "react-admin";
import {useForm, useFormContext} from "react-hook-form";
import {useMutation} from "react-query";
import {ClipLoader} from "react-spinners";
import {Form} from "ra-core";
import EditIcon from '@mui/icons-material/Edit';
import dataProvider from "../../../Providers/dataProvider";
import {formatPhoneNumber, parsePhoneNumber, renderPhoneNumber} from "../../../Components/Functions/PhoneNumber";
import {CreateButton} from "../../../Components/Inputs/CreateButton";
import {StateInput} from "../../../Components/Inputs/StateInput";
import {AutocompleteStreetNameInput} from "./AutocompleteStreetNameInput";
import {Datagrid} from "../../../Components/Datagrid";
import {TopToolbar} from "../../../Components/TopToolbar";

/** @var record.institution_contact_type **/

export const InstitutionContacts = ({ institutionId }) => {
    const isMedium = useMediaQuery(theme => theme.breakpoints.down('md'));

    if (!institutionId) {
        return <></>
    }

    return (
        <List
            resource="institution-contacts"
            filter={{ institution_id: institutionId }}
            empty={false}
            exporter={false}
            actions={<ListActions institutionId={institutionId} />}
            title={<></>}
        >
            {isMedium ? (
                <SimpleList
                    hasBulkActions={false}
                    linkType="show"
                    primaryText={record => record.name}
                    secondaryText={record => record.email}
                    tertiaryText={record => record.institution_contact_type.name}
                />
            ) : (
                <Datagrid bulkActionButtons={false} rowClick={false}>
                    <TextField source="institution_contact_type.name" label="Type" />
                    <TextField source="name" />
                    <TextField source="email" />
                    <FunctionField source="phone" render={renderPhoneNumber}  />
                    <TextField source="street_name" label="Address" />
                    <TextField source="city_name" label="City" />
                    <TextField source="state_abbreviation" label="State" />
                    <TextField source="zip" label="ZIP Code" />
                    <InstitutionContactEditDialog />
                    <DeleteWithConfirmButton
                        confirmTitle={"Are you sure you wish to delete?"}
                        redirect={false}
                    />
                </Datagrid>
            )}
        </List>
    );
};

const ListActions = ({ institutionId }) => {
    return (
        <TopToolbar>
            <InstitutionContactCreateDialog institutionId={institutionId} />
        </TopToolbar>
    );
}

const InstitutionContactCreateDialog = ({ institutionId }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Form>
                <CreateButton onClick={handleClickOpen} />
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Create Institution Contact</DialogTitle>
                    <DialogContent>
                        <InstitutionContactForm />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <InstitutionDialogButton type='create' handleClose={handleClose} institutionId={institutionId} />
                    </DialogActions>
                </Dialog>
            </Form>
        </div>
    );
}

const InstitutionContactEditDialog = () => {
    const record = useRecordContext();
    const institutionContactId = record?.id;
    const [open, setOpen] = React.useState(false);
    const { mutate, data: institutionContactData, isSuccess } = useMutation(
        () => dataProvider.getInstitutionContact(institutionContactId)
    )

    if (!record) {
        return null;
    }

    const handleEditOpen = () => {
        mutate();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <SimpleForm toolbar={false} sx={{padding: 0, paddingBottom: 0, height: 0}}>
            <Button startIcon={<EditIcon />} onClick={handleEditOpen} sx={{padding: 0}} >
                Edit
            </Button>
            { isSuccess ? (
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Edit Institution Contact</DialogTitle>
                    <DialogContent>
                        <InstitutionContactForm institutionContactData={institutionContactData} />
                    </DialogContent>
                    <DialogActions>
                        <CancelButton handleClose={handleClose} />
                        <InstitutionDialogButton type='update' handleClose={handleClose} institutionContactId={institutionContactId} />
                    </DialogActions>
                </Dialog>
            ) : (<></>)}
        </SimpleForm>
    );
}

const CancelButton = ({ handleClose }) => {
    const { reset: resetForm } = useForm();

    const handleClick = () => {
        resetForm();
        handleClose();
    }

    return (
        <Button onClick={handleClick}>Cancel</Button>
    );
};

const InstitutionContactForm = ({ institutionContactData }) => {
    return (
        <Grid container align="center">
            <Grid item lg={12}>
                <ReferenceArrayInput name="Contact Type" source="institution_contact_type_id" reference="institution-contact-types" >
                    <SelectInput
                        source="institution_contact_type_id"
                        optionText="name"
                        name="InstitutionContactTypeSelect"
                        label="Contact Type"
                        validate={required()}
                        sx={{ width: 300, textAlign: "left" }}
                        value={institutionContactData?.institution_contact_type_id}
                    />
                </ReferenceArrayInput>
            </Grid>
            <Grid item lg={12}>
                <TextInput source="name" name="name" validate={required()} style={{ width: 300 }} value={institutionContactData?.name} />
            </Grid>
            <Grid item lg={12}>
                <TextInput source="email" name="email" validate={required()} style={{ width: 300 }} value={institutionContactData?.email} />
            </Grid>
            <Grid item lg={12}>
                <TextInput
                    source="phone_number"
                    name={"phone_number"}
                    style={{ width: 300 }}
                    format={formatPhoneNumber}
                    parse={parsePhoneNumber}
                    value={institutionContactData?.phone_number}
                />
            </Grid>
            <Grid item lg={12}>
                <AutocompleteStreetNameInput
                    streetNameInputLabel="Street Name *"
                    streetNameInputName="street_name"
                    cityNameInputName="city_name"
                    stateAbbreviationInputName="state_abbreviation"
                    zipInputName="zip"
                    sx={{ width: 300, marginBottom: 3 }}
                    value={institutionContactData?.street_name}
                />
            </Grid>
            <Grid item lg={12}>
                <TextInput source="city_name" name="city_name" style={{ width: 300 }} value={institutionContactData?.city_name} />
            </Grid>
            <Grid item lg={12}>
                <StateInput
                    source="state_abbreviation"
                    name="state_abbreviation"
                    label="State"
                    style={{ width: 300 }}
                    value={institutionContactData?.state_abbreviation}
                />
            </Grid>
            <Grid item lg={12}>
                <TextInput source="zip" name="zip" validate={[regex(/^\d{5}$/, 'Must be a valid ZIP Code')]} style={{ width: 300 }} label="ZIP Code" value={institutionContactData?.zip} />
            </Grid>
        </Grid>
    );
};

const InstitutionDialogButton = ({ type, handleClose, institutionContactId, institutionId }) => {
    const { refetch: refresh } = useListContext();
    const notify = useNotify();
    const { getValues } = useFormContext();
    const { reset: resetForm } = useForm();
    const [color] = useState("#1976d2");

    const { mutate, isLoading } = useMutation(
        () => {

            const institutionContactTypeId = getValues('InstitutionContactTypeSelect');
            const name = getValues('name');
            const email = getValues('email');
            const phoneNumber = getValues('phone_number');
            const streetName = getValues('street_name');
            const cityName = getValues('city_name');
            const stateAbbreviation = getValues('state_abbreviation');
            const zip = getValues('zip');

            const institutionContact = {
                institution_contact_type_id: institutionContactTypeId,
                name: name,
                email: email,
                phone_number: phoneNumber,
                street_name: streetName,
                city_name: cityName,
                state_abbreviation: stateAbbreviation,
                zip: zip,
            };

            if (type === 'create') {
                institutionContact.institution_id = institutionId;

                return dataProvider.createInstitutionContact(institutionContact)
            } else {
                return dataProvider.updateInstitutionContact(institutionContactId, institutionContact);
            }
        },
        {
            onSuccess: () => {
                refresh();
                notify('Institution Contact ' + type + 'd successfully', { type: "success"});
                handleClose(true);
                resetForm();
            },
            onError: () => {
                notify('Could not ' + type + ' Institution Contact', { type: "error"});
                handleClose();
                if (type === 'update') {
                    resetForm();
                }
            }
        }
    );

    if (isLoading) {
        return (
            <Box>
                <ClipLoader color={color} display="block"/>
            </Box>
        )
    }

    const handleFormSubmit = () => {
        const name = getValues('name');
        const email = getValues('email');
        const zip = getValues('zip');

        let emailRegex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let valid = (
            (name && name.length) > 0 &&
            emailRegex.test(email) &&
            (
                zip ? (zip.length === 5) : true
            )
        );

        if (!valid) {
            notify('Please fill in all values', { type: "error"});
            return;
        }

        mutate();
    }

    return (
        <Button
            type="submit"
            textalign='center'
            onClick={handleFormSubmit}
            variant="contained"
            color="primary"
        >
            {type === 'create' ? 'Create' : 'Update'}
        </Button>
    );
};