import * as React from 'react';
import { Title } from 'react-admin';
import {styled} from "@mui/material/styles";
import {PageTitle} from "../../../Components/PageTitle";

const Viewer = styled('embed')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
    height: '100%',
}));

export const UserGuide = () => {
    return (
        <>
            <Title title={<PageTitle label="TrueData Admin User Guide" />} />
            <Viewer src="https://truedata.ai/TrueDataAdminUserGuide.pdf" type="application/pdf" />
        </>
    ); 
};