import * as React from "react";
import {useMutation} from "react-query";
import dataProvider from "../../Providers/dataProvider";
import DeleteIcon from "@mui/icons-material/Delete";

export const DeleteButton = ({notification, success}) => {
    const { mutate } = useMutation({
            mutationFn: (notificationId) => {
                return dataProvider.deleteNotification(notificationId);
            },
        }
    );

    if (!notification) {
        return;
    }

    const deleteNotification = () => {
        mutate(notification.id);
        notification.deleted = true;
        success(notification);
    };

    return (
        <DeleteIcon
            key={"icon2_" + notification.id}
            color="secondary"
            sx={{
                cursor: 'pointer',
                fontSize: 20
            }}
            onClick={() => deleteNotification()}
        />
    );
}