import {EditButton, ShowButton} from "react-admin";
import {TopToolbar} from "./TopToolbar";

export const ShowActions = ({ showEditButton = true }) => {
    return (
        <TopToolbar backButton={true}>
            { showEditButton ? (
                <EditButton />
            ) : (<></>)}
        </TopToolbar>
    );
};

export const EditActions = () => (
    <TopToolbar>
        <ShowButton />
    </TopToolbar>
);