import {useNotify, useRecordContext} from "react-admin";
import {useMutation} from "react-query";
import dataProvider from "../../Providers/dataProvider";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import React from "react";

export const InvoiceExportDetailsButton = ({ disabled = false }) => {
    const record = useRecordContext();
    const invoiceId = record?.id;
    const notify = useNotify();
    const { mutate, isLoading } = useMutation(
        ['exportInvoiceDetails'],
        () => dataProvider.exportInvoiceDetails('admin', invoiceId),
        {
            onSuccess: (data) => {
                let a = document.createElement('a');
                a.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.json}`;
                a.download = 'details.xlsx';
                a.click();

            },
            onError: () => {
                notify('Could not download invoice details', {type: "error"});
            }
        }
    );

    const handleClick = () => {
        mutate();
    }

    return (
        <LoadingButton
            loading={isLoading}
            type="submit"
            textalign="center"
            onClick={handleClick}
            startIcon={<FileDownloadIcon />}
            size="small"
            sx={{lineHeight: 1.5}}
            disabled={disabled}
        >
            XLSX
        </LoadingButton>
    );
};