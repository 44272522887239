import Box from "@mui/material/Box";
import {Alert, Grid, IconButton, Typography} from "@mui/material";
import {MarkAsReadButton} from "./MarkAsReadButton";
import {DeleteButton} from "./DeleteButton";
import Divider from "@mui/material/Divider";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";

const ADMIN_NOTIFICATION_ALERT_TYPES = {
    1: 'success',
    2: 'info',
    3: 'warning',
    4: 'error',
};

const ADMIN_NOTIFICATION_ENTITY_TYPES = {
    1: 'Message',
    2: 'IVES Request',
};

export const NotificationsDrawerContent = ({ dataForList, markAsReadAndRedirect, updateNotificationInState, setOpenMessage, setMessageContent, toggleDrawer }) => {
    /** @var notification.id **/
    /** @var notification.read **/
    /** @var notification.admin_notification_alert_type_id **/
    /** @var notification.admin_notification_entity_type_id **/
    /** @var notification.text **/
    /** @var notification.entity_id **/
    const NotificationAlerts = () => {

        const clickNotification = (notification) => {
            let link = null;
            if (ADMIN_NOTIFICATION_ENTITY_TYPES[notification.admin_notification_entity_type_id] === 'Message') {
                setMessageContent(notification.text);
                setOpenMessage(true);
            } else if (ADMIN_NOTIFICATION_ENTITY_TYPES[notification.admin_notification_entity_type_id] === 'IVES Request') {
                link = '/ives-requests/' + notification.entity_id + '/show';
            }

            markAsReadAndRedirect(notification, link);
        }

        const Empty = (
            <>
                <Box
                    sx={{textAlign: 'center', paddingTop: 5}}
                >
                    <Typography variant="body" color="success">No notifications</Typography>
                </Box>
            </>
        );

        if (!dataForList || dataForList.length === 0) {
            return Empty;
        }

        let nonDeleted = 0;
        dataForList.forEach((notification) => {
            if (!("deleted" in notification)) {
                nonDeleted++;
            }
        });

        if (nonDeleted === 0) {
            return Empty;
        }

        return (
            <>
                {
                    dataForList.map((notification) => {
                        if ("deleted" in notification) {
                            return (<div key={notification.id} />);
                        }
            
                        const variant = notification.read ? 'outlined' : 'filled';
                        const severity = ADMIN_NOTIFICATION_ALERT_TYPES[notification.admin_notification_alert_type_id];
                        const text = notification.text.length < 115 ? notification.text : notification.text.substring(0, 115) + '... (see more)';
            
                        return (
                            <div key={notification.id}>
                                <Grid key={"grid1_" + notification.id} container>
                                    <Grid key={"grid2_" + notification.id} item xs={11} padding={1}>
                                        <Alert
                                            key={"alert_" + notification.id}
                                            variant={variant}
                                            severity={severity}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => clickNotification(notification)}
                                        >
                                            {text}
                                        </Alert>
                                    </Grid>
                                    <Grid key={"grid3_" + notification.id} item xs={1}>
                                        <Grid key={"grid4_" + notification.id} container>
                                            <Grid key={"grid5_" + notification.id} item xs={12}>
                                                <MarkAsReadButton notification={notification} success={updateNotificationInState} />
                                            </Grid>
                                            <Grid key={"grid6_" + notification.id} item xs={12}>
                                                <DeleteButton notification={notification} success={updateNotificationInState} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        );
                    })
                }
            </>
        )
    }

    return (
        <Box
            sx={{width: 360, padding: 1}}
            role="presentation"
        >
            <Box>
                <Grid container padding={1}>
                    <Grid item xs={11}>
                        <Typography variant="h5" color="primary">Notifications</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            sx={{marginTop: -0.5}}
                            size="small"
                            aria-label="close"
                            onClick={toggleDrawer(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <NotificationAlerts />
        </Box>
    );
}