import {Show, Edit, PasswordInput, useNotify, useRedirect, DateField, Toolbar, SaveButton} from "react-admin";
import { SimpleShowLayout, TextField, required, SimpleForm, TextInput, EditButton} from "react-admin";
import { ShowButton } from "react-admin";
import {PageTitle} from "../../Components/PageTitle";
import React from "react";
import {TopToolbar} from "../../Components/TopToolbar";

export const UserProfileShow = () => {
    return (
        <Show title={<PageTitle label="Profile" />} actions={<ShowActions />}>
            <SimpleShowLayout>
                <TextField source="name"/>
                <TextField source="email"/>
                <DateField showTime={true} source="updated_at" label="Updated" />
            </SimpleShowLayout>
        </Show>
    );
}

export const UserProfileEdit = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('Password updated successfully', { type: "success"});
        redirect('/user/profile/1/show');
    };

    return (
        <Edit title={<PageTitle label="Edit Profile" />} mutationMode="pessimistic" mutationOptions={{ onSuccess }} actions={<EditActions />} redirect="show">
            <SimpleForm validate={PasswordValidate} toolbar={<EditToolbar />}>
                <TextInput source="name" name={"name"} disabled={true} style={{ width : '300px' }} />
                <TextInput source="email" name={"email"} disabled={true} style={{ width : '300px' }} />
                <PasswordInput source="password" inputProps={{ autoComplete: 'off' }} validate={[required()]} name={"password"} style={{ width : '300px' }} />
                <PasswordInput source="confirmPassword" inputProps={{ autoComplete: 'off' }} validate={[required()]} name={"confirmPassword"} style={{ width : '300px' }} />
            </SimpleForm>
        </Edit>
    );
};

const PasswordValidate = values => {
    const errors = {};
    if (!values.password) {
        errors.password = 'Required';
    }
    if (!values.confirmPassword ) {
        errors.confirmPassword = 'Required' ;
    } else if (values.confirmPassword !== values.password) {
        errors.confirmPassword = 'Password mismatched' ;
    }

    return errors;
};

const ShowActions = () => (
    <TopToolbar>
        <EditButton />
    </TopToolbar>
);

const EditActions = () => (
    <TopToolbar>
        <ShowButton />
    </TopToolbar>
);

const EditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);