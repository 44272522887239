import {Box, Grid, Typography} from "@mui/material";
import {CreateButton} from "react-admin";
import React from "react";
import {ItemCenter} from "./Items";

export const EmptyList = ({ resourceLabel, createButton }) => {
    return (
        <Grid container spacing={2} justifyContent="center" alignItems="stretch" sx={{ marginTop: '1%' }}>
            <Grid item>
                <ItemCenter>
                    <Box textAlign="center" m={1}>
                        <Typography variant="h5" paragraph>
                            You do not have any {resourceLabel} yet
                        </Typography>
                        { createButton ? (
                            <CreateButton variant="outlined" />
                        ) : (<></>)}
                    </Box>
                </ItemCenter>
            </Grid>
        </Grid>
    );
};