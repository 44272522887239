import * as React from 'react';
import {List, SimpleList, RecordContextProvider, RichTextField, useRecordContext, useRefresh} from "react-admin";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import {useMutation} from "react-query";
import dataProvider from "../../../Providers/dataProvider";
import {Datagrid} from "../../../Components/Datagrid";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const Announcements = ({ condensed = false }) => {
    const defaultDialog = {
        'title': '',
        'message': '',
        'date': '',
    }
    const [open, setOpen] = React.useState(false);
    const [dialog, setDialog] = React.useState(defaultDialog);
    const refresh = useRefresh();

    const { mutate } = useMutation({
            mutationFn: (announcementId) => {
                return dataProvider.markAnnouncementAsRead(announcementId);
            },
        },
        {
            onSuccess: () => {
                refresh();
            }
        }
    );

    const handleClickOpen = (id, resource, record) => {
        if (record) {
            setDialog(
                {
                    'title': record.title,
                    'message': record.message,
                    'date': record.created_at.substring(0, 10),
                }
            );
            mutate(record.id);
            setOpen(true);
        }
    };

    const handleClose = () => {
        setDialog(defaultDialog);
        setOpen(false);
    };

    return (
        <>
            <List title=" " resource="user/announcements" pagination={false} exporter={false} empty={false} queryOptions={{refetchInterval: 5000}}>
                {condensed ? (
                    <SimpleList
                        linkType={false}
                        primaryText={(record) => record.title}
                        secondaryText={(record) => new Date(record.created_at).toLocaleDateString("en-US", {day: "numeric", month: "long", year: "numeric"})}
                    />
                ) : (
                    <Datagrid header={<></>} bulkActionButtons={false} rowClick={handleClickOpen}>
                        <CustomField />
                    </Datagrid>
                )}
            </List>
            <React.Fragment>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        {dialog.title}
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            {dialog.date}
                        </Typography>
                        <RichTextField source={dialog.message} />
                        <RecordContextProvider value={ { message: dialog.message } }>
                            <RichTextField source="message" />
                        </RecordContextProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose}>
                            Close
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
            </React.Fragment>
        </>
    );
}

const CustomField = () => {
    const record = useRecordContext();

    if (!record) {
        return '';
    }

    let fontWeight = 'bold';

    /** @var record.read **/
    if (record.read) {
        fontWeight = 'regular';
    }

    const sx = {
        fontWeight: fontWeight
    };

    /** @var record.created_at **/
    const date = new Date(record.created_at);
    const formattedDate = date.toLocaleDateString("en-US", {
        day: "numeric",
        month: "long",
        year: "numeric"
    })

    /** @var record.title **/
    const label = formattedDate + ' - ' + record.title;
    
    return (
        <Typography
            component="span"
            variant="body2"
            sx={sx}
        >
            {label}
        </Typography>
    );
}

export default Announcements;