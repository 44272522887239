import {useNotify, useRecordContext} from "react-admin";
import React from "react";
import {useMutation} from "react-query";
import dataProvider from "../../Providers/dataProvider";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {Dialog} from "@mui/material";

export const InvoiceExportButton = ({ disabled = false }) => {
    const record = useRecordContext();
    const invoiceId = record?.id;
    const notify = useNotify();
    const [ open, setOpen ] = React.useState(false);
    const [ pdf, setPdf ] = React.useState('');
    const { mutate, isLoading } = useMutation(
        () => dataProvider.exportInvoice('admin', invoiceId),
        {
            onSuccess: (data) => {
                /** @var data.json.pdf **/
                setPdf(data.json.pdf);
                setOpen(true);
            },
            onError: () => {
                notify('Could not download invoice', { type: "error"});
                setOpen(false);
            }
        }
    );

    /** @var record.line_item_count **/
    /** @var record.amount **/
    if (!record || record.amount === 0) {
        return '';
    }

    const handleFormSubmit = () => {
        mutate();
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <LoadingButton
                loading={isLoading}
                type="submit"
                textalign="center"
                onClick={handleFormSubmit}
                startIcon={<FileDownloadIcon />}
                size="small"
                sx={{lineHeight: 1.5}}
                disabled={disabled}
            >
                PDF
            </LoadingButton>
            <Dialog
                onClose={handleClose}
                open={open}
                maxWidth={"md"}
                fullWidth={true}
            >
                <embed style={{ height: 500, overflow: 'scroll' }} src={`data:application/pdf;base64,${pdf}`} type="application/pdf" width="100%"></embed>
            </Dialog>
        </div>
    );
};