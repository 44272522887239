import {
    Show,
    SimpleShowLayout,
    List,
    TextField,
    DateField,
    ArrayField,
    ReferenceField,
    useShowController,
    FunctionField,
    SearchInput,
} from "react-admin";
import {PageTitle} from "../../../Components/PageTitle";
import {ShowActions} from "../../../Components/Actions";
import React from "react";
import {Card, Grid, Typography} from "@mui/material";
import {IvesRequestBillingMatrix} from "./Components/InstitutionIvesRequestBillingMatrix";
import {IvesRequestBilling} from "./Components/InstitutionIvesRequestBilling";
import {renderPhoneNumber} from "../../../Components/Functions/PhoneNumber";
import {Datagrid} from "../../../Components/Datagrid";
import {Item} from "../../../Components/Items";

export const AffiliateInstitutionList = () => {
    return (
        <List filters={postFilters} resource="affiliate/institutions" hasCreate={false} exporter={false}>
            <Datagrid bulkActionButtons={false} rowClick="show">
                <TextField source="name" />
                <FunctionField render={renderPhoneNumber} label="Phone" />
                <TextField source="street_name" label="Address" />
                <TextField source="city_name" label="City" />
                <TextField source="state_abbreviation" label="State" />
                <TextField source="zip" label="ZIP Code" />
                <TextField source="industry.name" label="Industry" sortable={false} />
                <TextField source="billing_model.name" label="Billing Model" sortable={false} />
                <DateField showTime={true} source="created_at" label="Created"  />
                <DateField showTime={true} source="updated_at" label="Updated"  />
            </Datagrid>
        </List>
    );
};

const postFilters = [
    <SearchInput placeholder="Name" source="name" name="name" alwaysOn={true} key={Math.random()} />,
];

export const AffiliateInstitutionShow = () => {
    const showController = useShowController();

    return (
        <Grid container spacing={2}>
            <Grid item lg={12}>
                <Item>
                    <Show title={<PageTitle />} actions={<ShowActions showEditButton={false} />} resource='affiliate/institutions' >
                        <SimpleShowLayout>
                            <TextField source="name"/>
                            <FunctionField render={renderPhoneNumber} label="Phone" />
                            <TextField source="street_name" label="Address" />
                            <TextField source="city_name" label="City" />
                            <TextField source="state_abbreviation" label="State" />
                            <TextField source="zip" label="ZIP Code" />
                            <ReferenceField source="industry_id" reference="affiliate/industries" link="show">
                                <TextField source="name" />
                            </ReferenceField>
                            <ReferenceField source="billing_model_id" reference="affiliate/billing-models" link="show">
                                <TextField source="name" />
                            </ReferenceField>
                            <DateField showTime={true} source="created_at" label="Created" />
                            <DateField showTime={true} source="updated_at" label="Updated" />
                            <ArrayField source="branches">
                                <Datagrid bulkActionButtons={false} rowClick={false}>
                                    <TextField source="name" />
                                </Datagrid>
                            </ArrayField>
                        </SimpleShowLayout>
                    </Show>
                </Item>
            </Grid>
            <Grid item lg={12}>
                <Item>
                    <Card>
                        <Typography variant="h6" padding={1}>IVES Request Billing Matrix</Typography>
                        { showController?.record?.billing_model_id === 1 ?
                            ( <IvesRequestBilling institutionId={showController?.record?.id} /> )
                            :
                            ( <IvesRequestBillingMatrix institutionId={showController?.record?.id} /> )
                        }
                    </Card>
                </Item>
            </Grid>
        </Grid>
    );
};

// const branchRowClick = (id) => {
//     return "affiliate/branches/" + id + "/show";
// };