import {
    Show,
    SimpleShowLayout,
    List,
    TextField,
    DateField,
    required,
    SimpleForm,
    Edit,
    TextInput,
    Create,
    ReferenceField,
    ReferenceArrayInput,
    SelectInput,
    useShowController,
    useRefresh,
    regex,
    FunctionField,
    SearchInput,
    useNotify,
    useRedirect,
    BooleanField,
    BooleanInput,
} from "react-admin";
import {PageTitle} from "../../../Components/PageTitle";
import {EditActions, ShowActions} from "../../../Components/Actions";
import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import {IvesRequestBillingMatrix} from "./InstitutionIvesRequestBillingMatrix";
import {IvesRequestBilling} from "./InstitutionIvesRequestBilling";
import {StateInput} from "../../../Components/Inputs/StateInput";
import {formatPhoneNumber, parsePhoneNumber, renderPhoneNumber} from "../../../Components/Functions/PhoneNumber";
import {InstitutionContacts} from "./InstitutionContacts";
import {Accordion, AccordionDetails, AccordionSummary} from "../../../Components/Accordion";
import {AutocompleteStreetNameInput} from "./AutocompleteStreetNameInput";
import {Datagrid} from "../../../Components/Datagrid";
import {Item} from "../../../Components/Items";

export const InstitutionList = () => {
    return (
        <List filters={postFilters} resource="institutions" hasCreate={true} exporter={false}>
            <Datagrid bulkActionButtons={false} rowClick="show">
                <TextField source="name" />
                <FunctionField render={renderPhoneNumber} label="Phone" />
                <TextField source="industry.name" label="Industry" sortable={false} />
                <TextField source="billing_model.name" label="Billing Model" sortable={false} />
                <ReferenceField source="affiliate_user_id" resource="affiliate-users" reference="users" label="Affiliate User" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <BooleanField source="is_tetitos_affiliate" label="Tetitos Affiliate" textAlign="center" sx={{ width: 20 }} />
                <DateField showTime={true} source="created_at" label="Created" />
            </Datagrid>
        </List>
    );
};

const postFilters = [
    <SearchInput placeholder="Name" source="name" name="name" alwaysOn={true} key={Math.random()} />,
];

export const InstitutionCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data) => {
        notify(`Institution created successfully`, { type: "success"});
        redirect('show', 'institutions', data.id, data);
    };

    return (
        <Create mutationOptions={{ onSuccess }}>
            <InstitutionForm />
        </Create>
    );
}

export const InstitutionShow = () => {
    const showController = useShowController();
    const refresh = useRefresh();
    return (
        <Show title={<PageTitle />} actions={<ShowActions />}>
            <Accordion defaultExpanded>
                <AccordionSummary >
                    <Typography>Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1} direction="row" justifyContent="space-evenly" alignItems="stretch" sx={{padding: 1}}>
                        <Grid item md={6} sm={12}>
                            <Item>
                                <Box sx={{padding: 1}}>
                                    <Typography variant="h6">Information</Typography>
                                </Box>
                                <SimpleShowLayout>
                                    <TextField source="name" />
                                    <FunctionField render={renderPhoneNumber} label="Phone" />
                                    <TextField source="street_name" label="Address" />
                                    <TextField source="city_name" label="City" />
                                    <TextField source="state_abbreviation" label="State" />
                                    <TextField source="zip" label="ZIP Code" />
                                </SimpleShowLayout>
                            </Item>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Item>
                                <Box sx={{padding: 1}}>
                                    <Typography variant="h6">Miscellaneous</Typography>
                                </Box>
                                <SimpleShowLayout>
                                    <ReferenceField source="industry_id" reference="industries" link="show">
                                        <TextField source="name" />
                                    </ReferenceField>
                                    <ReferenceField source="billing_model_id" reference="billing-models" link="show">
                                        <TextField source="name" />
                                    </ReferenceField>
                                    <DateField showTime={true} source="created_at" label="Created" />
                                    <DateField showTime={true} source="updated_at" label="Updated" />
                                    <ReferenceField source="affiliate_user_id" resource="affiliate-users" reference="users" label="Affiliate User" link="show">
                                        <TextField source="name" />
                                    </ReferenceField>
                                    <BooleanField source="is_tetitos_affiliate" label="Tetitos Affiliate" />
                                </SimpleShowLayout>
                            </Item>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary >
                    <Typography>Billing</Typography>
                </AccordionSummary>
                { showController?.record?.billing_model_id === 1 ?
                    ( <IvesRequestBilling institutionId={showController?.record?.id} refresh={refresh} /> )
                    :
                    ( <IvesRequestBillingMatrix institutionId={showController?.record?.id} refresh={refresh} /> )
                }
            </Accordion>
            <Accordion>
                <AccordionSummary >
                    <Typography>Contacts</Typography>
                </AccordionSummary>
                <InstitutionContacts institutionId={showController?.record?.id} />
            </Accordion>
        </Show>
    );
};

export const InstitutionEdit = () => (
    <Edit title={<PageTitle />} mutationMode="pessimistic" actions={<EditActions />} redirect="show">
        <InstitutionForm />
    </Edit>
);

const InstitutionForm = () => {
    function onAutoFocus (event) {
        if (event.target.autocomplete) {
            event.target.autocomplete = "whatever";
        }
    }

    return (
        <SimpleForm autoComplete="off">
            <Grid container spacing={1} direction="row" justifyContent="space-evenly" alignItems="stretch" sx={{padding: 1}}>
                <Grid item lg={6} xs={12}>
                    <Grid container direction="column" justifyContent="flex-start">
                        <Grid item>
                            <Box sx={{padding: 1}}>
                                <Typography variant="h6">Information</Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <TextInput
                                source="name"
                                validate={required()}
                                name={"name"}
                                sx={{ width: 400 }}
                                onFocus={onAutoFocus}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item>
                            <TextInput
                                source="phone_number"
                                validate={[required()]}
                                name={"phone_number"}
                                sx={{ width: 400 }}
                                format={formatPhoneNumber}
                                parse={parsePhoneNumber}
                                onFocus={onAutoFocus}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item>
                            <AutocompleteStreetNameInput
                                streetNameInputLabel="Street Name *"
                                streetNameInputName="street_name"
                                cityNameInputName="city_name"
                                stateAbbreviationInputName="state_abbreviation"
                                zipInputName="zip"
                                sx={{ width: 400, marginBottom: 3 }}
                            />
                        </Grid>
                        <Grid item>
                            <TextInput
                                source="city_name"
                                validate={[required()]}
                                name={"city_name"}
                                sx={{ width: 400 }}
                                onFocus={onAutoFocus}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item>
                            <StateInput
                                source="state_abbreviation"
                                validate={[required()]}
                                name={"state_abbreviation"}
                                label="State"
                                sx={{ width: 400 }}
                                onFocus={onAutoFocus}
                            />
                        </Grid>
                        <Grid item>
                            <TextInput
                                source="zip"
                                validate={[required(), regex(/^\d{5}$/, 'Must be a valid ZIP Code')]}
                                name={"zip"}
                                sx={{ width: 400 }}
                                label="ZIP Code"
                                onFocus={onAutoFocus}
                                autoComplete="off"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Grid container direction="column" justifyContent="flex-start">
                        <Grid item>
                            <Box sx={{padding: 1}}>
                                <Typography variant="h6">Miscellaneous</Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <ReferenceArrayInput name="Industry" source="industry_id" reference="industries" perPage={1000} sort={{ field: "name", order: "ASC" }}>
                                <SelectInput optionText="name" required={true} sx={{ width : 400 }} />
                            </ReferenceArrayInput>
                        </Grid>
                        <Grid item>
                            <ReferenceArrayInput name="Billing Model" source="billing_model_id" reference="billing-models" perPage={1000} sort={{ field: "name", order: "ASC" }}>
                                <SelectInput optionText="name" required={true} sx={{ width : 400 }} />
                            </ReferenceArrayInput>
                        </Grid>
                        <Grid item>
                            <ReferenceArrayInput name="Affiliate User" source="affiliate_user_id" reference="affiliate-users" perPage={1000} sort={{ field: "name", order: "ASC" }}>
                                <SelectInput optionText="name" sx={{ width : 400 }} />
                            </ReferenceArrayInput>
                        </Grid>
                        <Grid item>
                            <BooleanInput source="is_tetitos_affiliate" label="Tetitos Affiliate" name="is_tetitos_affiliate"/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SimpleForm>
    )
}