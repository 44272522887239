import {Box, Grid, Typography} from "@mui/material";
import {email, maxLength, minLength, TextInput} from "react-admin";
import React, {useEffect} from "react";
import {useFormContext} from "react-hook-form";

export const RequestCreateTaxpayerNotificationInput = ({ retryData }) => {
    const validateTaxpayerEmail = [email(), minLength(3), maxLength(128)];
    const { setValue } = useFormContext();

    useEffect(() => {
        if (retryData) {
            /** @var retryData.taxpayer_email **/
            setTimeout(() => setValue('taxpayer_email', retryData.taxpayer_email));
        }

    }, [setValue, retryData]);

    function onAutoFocus (event) {
        if (event.target.autocomplete) {
            event.target.autocomplete = "whatever";
        }
    }

    return (
        <Grid item md={3} sm={6} xs={12}>
            <Grid container direction="column" justifyContent="flex-start">
                <Grid item>
                    <Box>
                        <Typography variant="h6">Taxpayer/Applicant Notification</Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <TextInput source="taxpayer_email" helperText={false} validate={validateTaxpayerEmail} name={"taxpayer_email"} label="Email" sx={{width: "100%"}} autoComplete="off" onFocus={onAutoFocus} />
                </Grid>
            </Grid>
        </Grid>
    );
}