import {useRecordContext} from "react-admin";
import {useMediaQuery} from "@mui/material";

export const PageTitle = (props) => {
    const record = useRecordContext();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('md'));

    if (isSmall) {
        return <></>;
    }

    if (props.label) {
        return <span>{props.label}</span>;
    }

    if (!record) {
        return <span></span>;
    }

    /** @var record.name */
    return <span>{record.name}</span>;
}