import { makeApiCall } from "./httpClient";

const authProvider = {
    login: ({ email, password }) => {
        return makeApiCall("/sanctum/csrf-cookie", { method: "GET" })
            .then(() => {
                return makeApiCall(
                    "/login",
                    { method: "POST" },
                    {
                        email: email,
                        password: password
                    }
                    )
                    .then(response => {
                        if (response.status < 200 || response.status >= 300) {
                            throw new Error('Unknown Error')
                        }
                        localStorage.setItem("user", JSON.stringify(response.json.data));
                        
                        let redirect = localStorage.getItem('redirect');
                        localStorage.removeItem('redirect');

                        if (redirect) {
                            const port = window.location.port;
                            const portString = port ? ':' + port : '';
                            redirect = redirect.replace(window.location.protocol + '//' + window.location.hostname + portString + '/', '');
                            redirect = redirect.replace('#', '');
                        }

                        const redirectObject = redirect ? { redirectTo: redirect } : {};

                        return Promise.resolve(redirectObject);
                    })
                    .catch(response => {
                        if (response.status === 401) {
                            throw new Error('Unauthorized')
                        }
                        throw new Error('Unknown Error')
                    });
                }
            )
            // .catch(response => {
            //     return Promise.resolve(response);
            // })
            ;
    },
    
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('user');
            return Promise.reject({ redirectTo: '/login' });
        }
        return Promise.resolve();
    },
    
    checkAuth: () => {
        if (localStorage.getItem('user')) {
            return Promise.resolve();
        } else {
            if (!localStorage.getItem('redirect') && !window.location.href.includes('login') && !window.location.href.includes('token=')) {
                localStorage.setItem('redirect', window.location.href);
            }

            return Promise.reject();
        }
    },
    
    logout: () => {
        const userSettings = JSON.parse(localStorage.getItem('user'));
        if (userSettings) {
            let title = "TrueData ";

            if (userSettings.environment === 'Sandbox') {
                title += "Sandbox"
            } else {
                title += "Admin"
            }

            document.title = title;
        }

        return makeApiCall("/logout", { method: 'GET' })
            .then(() => {
                localStorage.removeItem('user');
                return Promise.resolve();
            })
            .catch(() => {
                localStorage.removeItem('user');
                return Promise.resolve();
            });
    },
    
    getIdentity: () => {
        try {
            const { id, name, token, role } = JSON.parse(localStorage.getItem('user'));
            return Promise.resolve({ id, name, token, role });
        } catch (error) {
            return Promise.reject(error);
        }
    },
    
    getPermissions: () => {
        const { permissions } = JSON.parse(localStorage.getItem('user'));

        return permissions;
    },
};

export default authProvider;