import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, useMediaQuery} from "@mui/material";
import React, {useState} from "react";
import {
    List,
    NumberField,
    NumberInput,
    SimpleList,
    useListContext,
    useNotify,
    useRecordContext,
} from "react-admin";
import {useFormContext} from "react-hook-form";
import {useMutation} from "react-query";
import {ClipLoader} from "react-spinners";
import {Form} from "ra-core";
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import dataProvider from "../../../Providers/dataProvider";
import {CreateButton} from "../../../Components/Inputs/CreateButton";
import {Datagrid} from "../../../Components/Datagrid";

export const IvesRequestBilling = ({ institutionId, refresh }) => {
    const isMedium = useMediaQuery(theme => theme.breakpoints.down('md'));

    const { mutate, isError} = useMutation(
        () => dataProvider.getIvesRequestBillingValidity(institutionId)
    );

    if (!institutionId) {
        return <></>
    }

    const updateValidity = () => {
        mutate();
    }

    let status = true;

    if (isError) {
        status = false;
    }

    return (
        <React.Fragment>
            <List
                resource="ives-request-billing"
                filter={{ institution_id: institutionId }}
                empty={false}
                pagination={false}
                exporter={false}
                hasCreate={false}
                actions={false}
                title={<></>}
            >
                {isMedium ? (
                    <SimpleList
                        hasBulkActions={false}
                        linkType={false}
                        primaryText="Not available in mobile mode"
                    />
                ) : (
                    <>
                    <ValidityStatus status={status} />
                        <Datagrid bulkActionButtons={false} rowClick={false}>
                            <NumberField source="year1_amount" label="1 Yr" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} sortable={false} />
                            <NumberField source="year2_amount" label="2 Yrs" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} sortable={false} />
                            <NumberField source="year3_amount" label="3 Yrs" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} sortable={false} />
                            <NumberField source="year4_amount" label="4 Yrs" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} sortable={false} />
                            <NumberField source="year5_amount" label="5 Yrs" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} sortable={false} />
                            <NumberField source="year6_amount" label="6 Yrs" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} sortable={false} />
                            <NumberField source="year7_amount" label="7 Yrs" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} sortable={false} />
                            <NumberField source="year8_amount" label="8 Yrs" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} sortable={false} />
                            <NumberField source="year9_amount" label="9 Yrs" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} sortable={false} />
                            <NumberField source="year10_amount" label="10 Yrs" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} sortable={false} />
                            <IvesRequestBillingEditDialog refresh={refresh} updateValidity={updateValidity} />
                        </Datagrid>
                        <Box padding={1}>
                            <IvesRequestBillingCreateDialog institutionId={institutionId} refresh={refresh} updateValidity={updateValidity} />
                        </Box>
                    </>
                )}
            </List>
        </React.Fragment>
    );
};

const ValidityStatus = ({status}) => {
    const listContext = useListContext();
    if (listContext?.data?.length === 0) {
        return <></>;
    }

    return status ? (<CheckIcon style={{ color: "green" }} />) : (<PriorityHighIcon style={{ color: "red" }} />);    
};

const CreateIvesRequestBillingButton = ({ handleClose, institutionId, refresh, updateValidity }) => {
    const notify = useNotify();
    const { getValues } = useFormContext();
    const [color] = useState("#1976d2");
    const year1Amount = getValues('year1_amount');
    const year2Amount = getValues('year2_amount');
    const year3Amount = getValues('year3_amount');
    const year4Amount = getValues('year4_amount');
    const year5Amount = getValues('year5_amount');
    const year6Amount = getValues('year6_amount');
    const year7Amount = getValues('year7_amount');
    const year8Amount = getValues('year8_amount');
    const year9Amount = getValues('year9_amount');
    const year10Amount = getValues('year10_amount');
    const ivesRequestBilling = {
        institution_id: institutionId,
        year1_amount: year1Amount,
        year2_amount: year2Amount,
        year3_amount: year3Amount,
        year4_amount: year4Amount,
        year5_amount: year5Amount,
        year6_amount: year6Amount,
        year7_amount: year7Amount,
        year8_amount: year8Amount,
        year9_amount: year9Amount,
        year10_amount: year10Amount
    };
    const { mutate, isSuccess, isLoading, isError } = useMutation(
        () => dataProvider.createIvesRequestBilling(ivesRequestBilling)
    );
    if (isLoading) {
        return (
            <Box>
                <ClipLoader color={color} display="block"/>
            </Box>
        )
    }
    if (isError) {
        notify('Could not create IVES Request Billing', { type: "error"});
        handleClose();
    }
    if (isSuccess) {
        updateValidity();
        refresh();
        notify('IVES Request Billing created successfully', { type: "success"});
        handleClose(true);
    }

    const handleFormSubmit = () => {
        let regex = /^\d*\.?\d*$/;
        let valid = (
            regex.test(year1Amount) &&
            regex.test(year2Amount) &&
            regex.test(year3Amount) &&
            regex.test(year4Amount) &&
            regex.test(year5Amount) &&
            regex.test(year6Amount) &&
            regex.test(year7Amount) &&
            regex.test(year8Amount) &&
            regex.test(year9Amount) &&
            regex.test(year10Amount)
        );

        if (!valid) {
            notify('Please fill in all values', { type: "error"});
            return;
        }
        mutate();
    }
    return (
        <Button
            type="submit"
            textalign='center'
            onClick={handleFormSubmit}
            variant="contained"
            color="primary"
        >
            Create
        </Button>
    );
};

const IvesRequestBillingCreateDialog = ({ institutionId, refresh, updateValidity }) => {
    const [open, setOpen] = React.useState(false);
    const record = useRecordContext()

    if (!record) {
        return;
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Form>
                <CreateButton onClick={handleClickOpen} />
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Create IVES Request Billing</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1}>
                            <Grid item lg={6}>
                                <NumberInput source="year1_amount" name="year1_amount" label="Amount for 1 Year"></NumberInput>
                            </Grid>
                            <Grid item lg={6}>
                                <NumberInput source="year2_amount" name="year2_amount" label="Amount for 2 Years"></NumberInput>
                            </Grid>
                            <Grid item lg={6}>
                                <NumberInput source="year3_amount" name="year3_amount" label="Amount for 3 Years"></NumberInput>
                            </Grid>
                            <Grid item lg={6}>
                                <NumberInput source="year4_amount" name="year4_amount" label="Amount for 4 Years"></NumberInput>
                            </Grid>
                            <Grid item lg={6}>
                                <NumberInput source="year5_amount" name="year5_amount" label="Amount for 5 Years"></NumberInput>
                            </Grid>
                            <Grid item lg={6}>
                                <NumberInput source="year6_amount" name="year6_amount" label="Amount for 6 Years"></NumberInput>
                            </Grid>
                            <Grid item lg={6}>
                                <NumberInput source="year7_amount" name="year7_amount" label="Amount for 7 Years"></NumberInput>
                            </Grid>
                            <Grid item lg={6}>
                                <NumberInput source="year8_amount" name="year8_amount" label="Amount for 8 Years"></NumberInput>
                            </Grid>
                            <Grid item lg={6}>
                                <NumberInput source="year9_amount" name="year9_amount" label="Amount for 9 Years"></NumberInput>
                            </Grid>
                            <Grid item lg={6}>
                                <NumberInput source="year10_amount" name="year10_amount" label="Amount for 10 Years"></NumberInput>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <CreateIvesRequestBillingButton handleClose={handleClose} institutionId={institutionId} refresh={refresh} updateValidity={updateValidity} />
                    </DialogActions>
                </Dialog>
            </Form>
        </div>
    );
}

const UpdateIvesRequestBillingButton = ({ handleClose, ivesRequestBillingId, refresh, updateValidity }) => {
    const notify = useNotify();
    const { getValues } = useFormContext();
    const [color] = useState("#1976d2");
    const year1Amount = getValues('year1_amount');
    const year2Amount = getValues('year2_amount');
    const year3Amount = getValues('year3_amount');
    const year4Amount = getValues('year4_amount');
    const year5Amount = getValues('year5_amount');
    const year6Amount = getValues('year6_amount');
    const year7Amount = getValues('year7_amount');
    const year8Amount = getValues('year8_amount');
    const year9Amount = getValues('year9_amount');
    const year10Amount = getValues('year10_amount');
    const ivesRequestBilling = {
        year1_amount: year1Amount,
        year2_amount: year2Amount,
        year3_amount: year3Amount,
        year4_amount: year4Amount,
        year5_amount: year5Amount,
        year6_amount: year6Amount,
        year7_amount: year7Amount,
        year8_amount: year8Amount,
        year9_amount: year9Amount,
        year10_amount: year10Amount
    };
    const { mutate, isSuccess, isLoading, isError } = useMutation(
        () => dataProvider.updateIvesRequestBilling(ivesRequestBillingId, ivesRequestBilling)
    );
    if (isLoading) {
        return (
            <Box>
                <ClipLoader color={color} display="block"/>
            </Box>
        )
    }
    if (isError) {
        notify('Could not update IVES Request Billing', { type: "error"});
        handleClose();
    }
    if (isSuccess) {
        updateValidity();
        refresh();
        notify('IVES Request Billing updated successfully', { type: "success"});
        handleClose(true);
    }

    const handleFormSubmit = () => {
        let regex = /^\d*\.?\d*$/;
        let valid = (
            regex.test(year1Amount) &&
            regex.test(year2Amount) &&
            regex.test(year3Amount) &&
            regex.test(year4Amount) &&
            regex.test(year5Amount) &&
            regex.test(year6Amount) &&
            regex.test(year7Amount) &&
            regex.test(year8Amount) &&
            regex.test(year9Amount) &&
            regex.test(year10Amount)
        );

        if (!valid) {
            notify('Please fill in all values', { type: "error"});
            return;
        }
        mutate();
    }

    return (
        <Button
            type="submit"
            textalign='center'
            onClick={handleFormSubmit}
            variant="contained"
            color="primary"
        >
            Update
        </Button>
    );
};

const IvesRequestBillingEditDialog = ({ refresh, updateValidity }) => {
    const record = useRecordContext();
    const [open, setOpen] = React.useState(false);
    const { mutate, data} = useMutation(
        () => dataProvider.getIvesRequestBilling(record.id)
    );

    if (!record) {
        return;
    }

    const handleClickOpen = () => {
        mutate();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Form>
                <Button startIcon={<EditIcon />} onClick={handleClickOpen} >
                    Edit
                </Button>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Create IVES Request Billing</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1}>
                            <Grid item lg={6}>
                                <NumberInput source="year1_amount" name="year1_amount" label="Amount for 1 Year" value={data?.year1_amount}></NumberInput>
                            </Grid>
                            <Grid item lg={6}>
                                <NumberInput source="year2_amount" name="year2_amount" label="Amount for 2 Years" value={data?.year2_amount}></NumberInput>
                            </Grid>
                            <Grid item lg={6}>
                                <NumberInput source="year3_amount" name="year3_amount" label="Amount for 3 Years" value={data?.year3_amount}></NumberInput>
                            </Grid>
                            <Grid item lg={6}>
                                <NumberInput source="year4_amount" name="year4_amount" label="Amount for 4 Years" value={data?.year4_amount}></NumberInput>
                            </Grid>
                            <Grid item lg={6}>
                                <NumberInput source="year5_amount" name="year5_amount" label="Amount for 5 Years" value={data?.year5_amount}></NumberInput>
                            </Grid>
                            <Grid item lg={6}>
                                <NumberInput source="year6_amount" name="year6_amount" label="Amount for 6 Years" value={data?.year6_amount}></NumberInput>
                            </Grid>
                            <Grid item lg={6}>
                                <NumberInput source="year7_amount" name="year7_amount" label="Amount for 7 Years" value={data?.year7_amount}></NumberInput>
                            </Grid>
                            <Grid item lg={6}>
                                <NumberInput source="year8_amount" name="year8_amount" label="Amount for 8 Years" value={data?.year8_amount}></NumberInput>
                            </Grid>
                            <Grid item lg={6}>
                                <NumberInput source="year9_amount" name="year9_amount" label="Amount for 9 Years" value={data?.year9_amount}></NumberInput>
                            </Grid>
                            <Grid item lg={6}>
                                <NumberInput source="year10_amount" name="year10_amount" label="Amount for 10 Years" value={data?.year10_amount}></NumberInput>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <UpdateIvesRequestBillingButton handleClose={handleClose} ivesRequestBillingId={record.id} refresh={refresh} updateValidity={updateValidity}/>
                    </DialogActions>
                </Dialog>
            </Form>
        </div>
    );
}