import * as React from "react";

export const EnvironmentLabel = () => {
    let label = <></>;
    const userSettings = JSON.parse(localStorage.getItem('user'));
    if (userSettings && userSettings.environment === 'Sandbox') {
        label = <span style={{color: "red"}}> Sandbox</span>;
    }

    return label;
}