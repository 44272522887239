import {
    Show,
    SimpleShowLayout,
    List,
    TextField,
    DateField,
    ReferenceField,
    NumberField,
    SelectInput,
    useRecordContext,
    useShowController,
} from "react-admin";
import {PageTitle} from "../../../../Components/PageTitle";
import React from "react";
import {useQuery} from "react-query";
import {Box, Grid, Typography} from "@mui/material";
import {EmptyList} from "../../../../Components/EmpyList";
import dataProvider from "../../../../Providers/dataProvider";
import {InvoiceLineItemsShow} from "../../../../Components/Invoices/InvoiceLineItems";
import {Datagrid} from "../../../../Components/Datagrid";
import {TopToolbar} from "../../../../Components/TopToolbar";
import {InvoiceExportButton} from "../../../../Components/Invoices/InvoiceExportButton";
import {InvoiceExportDetailsButton} from "../../../../Components/Invoices/InvoiceExportDetailsButton";
import {Item, ItemRight} from "../../../../Components/Items";

export const UserInvoiceList = () => {
    return (
        <List
            title={<PageTitle label="Invoices" />}
            filters={postFilters}
            resource="user/invoices"
            sort={{ field: 'created_at', order: 'DESC' }}
            hasCreate={false}
            empty={<EmptyList resourceLabel="Invoices" />}
            exporter={false}
        >
            <Datagrid bulkActionButtons={false} rowClick="show">
                <TextField source="invoice_status.name" label="Status" sortable={false} />
                <TextField source="name" />
                <DateField source="starts_at" label="Start" />
                <DateField source="ends_at" label="End" />
                <NumberField source="amount" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} />
                <DateField showTime={true} source="created_at" label="Created"  />
                <DateField showTime={true} source="updated_at" label="Updated"  />
            </Datagrid>
        </List>
    );
};

const InvoiceStatusFilterSelectInput = (props) => {
    const { data, isSuccess, isLoading, isError} = useQuery(
        ['getInvoiceStatuses'],
        () => dataProvider.getInvoiceStatuses('user')
    );

    const invoiceStatuses = data?.json;

    if (isLoading || isError || !invoiceStatuses) {
        return <span key={Math.random()}>Loading...</span>
    }

    if (isSuccess) {
        return (
            <SelectInput
                key={Math.random()}
                label={props.label}
                choices={invoiceStatuses}
                name="invoice_status_id"
                source="invoice_status_id"
                isLoading={isLoading}
                alwaysOn={true}
            />
        );
    }
};

const postFilters = [
    <InvoiceStatusFilterSelectInput label="Status" alwaysOn={true} key={Math.random()} />,
];

const InvoiceShowActions = () => (
    <TopToolbar backButton={true}>
        <InvoiceExportButton />
        <InvoiceExportDetailsButton />
    </TopToolbar>
);

const InvoiceSubtotals = () => {
    const record = useRecordContext();

    if (!record) {
        return '';
    }

    function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    return (
        <Box>
            <div style={{height: 252, width: '100%', overflowY: 'auto', overflowX: 'none'}}>
                <Grid container spacing={1}>
                    {record.subtotals.map((subtotal) => (
                        <Grid item xs={12} key={Math.random()}>
                            <ItemRight key={Math.random()}>
                                <Typography key={Math.random()} variant="caption" display="inline" noWrap >{subtotal.description}: </Typography>
                                <Typography key={Math.random()} variant="body2" display="inline" noWrap >{currencyFormat(subtotal.amount)}</Typography>
                            </ItemRight>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </Box>
    );
}

const InvoicePayments = () => {
    const record = useRecordContext();

    if (!record) {
        return '';
    }

    function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    return (
        <Box>
            <div style={{height: 252, width: '100%', overflowY: 'auto', overflowX: 'none'}}>
                <Grid container spacing={1}>
                    {record.payments.map((payment) => (
                        <Grid item xs={12} key={Math.random()}>
                            <ItemRight key={Math.random()}>
                                <Typography key={Math.random()} variant="caption" display="inline" noWrap >{payment.description}: </Typography>
                                <Typography key={Math.random()} variant="body2" display="inline" noWrap >{currencyFormat(payment.amount)}</Typography>
                            </ItemRight>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </Box>
    );
}

export const UserInvoiceShow = () => {
    const record = useShowController().record;
    
    let paymentTotal = 0;
    if (record?.payments) {
        for (let key in record.payments) {
            paymentTotal += record.payments[key].amount;
        }
        record.due = record.amount - paymentTotal;
    }

    return (
        <Grid container>
            <Grid item lg={12}>
                <Show
                    title={<PageTitle />}
                    actions={
                        <InvoiceShowActions />
                    }
                >
                    <SimpleShowLayout>
                        <Grid container>
                            <Grid item lg={4}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Invoice</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <Typography variant="caption" display="inline" noWrap >Name: </Typography>
                                            <TextField display="inline" source="name"/>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <Typography variant="caption" display="inline" noWrap >Start: </Typography>
                                            <DateField display="inline" source="starts_at" label="Start" />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <Typography variant="caption" display="inline" noWrap >End: </Typography>
                                            <DateField display="inline" source="ends_at" label="End" />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <Typography variant="caption" display="inline" noWrap >Status: </Typography>
                                            <ReferenceField source="invoice_status_id" reference="user/invoice-statuses" display="inline">
                                                <TextField source="name" />
                                            </ReferenceField>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <Typography variant="caption" display="inline" noWrap >Created: </Typography>
                                            <DateField showTime={true} display="inline" source="created_at" label="Created" />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <Typography variant="caption" display="inline" noWrap >Updated: </Typography>
                                            <DateField showTime={true} display="inline" source="updated_at" label="Updated" />
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={4}>
                                <Grid container spacing={1} alignItems="stretch">
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Summary</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <InvoiceSubtotals />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ItemRight>
                                            <Typography variant="caption" display="inline" noWrap style={{fontWeight: 'bold'}}>Total: </Typography>
                                            <NumberField source="amount" display="inline" style={{fontWeight: 'bold'}} options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} />
                                        </ItemRight>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={4}>
                                <Grid container spacing={1} alignItems="stretch">
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Payments</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <InvoicePayments />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ItemRight>
                                            <Typography variant="caption" display="inline" noWrap style={{fontWeight: 'bold'}}>Total Due: </Typography>
                                            <NumberField source="due" style={{fontWeight: 'bold'}} options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} />
                                        </ItemRight>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </SimpleShowLayout>
                </Show>
            </Grid>
            <Grid item lg={12}>
                <InvoiceLineItemsShow role="user" />
            </Grid>
        </Grid>
    );
};