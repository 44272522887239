import * as React from "react";
import DraftsIcon from "@mui/icons-material/Drafts";
import {useMutation} from "react-query";
import dataProvider from "../../Providers/dataProvider";

export const MarkAsReadButton = ({notification, success}) => {
    const { mutate } = useMutation({
            mutationFn: (notificationId) => {
                return dataProvider.markNotificationAsRead(notificationId);
            },
        }
    );

    if (!notification) {
        return;
    }

    const markAsRead = () => {
        mutate(notification.id);
        notification.read = 1;
        success(notification);
    };

    const cursor = notification.read ? 'crosshairs' : 'pointer';
    const color = notification.read ? 'disabled' : 'secondary';

    return (
        <DraftsIcon
            key={"icon1_" + notification.id}
            color={color}
            sx={{
                cursor: cursor,
                fontSize: 20,
                marginTop: 1.5
            }}
            onClick={() => markAsRead()}
        />
    );
}