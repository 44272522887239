import {SelectInput, useGetList} from "react-admin";
import React from "react";

export const IvesRequestFormFilterSelectInput = (props) => {
    const { data : ivesRequestForms, isLoading} = useGetList('ives-request-forms');

    return (
        <SelectInput
            key={Math.random()}
            label={props.label}
            choices={ivesRequestForms}
            name="ives_request_form_id"
            source="ives_request_form_id"
            isLoading={isLoading}
            alwaysOn={true}
        />
    );
};