import {required, SelectInput, useGetList} from "react-admin";
import {useFormContext, useWatch} from "react-hook-form";
import React, {useState} from "react";

export const RequestCreateFormInput = ({ source, requestFormTypeSelect }) => {
    const [ requestTypeId, setRequestTypeId ] = useState(null);
    const { resetField} = useFormContext();
    let forms = [];
    const newRequestTypeId = useWatch({ name: requestFormTypeSelect});

    if (newRequestTypeId !== requestTypeId) {
        if (requestTypeId) {
            resetField(source);
        }
        setRequestTypeId(newRequestTypeId);
    }
    const expectedFormTypeId = newRequestTypeId === 1 ? 1 : 2;

    const { data: currentIvesRequestForms, isLoading: isLoadingCurrentIvesRequestForms } = useGetList('ives-request-forms');

    if (currentIvesRequestForms) {
        currentIvesRequestForms.forEach(form => {
            /** @var form.ives_request_form_type_id **/
            if (form.ives_request_form_type_id === expectedFormTypeId) {
                form.order = form.id === 45 ? 1 : form.id + 100;
                forms.push(form);
            }
        });
    }
    forms.sort((a, b) => a.order - b.order);

    const isLoading = isLoadingCurrentIvesRequestForms || !newRequestTypeId;

    return (
            <SelectInput
                helperText={false}
                id={source}
                name={source}
                label="Form"
                source={source}
                choices={forms}
                optionText="name"
                disabled={isLoading}
                validate={[required()]}
                style={{ width : '100%' }}
            />
    );
}