import {
    ArrayField,
    AutocompleteArrayInput,
    BooleanField,
    BooleanInput,
    ChipField,
    Create,
    DateField,
    Edit,
    EditButton,
    List,
    ReferenceArrayInput,
    ReferenceField,
    SearchInput,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    SimpleList,
    SimpleShowLayout,
    SingleFieldList,
    TextField,
    TextInput,
    useEditController,
    useGetList,
    useNotify,
    useRecordContext,
} from "react-admin";
import {PageTitle} from "../../../Components/PageTitle";
import {Box, Button, Grid, Typography, useMediaQuery} from "@mui/material";
import React, {useState} from "react";
import {BranchInput} from "../../../Components/Inputs/BranchInput";
import {useMutation} from "react-query";
import {ClipLoader} from "react-spinners";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import dataProvider from "../../../Providers/dataProvider";
import {useWatch} from "react-hook-form";
import {Datagrid} from "../../../Components/Datagrid";
import {TopToolbar} from "../../../Components/TopToolbar";

export const UserList = () => {
    /** @var record.branch.institution **/
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List filters={postFilters} resource="users" hasCreate={true} exporter={false}>
            {isSmall ? (
                <SimpleList
                    hasBulkActions={false}
                    linkType="show"
                    primaryText={record => record.name}
                    secondaryText={record => `${record.branch.institution.name} - ${record.branch.name}`}
                    tertiaryText={record => record.email}
                />
            ) : (
                <Datagrid bulkActionButtons={false} rowClick="show">
                    <TextField source="name" />
                    <TextField source="email" />
                    <TextField source="branch.institution.name" label="Institution" />
                    <TextField source="branch.name" label="Branch" />
                    <TextField source="role.name" label="Role" />
                    <BooleanField source="is_active" label="Active" />
                    <DateField showTime={true} source="created_at" label="Created"  />
                    <DateField showTime={true} source="updated_at" label="Updated"  />
                </Datagrid>
            )}
        </List>
    );
}

const postFilters = [
    <SearchInput placeholder="Name" source="name" name="name" alwaysOn={true} key={Math.random()} />,
    <SearchInput placeholder="E-Mail" source="email" name="email" alwaysOn={true} key={Math.random()} />,
];

export const UserShow = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <Show title={<PageTitle />} actions={<ShowActions />}>
            {isSmall ? (
            <SimpleShowLayout>
                <TextField source="name"/>
                <TextField source="email"/>
                <ReferenceField source="role_id" reference="roles" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="branch.institution_id" reference="institutions" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="branch_id" reference="branches" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <ArrayField source="permissions">
                    <SingleFieldList linkType={false}>
                        <ChipField source="name" size="small" />
                    </SingleFieldList>
                </ArrayField>
                <BooleanField source="is_active" />
                <DateField showTime={true} source="created_at" label="Created" />
                <DateField showTime={true} source="updated_at" label="Updated" />
            </SimpleShowLayout>
            ) : (
                <Grid container>
                    <Grid item xs={4}>
                        <Box sx={{padding: 1}}>
                            <Typography variant="h6">User</Typography>
                        </Box>
                        <SimpleShowLayout>
                            <TextField source="name"/>
                            <TextField source="email"/>
                            <ReferenceField source="role_id" reference="roles" link="show">
                                <TextField source="name" />
                            </ReferenceField>
                            <ReferenceField source="branch.institution_id" reference="institutions" link="show">
                                <TextField source="name" />
                            </ReferenceField>
                            <ReferenceField source="branch_id" reference="branches" link="show">
                                <TextField source="name" />
                            </ReferenceField>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{padding: 1}}>
                            <Typography variant="h6">Resources</Typography>
                        </Box>
                        <SimpleShowLayout>
                            <ArrayField source="permissions">
                                <SingleFieldList linkType={false}>
                                    <ChipField source="name" size="small" />
                                </SingleFieldList>
                            </ArrayField>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{padding: 1}}>
                            <Typography variant="h6">Status</Typography>
                        </Box>
                        <SimpleShowLayout>
                            <BooleanField source="is_active" />
                            <DateField showTime={true} source="created_at" label="Created" />
                            <DateField showTime={true} source="updated_at" label="Updated" />
                        </SimpleShowLayout>
                    </Grid>
                </Grid>
            )}
        </Show>
    );
}

export const UserCreate = () => {
    return (
        <Create redirect="show">
            <SimpleForm>
                <ReferenceArrayInput name="Institution" source="branch.institution_id" reference="institutions" perPage={1000} sort={{ field: "name", order: "ASC" }}>
                    <SelectInput optionText="name" name="InstitutionSelect" label="Institution" required={true} style={{ width : '300px' }} />
                </ReferenceArrayInput>
                <BranchInput />
                <TextInput source="name" required={true} name={"name"} style={{ width : '300px' }} />
                <TextInput source="email" required={true} name={"email"} style={{ width : '300px' }} />
                <RoleSelect formType="Create" />
            </SimpleForm>
        </Create>
    );
};

const RoleSelect = ({ formType }) => {
    const record = useRecordContext();

    let roles = [];

    const originalInstitutionId = useWatch({ name: 'InstitutionSelect'})
    let institutionId = !originalInstitutionId ? 1 : originalInstitutionId;

    const { data: newRoles, isLoading: isLoadingRoles } = useGetList('roles');

    if (formType === 'Edit' && !record) {
        return <></>;
    }

    if (newRoles) {
        newRoles.forEach(newRole => {
            if (newRole.id !== 1 || institutionId === 1) {
                roles.push(newRole);
            }
        });
    }

    const isLoading = isLoadingRoles || !originalInstitutionId;

    return (
        <SelectInput
            label="Roles"
            source="role_id"
            choices={roles}
            optionText="name"
            disabled={isLoading}
            required={true}
            style={{ width : '300px' }}
        />
    );
};

export const UserEdit = () => {
    const controller = useEditController();
    /** @var controller.record.role_id **/
    const role_id = controller?.record?.role_id ?? 1;
    const permissionsReference = 'permissions/' + role_id
    const formatPermissions = (value) => {
        return value.map(value => value.id)
    }
    return (
        <Edit title={<PageTitle />} mutationMode="pessimistic" actions={<EditActions />} redirect="show">
            <SimpleForm>
                <TextInput source="name" required={true} name={"name"} style={{ width : '300px' }} />
                <TextInput source="email" required={true} name={"email"} style={{ width : '300px' }} />
                <RoleSelect formType="Edit" />
                <ReferenceArrayInput name="Institution" source="branch.institution_id" reference="institutions" perPage={1000} sort={{ field: "name", order: "ASC" }}>
                    <SelectInput optionText="name" disabled={true} label="Institution cannot be changed" style={{ width : '300px' }} />
                </ReferenceArrayInput>
                <ReferenceArrayInput name="Branch" source="branch_id" reference="branches" perPage={1000} sort={{ field: "name", order: "ASC" }}>
                    <SelectInput optionText="name" disabled={true} label="Branch cannot be changed" style={{ width : '300px' }} />
                </ReferenceArrayInput>
                <ReferenceArrayInput name="Permissions" source="permissions" reference={permissionsReference} perPage={1000} sort={{ field: "name", order: "ASC" }} >
                    <AutocompleteArrayInput
                        name="permissions"
                        optionText="name"
                        format={formatPermissions}
                        parse={value => value.map(value => {return { id: value}})}
                        style={{ width : '300px' }}
                    />
                </ReferenceArrayInput>
                <BooleanInput source="is_active" label="Active" options={{}}/>
            </SimpleForm>
        </Edit>
    );
};

const SendWelcomeEmailButton = () => {
    const notify = useNotify();
    let [color] = useState("#1976d2");
    const record = useRecordContext();
    const port = window.location.port;
    const portString = port ? ':' + port : '';
    const link = window.location.protocol + '//' + window.location.hostname + portString + '/';
    const { mutate, isSuccess, isLoading, isError, error } = useMutation(
        () => dataProvider.sendWelcomeEmail('admin', record.id, link)
    );

    if (isLoading) {
        return <ClipLoader color={color}/>
    }

    if (isError) {
        return <p>ERROR: {error}</p>
    }

    if (isSuccess) {
        notify('Welcome Email sent', { type: "success"});
    }

    return (
        <Button
            style={{marginBottom: -3}}
            size="small"
            label="Send Welcome Email"
            startIcon={<ForwardToInboxIcon />}
            onClick={() => mutate()}
            disabled={isLoading}
        >Send Welcome Email</Button>
    );
}

const ShowActions = () => (
    <TopToolbar backButton={true}>
        <SendWelcomeEmailButton />
        <EditButton />
    </TopToolbar>
);

const EditActions = () => (
    <TopToolbar>
        <ShowButton />
    </TopToolbar>
);