import {useNotify, useRecordContext, useRefresh} from "react-admin";
import React from "react";
import {useMutation} from "react-query";
import dataProvider from "../../../../Providers/dataProvider";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import LoadingButton from "@mui/lab/LoadingButton";

export const MarkAsButton = ({ status, disabled }) => {
    const refresh = useRefresh();
    const record = useRecordContext();
    const invoiceId = record?.id;
    let invoiceStatusId = 0;
    switch (status) {
        case 'New':
            invoiceStatusId = 1;
            break;
        case 'Sent':
            invoiceStatusId = 2;
            break;
        case 'Paid':
            invoiceStatusId = 3;
            break;
        default:
            invoiceStatusId = 1;
            break;
    }
    const notify = useNotify();
    const { mutate, isSuccess, isLoading, isError } = useMutation(
        () => dataProvider.setInvoiceStatus(invoiceId, invoiceStatusId)
    );

    if (!record) {
        return '';
    }

    const handleFormSubmit = () => {
        mutate();
    }

    if (isError) {
        notify('Could not populate invoice', { type: "error"});
    }

    if (isSuccess) {
        refresh();
    }

    const label = 'Mark As ' + status;

    return (

        <LoadingButton
            loading={isLoading}
            type="submit"
            textalign="center"
            onClick={handleFormSubmit}
            startIcon={<ChecklistRtlIcon />}
            size="small"
            sx={{lineHeight: 1.5}}
            disabled={disabled}
        >
            {label}
        </LoadingButton>
    );
};