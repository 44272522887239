import {
    Show,
    List,
    SearchInput,
    useRecordContext,
    FunctionField,
    SimpleList,
    Create,
    SimpleForm,
    EmailField,
    ArrayField,
    useShowController,
    SelectColumnsButton,
    CreateButton,
    useGetIdentity,
} from "react-admin";
import { SimpleShowLayout, TextField, DateField } from "react-admin";
import React, {useEffect, useState} from "react";
import {Box, Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {IvesRequestStatusFilterSelectInput} from "../../../Components/Filters/IvesRequestStatusFilterSelectInput";
import {IvesRequestFormFilterSelectInput} from "../../../Components/Filters/IvesRequestFormFilterSelectInput";
import {formatSocialSecurityNumber, parseSocialSecurityNumber, renderPartialSsnFromRecord, renderSsnFromRecord} from "../../../Components/Functions/SocialSecurityNumber";
import {EmptyList} from "../../../Components/EmpyList";
import {renderIvesClientPhoneNumber} from "../../../Components/Functions/PhoneNumber";
import {renderIvesClientAddress, renderTaxpayerCurrentAddress, renderTaxpayerPreviousAddress} from "../../../Components/Functions/Address";
import {RequestCreateToolbar} from "./Components/RequestCreateToolbar";
import {RequestCreateIvesClientInput} from "./Components/RequestCreateIvesClientInput";
import {RequestCreateTaxpayerInput} from "./Components/RequestCreateTaxpayerInput";
import {RequestCreateTaxpayerNotificationInput} from "./Components/RequestCreateTaxpayerNotificationInput";
import {RequestCreateRequestInput} from "./Components/RequestCreateRequestInput";
import {SendTaxpayerEmailNotificationButton} from "./Components/SendTaxpayerEmailNotificationButton";
import {RequestStatusField} from "./Components/RequestStatusField";
import {renderYearsFromRecord} from "../../../Components/Functions/Years";
import {renderFormFromRecord} from "../../../Components/Functions/Form";
import {renderTaxpayerNameFromRecord} from "../../../Components/Functions/TaxpayerName";
import {PageTitle} from "../../../Components/PageTitle";
import {RetryFailedButton} from "./Components/RetryFailedButton";
import {usePermissions} from "ra-core";
import {IvesRequestUserFilterSelectInput} from "../../../Components/Filters/IvesRequestUserFilterSelectInput";
import {IvesRequestBranchFilterSelectInput} from "../../../Components/Filters/IvesRequestBranchFilterSelectInput";
import {Accordion, AccordionSummary, AccordionDetails} from '../../../Components/Accordion.js';
import {CustomTab, CustomTabs, CustomTabPanel, CustomTabProps} from '../../../Components/Tab.js';
import {FormattedResultsFormSwitches} from "./Components/FormattedResultsFormSwitches";
import {Datagrid} from "../../../Components/Datagrid";
import {DatagridConfigurable} from "../../../Components/DatagridConfigurable";
import {TrackingNumberStringForIndustry} from "../../../Components/Functions/Labels";
import {TopToolbar} from "../../../Components/TopToolbar";
import {Item} from "../../../Components/Items";

const PERMISSION_BRANCH_ADMINISTRATOR = 'branch-administrator';
const PERMISSION_INSTITUTION_ADMINISTRATOR = 'institution-administrator';

/** @var record.taxpayer_ssn **/
/** @var record.taxpayer_street_name **/
/** @var record.taxpayer_city_name **/
/** @var record.taxpayer_state_abbreviation **/
/** @var record.taxpayer_zip **/
/** @var record.taxpayer_previous_street_name **/
/** @var record.taxpayer_previous_city_name **/
/** @var record.taxpayer_previous_state_abbreviation **/
/** @var record.taxpayer_previous_zip **/
/** @var record.ives_request_status **/
/** @var record.ives_request_years **/
/** @var record.ives_client **/
/** @var record.ives_client.street_name **/
/** @var record.ives_client.city_name **/
/** @var record.ives_client.state_abbreviation **/
/** @var record.ives_client.zip **/
/** @var record.ives_requests **/
/** @var ives_request.ives_request_status **/
/** @var ives_request.ives_request_status.name **/
/** @var ives_request.ives_request_results_formatted **/

const ListActions = ({ isSmall }) => (
    <TopToolbar>
        {(isSmall) ? (<></>) : (<SelectColumnsButton />)}
        <CreateButton />
    </TopToolbar>
);

export const UserIvesRequestList = () => {
    const { permissions } = usePermissions();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    function hasPermission(permissionToFind){
        const result = permissions.find((permission) => permission === permissionToFind);

        return result !== undefined;
    }

    return (
        <List
            filters={ListFilters()}
            actions={<ListActions isSmall={isSmall} />}
            title={<PageTitle label="IVES Requests" />}
            resource="user/ives-requests"
            sort={{ field: 'created_at', order: 'DESC' }}
            empty={<EmptyList resourceLabel="IVES Requests" createButton={true} />}
            queryOptions={{refetchOnWindowFocus: true}}
        >
            {isSmall ? (
                <SimpleList
                    hasBulkActions={false}
                    linkType="show"
                    primaryText={<SimpleListLabel />}
                    secondaryText={renderTaxpayerNameFromRecord}
                    // tertiaryText={record => record.ives_request_status.name}
                />
            ) : (
                <DatagridConfigurable bulkActionButtons={false} rowClick="show" id='ives-request-table'>
                    <TextField source="id" label="ID" />
                    <FunctionField
                        source="tracking_number"
                        label={TrackingNumberStringForIndustry()}
                        sortable={false}
                        render={(record) => {
                            if (!("tracking_number" in record) || !record.tracking_number) {
                                return "";
                            }

                            return <span>{record.tracking_number.substring(0,20)}{record.tracking_number.length > 20 ? "..." : ""}</span>;
                        }}
                    />
                    { (hasPermission(PERMISSION_INSTITUTION_ADMINISTRATOR)) ? (
                        <TextField source="user.branch.name" label="Branch" sortable={false} />
                    ) : (<></>)}
                    { (hasPermission(PERMISSION_INSTITUTION_ADMINISTRATOR) || hasPermission(PERMISSION_BRANCH_ADMINISTRATOR)) ? (
                        <TextField source="user.name" label="User" sortable={false} />
                    ) : (<></>)}
                    <TextField source="ives_client.name" label="Client" sortable={false} />
                    <FunctionField render={renderTaxpayerNameFromRecord} label="Taxpayer" sortable={false} />
                    <FunctionField render={renderPartialSsnFromRecord} label="SSN" sortable={false} />
                    <TextField source="ives_request_type.name" label="Type" sortable={false} />
                    <FunctionField render={renderFormFromRecord} label="Form" sortable={false} />
                    <FunctionField render={renderYearsFromRecord} label="Years" sortable={false} />
                    <TextField source="ives_request_status" label="Status" sortable={false} />
                    <DateField showTime={true} source="created_at" label="Created" />
                </DatagridConfigurable>
            )}
        </List>
    );
};

const SimpleListLabel = () => {
    const record = useRecordContext();

    if (!record) {
        return '';
    }

    return (
        <Typography key={Math.random()} variant="body2">
            {record.taxpayer_ssn + " - "}
            {
                record.ives_request_years.map(
                    t => <span key={Math.random()}>{t.year}</span>
                ).reduce(
                    (prev, curr) => [prev, ', ', curr]
                )
            }
        </Typography>
    );
};

const ListFilters = () => {
    const { permissions } = usePermissions();
    function hasPermission(permissionToFind){
        const result = permissions.find((permission) => permission === permissionToFind);

        return result !== undefined;
    }

    const listFilters = [
        <SearchInput
            placeholder="SSN"
            source="taxpayer_ssn"
            name="taxpayer_ssn"
            alwaysOn={true}
            format={formatSocialSecurityNumber}
            parse={parseSocialSecurityNumber}
            sx={{ width: 165 }}
            key={Math.random()}
        />,
        <SearchInput
            placeholder={TrackingNumberStringForIndustry()}
            source="tracking_number"
            name="tracking_number"
            alwaysOn={true}
            sx={{ width: 165 }}
            key={Math.random()}
        />,
        <IvesRequestFormFilterSelectInput label="FORM" alwaysOn={true} key={Math.random()} />,
        <IvesRequestStatusFilterSelectInput label="STATUS" alwaysOn={true} key={Math.random()} />,
    ];

    if (hasPermission(PERMISSION_INSTITUTION_ADMINISTRATOR)) {
        listFilters.push(<IvesRequestBranchFilterSelectInput label="BRANCH" alwaysOn={true} key={Math.random()} />);
    }

    if (hasPermission(PERMISSION_INSTITUTION_ADMINISTRATOR) || hasPermission(PERMISSION_BRANCH_ADMINISTRATOR)) {
        listFilters.push(<IvesRequestUserFilterSelectInput label="USER" alwaysOn={true} key={Math.random()} />);
    }

    return listFilters;
}

export const UserIvesRequestShow = () => {
    const record = useShowController()?.record;
    const { isFetching } = useShowController();
    const { permissions } = usePermissions();
    function hasPermission(permissionToFind){
        const result = permissions.find((permission) => permission === permissionToFind);

        return result !== undefined;
    }

    const ShowWrapper = ({ children }) => (
        <Box sx={{ width: "100%" }}>
            {children}
        </Box>
    );

    const showResults = !(isFetching || !record || record.ives_request_status !== 'Complete' || !record.ives_request_results_formatted);

    return (
        <Show title={<ShowTitle />} actions={<ShowActions />} resource="user/ives-requests" component={ShowWrapper} >
            <Accordion defaultExpanded>
                <AccordionSummary >
                    <Typography>Request</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1} direction="row" justifyContent="space-evenly" alignItems="stretch" sx={{padding: 1}}>
                        <Grid item xl={3} lg={6} xs={12}>
                            <Item>
                                <Box sx={{padding: 1}}>
                                    <Typography variant="h6">Client</Typography>
                                </Box>
                                <SimpleShowLayout>
                                    <TextField source="ives_client.name" label="Name" />
                                    <FunctionField render={renderIvesClientPhoneNumber} label="Phone" />
                                    <FunctionField render={renderIvesClientAddress} label="Address" component="pre" />
                                </SimpleShowLayout>
                            </Item>
                        </Grid>
                        <Grid item xl={3} lg={6} xs={12}>
                            <Item>
                                <Box sx={{padding: 1}}>
                                    <Typography variant="h6">Taxpayer/Applicant</Typography>
                                </Box>
                                <SimpleShowLayout>
                                    <FunctionField render={renderSsnFromRecord} label="SSN" />
                                    <FunctionField render={renderTaxpayerNameFromRecord} label="Taxpayer" />
                                    <FunctionField render={renderTaxpayerCurrentAddress} label="Address" component="pre" />
                                    {(record?.taxpayer_previous_street_name) ? (
                                        <FunctionField render={renderTaxpayerPreviousAddress} label="Additional Address" component="pre" />
                                    ) : (<></>)}
                                </SimpleShowLayout>
                            </Item>
                        </Grid>
                        <Grid item xl={3} lg={6} xs={12}>
                            <Item>
                                <Box sx={{padding: 1}}>
                                    <Typography variant="h6">E-Mail Notifications</Typography>
                                </Box>
                                <SimpleShowLayout>
                                    <EmailField source="taxpayer_email" label="Email" />
                                    <Box sx={{maxHeight: 300, overflowY: 'auto'}}>
                                        <ArrayField source="taxpayer_email_notifications" label={false} >
                                            <Datagrid bulkActionButtons={false} empty={<Typography variant="body2">No E-Mail Notifications Sent</Typography>}>
                                                <DateField source="created_at" showTime={true} label="Date/Time" />
                                                <TextField source="taxpayer_email_notification_status.name" label="Status" />
                                            </Datagrid>
                                        </ArrayField>
                                    </Box>
                                </SimpleShowLayout>
                            </Item>
                        </Grid>
                        <Grid item xl={3} lg={6} xs={12}>
                            <Item>
                                <Box sx={{padding: 1}}>
                                    <Typography variant="h6">Request</Typography>
                                </Box>
                                <SimpleShowLayout>
                                    { (hasPermission(PERMISSION_INSTITUTION_ADMINISTRATOR)) ? (
                                        <TextField source="user.branch.name" label="Branch" sortable={false} />
                                    ) : (<></>)}
                                    { (hasPermission(PERMISSION_INSTITUTION_ADMINISTRATOR) || hasPermission(PERMISSION_BRANCH_ADMINISTRATOR)) ? (
                                        <TextField source="user.name" label="User" sortable={false} />
                                    ) : (<></>)}
                                    <TextField source="ives_request_channel" label="Channel" />
                                    <TextField source="ives_request_type.name" label="Type" />
                                    <TextField source="ives_request_form.name" label="Form" />
                                    <TextField source="tracking_number" label={TrackingNumberStringForIndustry()} />
                                    <FunctionField render={renderYearsFromRecord} label="Years" />
                                    <RequestStatusField />
                                    <DateField showTime={true} source="created_at" label="Created" />
                                </SimpleShowLayout>
                            </Item>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion disabled={!showResults} defaultExpanded={showResults}>
                <AccordionSummary >
                    <Typography>Results</Typography>
                </AccordionSummary>
                {
                    (showResults) ? (
                        <AccordionDetails>
                            <IvesResults />
                        </AccordionDetails>
                    ) : (<></>)
                }
            </Accordion>
        </Show>
    )
};

const ShowActions = () => {
    const record = useRecordContext();
    const { permissions } = usePermissions();
    const { identity } = useGetIdentity();
    function hasPermission(permissionToFind){
        const result = permissions.find((permission) => permission === permissionToFind);

        return result !== undefined;
    }

    const shouldShowButtons =
        (!hasPermission(PERMISSION_INSTITUTION_ADMINISTRATOR) && !hasPermission(PERMISSION_BRANCH_ADMINISTRATOR)) ||
        record?.user?.id === identity?.id;

    return (
        <TopToolbar backButton={true}>
            {
                (shouldShowButtons) ? (
                    <>
                        <RetryFailedButton role="user" />
                        <SendTaxpayerEmailNotificationButton role="user" />
                    </>
                ) : (<></>)
            }
        </TopToolbar>
    );
}

const ShowTitle = () => {
    const record = useRecordContext();

    if (!record) {
        return '';
    }

    return 'IVES Request #' + record.id;
}

const IvesResults = () => {
    const record = useRecordContext();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const pdfFilename = 'result.pdf';
    const pdfString = record.ives_request_results_merged_pdf;

    const htmlFilename = 'result.html';
    const htmlString = btoa(record.ives_request_results_merged_html);

    return (
        <Box sx={{ width: '100%', backgroundColor: 'background.paper' }}>
            <CustomTabs value={value} onChange={handleChange} >
                <CustomTab label="Report" {...CustomTabProps(0)} />
                <CustomTab label="Downloads" {...CustomTabProps(1)} />
                <CustomTab label="Interactive (Beta)" {...CustomTabProps(2)} />
            </CustomTabs>
            <Box>
                <CustomTabPanel value={value} index={0} >
                    <MergedIvesResultView />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <a download={pdfFilename} href={`data:application/pdf;base64,${pdfString}`} ><Button variant="outlined">Download PDF</Button></a><br/><br/>
                    <a download={htmlFilename} href={`data:text/plain;base64,${htmlString}`} ><Button variant="outlined">Download HTML</Button></a><br/><br/>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2} >
                    <Typography variant="body2">Currently only available for Wage and Income results<br /><br /></Typography>
                    <FormattedIvesResultView />
                </CustomTabPanel>
            </Box>
        </Box>
    )
}

const MergedIvesResultView = () => {
    const record = useRecordContext();

    if (!record) {
        return '';
    }

    const {ives_request_results_merged_pdf} = record;

    if (!ives_request_results_merged_pdf) {
        return '';
    }

    return (
        <Item>
            <embed
                key={Math.random()}
                style={{ height: 500, overflow: 'scroll' }}
                src={`data:application/pdf;base64,${ives_request_results_merged_pdf}`}
                type="application/pdf" width="100%"
            ></embed>
        </Item>
    );
}

const FormattedIvesResultView = () => {
    const record = useRecordContext();
    const [allForms, setAllForms] = React.useState([]);
    const [selectedForms, setSelectedForms] = React.useState('');

    if (
        !record ||
        record.ives_request_status !== 'Complete' ||
        !record.ives_request_results_formatted ||
        record.ives_request_results_formatted.data.length === 0
    ) {
        return (
            <Typography variant="body2" color="textSecondary">Interactive results not available</Typography>
        );
    }

    const {ives_request_results_formatted} = record;

    let resultsArray = [];

    for (let key in ives_request_results_formatted.data) {
        resultsArray[key] = ives_request_results_formatted.data[key];
    }

    if (allForms.length === 0) {
        setAllForms(ives_request_results_formatted.forms.map((form) => { return form; }));
        let tempSelectedForms = {};
        ives_request_results_formatted.forms.forEach((form) => { tempSelectedForms[form] = true; })
        setSelectedForms(tempSelectedForms);
    }

    return (
        <Item key="formatted-ives-results-view">
            <Grid container padding={1}>
                <Grid item sm={12} md={3} lg={2}>
                    <FormattedResultsFormSwitches allForms={allForms} selectedForms={selectedForms} setSelectedForms={setSelectedForms} />
                </Grid>
                <Grid item sm={12} md={9} lg={10}>
                    {
                        resultsArray.map((content, year) => {
                            const ariaControls= "panel" + year + "-content";
                            const id = "panel" + year + "-header";
                            return (
                                <>
                                    <Accordion>
                                        <AccordionSummary
                                            aria-controls={ariaControls}
                                            id={id}
                                            sx={{flexDirection: 'row-reverse'}}
                                        >
                                            <Typography>{year}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails key={'formatted-result-year-' + {year}}>
                                            <Typography>
                                                {
                                                    content.map(form => {
                                                        if (!selectedForms[form.form]) {
                                                            return '';
                                                        }
                                                        const subAriaControls= "panel" + form.title + "-content";
                                                        const subId = "panel" + form.title + "-header";
                                                        return (
                                                            <>
                                                                <Accordion>
                                                                    <AccordionSummary
                                                                        aria-controls={subAriaControls}
                                                                        id={subId}
                                                                        sx={{flexDirection: 'row-reverse'}}
                                                                    >
                                                                        <Typography>{form.title}</Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <Typography>
                                                                            <div dangerouslySetInnerHTML={{__html: form.content}}/>
                                                                        </Typography>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </>
                            )
                        })
                    }
                </Grid>
            </Grid>
        </Item>
    );
}

export const UserIvesRequestCreate = () => {
    let [retryData] = React.useState(JSON.parse(localStorage.getItem('retryFailedRecord')));
    const [confirm, setConfirm] = useState(false);

    useEffect(() => {
        localStorage.removeItem('retryFailedRecord');
    }, []);

    return (
        <Create title={<PageTitle label="Create IVES Request"/>}>
            <SimpleForm toolbar={<RequestCreateToolbar confirm={confirm} setConfirm={setConfirm} />} mode="onBlur" reValidateMode="onBlur" onSubmit={() => {setConfirm(true);}} >
                <Grid container columnSpacing={{xs: 1, sm: 2, md: 3}} lineHeight="1.0" direction="row" justifyContent="flex-start" alignItems="stretch" >
                    <RequestCreateIvesClientInput retryData={retryData} />
                    <RequestCreateTaxpayerInput retryData={retryData} />
                    <RequestCreateTaxpayerNotificationInput retryData={retryData} />
                    <RequestCreateRequestInput retryData={retryData} />
                </Grid>
            </SimpleForm>
        </Create>
    );
};