import {
    Show,
    List,
    SearchInput,
    useRecordContext,
    FunctionField,
    Link,
    EmailField,
    ArrayField,
    useShowController,
    SelectColumnsButton,
    BooleanInput,
} from "react-admin";
import { SimpleShowLayout, TextField, DateField, ReferenceField, SimpleList} from "react-admin";
import {ExpandedIvesRequestFormFilterSelectInput} from "../../../Components/Filters/ExpandedIvesRequestFormFilterSelectInput";
import {ExpandedIvesRequestStatusFilterSelectInput} from "../../../Components/Filters/ExpandedIvesRequestStatusFilterSelectInput";
import React from "react";
import {Box, Grid, Typography, useMediaQuery} from "@mui/material";
import {InstitutionFilterSelectInput} from "../../../Components/Filters/InstitutionFilterSelectInput";
import {formatSocialSecurityNumber, parseSocialSecurityNumber, renderPartialSsnFromRecord, renderSsnFromRecord} from "../../../Components/Functions/SocialSecurityNumber";
import {renderIvesClientPhoneNumber} from "../../../Components/Functions/PhoneNumber";
import {renderIvesClientAddress, renderTaxpayerCurrentAddress, renderTaxpayerPreviousAddress} from "../../../Components/Functions/Address";
import {SendTaxpayerEmailNotificationButton} from "../../User/IVES/Components/SendTaxpayerEmailNotificationButton";
import {WithdrawButton} from "../../User/IVES/Components/WithdrawButton";
import {renderYearsFromRecord} from "../../../Components/Functions/Years";
import {renderFormFromRecord} from "../../../Components/Functions/Form";
import {renderTaxpayerNameFromRecord} from "../../../Components/Functions/TaxpayerName";
import {PageTitle} from "../../../Components/PageTitle";
import {Datagrid} from "../../../Components/Datagrid";
import {DatagridConfigurable} from "../../../Components/DatagridConfigurable";
import {TrackingNumberStringForIndustry} from "../../../Components/Functions/Labels";
import {TopToolbar} from "../../../Components/TopToolbar";
import {Item} from "../../../Components/Items";

/** @var record.taxpayer_ssn **/
/** @var record.taxpayer_city_name **/
/** @var record.taxpayer_state_abbreviation **/
/** @var record.taxpayer_zip **/
/** @var record.expanded_ives_request_status **/
/** @var record.expanded_ives_request_years **/
/** @var record.ives_client **/
/** @var record.ives_client.city_name **/
/** @var record.ives_client.state_abbreviation **/
/** @var record.ives_client.zip **/
/** @var record.ives_requests **/
/** @var record.expanded_ives_request_form **/
/** @var record.expanded_ives_request_form.name **/
/** @var record.expanded_ives_request_status **/
/** @var record.expanded_ives_request_status.name **/
/** @var ives_request.ives_request_status **/
/** @var ives_request.ives_request_status.name **/

const ListActions = ({ isSmall }) => {
    if (isSmall) {
        return false;
    }

    return (
        <TopToolbar>
            <SelectColumnsButton />
        </TopToolbar>
    );
}

export const ExpandedIvesRequestList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List
            filters={postFilters}
            actions={<ListActions isSmall={isSmall} />}
            title={<PageTitle label="Expanded IVES Requests" />}
            resource="expanded-ives-requests"
            sort={{ field: 'updated_at', order: 'DESC' }}
            queryOptions={{refetchOnWindowFocus: true}}
        >
            {isSmall ? (
                <SimpleList
                    hasBulkActions={false}
                    linkType="show"
                    primaryText={<SimpleListLabel />}
                    secondaryText={record => record.user.branch.institution.name}
                    tertiaryText={record => record.updated_at}
                />
            ) : (
                <DatagridConfigurable bulkActionButtons={false} rowClick="show">
                    <TextField source="id" label="ID" />
                    <TextField source="user.branch.institution.name" label="Institution" sortable={false} />
                    <TextField source="user.branch.name" label="Branch" sortable={false} />
                    <TextField source="user.name" label="User" sortable={false} />
                    <FunctionField render={renderPartialSsnFromRecord} label="SSN" />
                    <TextField source="expanded_ives_request_type.name" label="Type" sortable={false} />
                    <FunctionField render={renderFormFromRecord} label="Form" sortable={false} />
                    <FunctionField render={renderYearsFromRecord} label="Years" />
                    <TextField source="expanded_ives_request_status.name" label="Status" sortable={false} />
                    <DateField showTime={true} source="created_at" />
                    <DateField showTime={true} source="updated_at" />
                </DatagridConfigurable>
            )}
        </List>
    );
};

const SimpleListLabel = () => {
    const record = useRecordContext();

    if (!record) {
        return '';
    }

    const lastFour = record.taxpayer_ssn.substring(5, 9);
    const form = record.expanded_ives_request_form.name === "ALL WAGE AND INCOME (ALL W2 and ALL 1099)" ? "ALL" : record.expanded_ives_request_form.name;
    const status = record.expanded_ives_request_status.name;

    return (
        <Typography variant="body2">
            {lastFour + " - " + form + " - "}
            {
                record.expanded_ives_request_years.map(
                    t => <span key={Math.random()}>{t.year}</span>
                ).reduce(
                    (prev, curr) => [prev, ', ', curr]
                )
            }
            {" - " + status}
        </Typography>
    );
};

const postFilters = [
    <SearchInput placeholder="SSN" source="taxpayer_ssn" name="taxpayer_ssn" alwaysOn={true} format={formatSocialSecurityNumber} parse={parseSocialSecurityNumber} key={Math.random()} />,
    <ExpandedIvesRequestFormFilterSelectInput label="Form" alwaysOn={true} key={Math.random()} />,
    <ExpandedIvesRequestStatusFilterSelectInput label="Status" alwaysOn={true} key={Math.random()} />,
    <InstitutionFilterSelectInput label="Institution" alwaysOn={true} key={Math.random()} />,
    <BooleanInput label="Hide Failed" alwaysOn={true} source="should_hide_failed" name="should_hide_failed" sx={{ padding: "12px" }} />
];

export const ExpandedIvesRequestShow = () => {
    const record = useShowController()?.record;

    return (
        <Show title={<ShowTitle/>} actions={<ShowActions/>}>
            <Grid container spacing={1} direction="row" justifyContent="space-evenly" alignItems="stretch" sx={{padding: 1}}>
                <Grid item xl={3} lg={6} xs={12}>
                    <Item>
                        <Box sx={{padding: 1}}>
                            <Typography variant="h6">Hierarchy</Typography>
                        </Box>
                        <SimpleShowLayout>
                            <ReferenceField source="user.branch.institution_id" reference="institutions" label="Institution" link="show">
                                <TextField source="name"/>
                            </ReferenceField>
                            <ReferenceField source="user.branch_id" reference="branches" label="Branch" link="show">
                                <TextField source="name"/>
                            </ReferenceField>
                            <ReferenceField source="user.id" reference="users" label="User" link="show">
                                <TextField source="name"/>
                            </ReferenceField>
                            <IvesRequestReferenceFields label="IVES Requests"/>
                        </SimpleShowLayout>
                    </Item>
                </Grid>
                <Grid item xl={3} lg={6} xs={12}>
                    <Item>
                        <Box sx={{padding: 1}}>
                            <Typography variant="h6">Client</Typography>
                        </Box>
                        <SimpleShowLayout>
                            <TextField source="ives_client.name" label="Name"/>
                            <FunctionField render={renderIvesClientPhoneNumber} label="Phone" />
                            <FunctionField render={renderIvesClientAddress} label="Address" component="pre" />
                        </SimpleShowLayout>
                    </Item>
                </Grid>
                <Grid item xl={3} lg={6} xs={12}>
                    <Item>
                        <Box sx={{padding: 1}}>
                            <Typography variant="h6">Request</Typography>
                        </Box>
                        <SimpleShowLayout>
                            <FunctionField render={renderSsnFromRecord} label="SSN" />
                            <FunctionField render={renderTaxpayerNameFromRecord} label="Taxpayer" />
                            <FunctionField render={renderTaxpayerCurrentAddress} label="Address" component="pre" />
                            {(record?.taxpayer_previous_street_name) ? (
                                <FunctionField render={renderTaxpayerPreviousAddress} label="Additional Address" component="pre" />
                            ) : (<></>)}
                        </SimpleShowLayout>
                    </Item>
                </Grid>
                <Grid item xl={3} lg={6} xs={12}>
                    <Item>
                        <Box sx={{padding: 1}}>
                            <Typography variant="h6">Status</Typography>
                        </Box>
                        <SimpleShowLayout>
                            <TextField source="expanded_ives_request_type.name" label="Type" />
                            <FunctionField render={renderFormFromRecord} label="Form" />
                            <FunctionField render={renderYearsFromRecord} label="Years" />
                            <TextField source="expanded_ives_request_status.name" label="Status"/>
                            <TextField source="tracking_number" label={TrackingNumberStringForIndustry()} />
                            <DateField showTime={true} source="created_at"/>
                            <DateField showTime={true} source="updated_at"/>
                        </SimpleShowLayout>
                    </Item>
                    <Item sx={{ marginTop: 1 }}>
                        <Box sx={{padding: 1}}>
                            <Typography variant="h6">E-Mail Notifications</Typography>
                        </Box>
                        <SimpleShowLayout>
                            <EmailField source="taxpayer_email" label="Email" />
                            <ArrayField source="taxpayer_email_notifications" label={false} >
                                <Datagrid bulkActionButtons={false} empty={<Typography variant="body2">No E-Mail Notifications Sent</Typography>}>
                                    <DateField source="created_at" showTime={true} label="When" />
                                    <TextField source="taxpayer_email_notification_status.name" label="Status" />
                                </Datagrid>
                            </ArrayField>
                        </SimpleShowLayout>
                    </Item>
                </Grid>
            </Grid>
        </Show>
    );
};

const IvesRequestReferenceFields = () => {
    const record = useRecordContext();

    if (record ? record.ives_requests : undefined) {
        const referenceFields = record.ives_requests.map(ives_request => {
            const link = "/ives-requests/" + ives_request.id + "/show";
            return (
                <Link variant="body2" key={Math.random()} to={link} >
                    {ives_request.id} - {ives_request.ives_request_status.name}
                </Link>
            )
        });

        return (
            <Grid container direction="column" >
                {referenceFields}
            </Grid>
        );
    } else {
        return (<Typography variant="body2">None</Typography>);
    }
}

const ShowTitle = () => {
    const record = useRecordContext();

    if (!record) {
        return '';
    }

    return 'Expanded IVES Request #' + record.id;
}

const ShowActions = () => (
    <TopToolbar backButton={true}>
        <WithdrawButton role="admin" type="expandedIvesRequest" />
        <SendTaxpayerEmailNotificationButton role="admin" type="expandedIvesRequest" />
    </TopToolbar>
);