import Autocomplete from "@mui/material/Autocomplete";
import {required, useRecordContext} from "react-admin";
import {Box, Grid, TextField, Typography} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useFormContext} from "react-hook-form";
import {debounce} from "@mui/material/utils";
import parse from 'autosuggest-highlight/parse';
import { geocodeByPlaceId } from 'react-places-autocomplete';

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };

export const AutocompleteStreetNameInput = (
    {
        streetNameInputLabel,
        streetNameInputName,
        cityNameInputName,
        stateAbbreviationInputName,
        zipInputName,
        sx,
        value}
) => {
    const record = useRecordContext();
    const [inputValue, setInputValue] = useState('');
    const loaded = useRef(false);
    const { setValue: setFormValue, resetField } = useFormContext();

    let defaultSelectedAddress = null;
    let defaultOptions = [];

    if (value) {
        defaultSelectedAddress = { structured_formatting: { main_text: value } };
        defaultOptions.push(defaultSelectedAddress);
    } else if (record) {
        /** @var record.street_name **/
        defaultSelectedAddress = { structured_formatting: { main_text: record.street_name } };
        defaultOptions.push(defaultSelectedAddress);
    }
    const [selectedAddress, setSelectedAddress] = useState(defaultSelectedAddress);
    const [options, setOptions] = useState(defaultOptions);

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&loading=async&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const fetch = useMemo(
        () =>
            debounce((request, callback) => {
                /** @var autocompleteService.current.getPlacePredictions **/
                request.componentRestrictions = {country: 'us'};
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 400),
        [],
    );

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }

        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(selectedAddress ? [selectedAddress] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [{ key: 'manualInput', value: inputValue }];

                if (selectedAddress) {
                    newOptions = [...newOptions, selectedAddress];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [selectedAddress, inputValue, fetch]);

    useEffect(() => {
        async function loadFormElements() {
            if (selectedAddress.place_id) {
                const places = await geocodeByPlaceId(selectedAddress.place_id);
                if (places.length) {
                    /** @var selectedAddress.structured_formatting **/
                    /** @var selectedAddress.structured_formatting.main_text **/

                    const streetName = selectedAddress.structured_formatting.main_text;
                    const cityNameContainer = places[0].address_components.find(c => c.types.includes('locality'));
                    const cityName = cityNameContainer ? cityNameContainer.long_name : null;
                    const stateAbbreviationContainer = places[0].address_components.find(c => c.types.includes('administrative_area_level_1'));
                    const stateAbbreviation = stateAbbreviationContainer ? stateAbbreviationContainer.short_name : null;
                    const zipContainer = places[0].address_components.find(c => c.types.includes('postal_code'));
                    const zip = zipContainer ? zipContainer.short_name : null;

                    setFormValue(streetNameInputName, streetName, { shouldDirty: true });
                    setFormValue(cityNameInputName, cityName, { shouldDirty: true });
                    if (stateAbbreviation) {
                        setFormValue(stateAbbreviationInputName, stateAbbreviation, { shouldDirty: true });
                    }
                    setFormValue(zipInputName, zip, { shouldDirty: true });
                }
            }
        }

        if (selectedAddress) {
            if (selectedAddress.key === 'manualInput') {
                setFormValue(streetNameInputName, selectedAddress.value, { shouldDirty: true });
            } else {
                loadFormElements().then();
            }
        } else {
            setFormValue(streetNameInputName, "", { shouldDirty: false });
            resetField(streetNameInputName);
            resetField(cityNameInputName);
            resetField(stateAbbreviationInputName);
            resetField(zipInputName);
        }
    }, [cityNameInputName, resetField, selectedAddress, setFormValue, stateAbbreviationInputName, streetNameInputName, zipInputName]);

    const updateAddress = (event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setSelectedAddress(newValue);
    }

    function onAutoFocus (event) {
        if (event.target.autocomplete) {
            event.target.autocomplete = "whatever";
        }
    }

    return (
        <Autocomplete
            source={streetNameInputName}
            name={streetNameInputName}
            id={streetNameInputName}
            sx={sx}
            getOptionLabel={
                (option) => {
                    if (option.key === 'manualInput') {
                        return option.value;
                    }

                    return typeof option === 'string' ? option : option.structured_formatting.main_text
                }
            }
            popupIcon={<></>}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={selectedAddress}
            validate={[required()]}
            noOptionsText="Start typing address to see suggestions"
            onChange={(event, newValue) => {
                updateAddress(event, newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    source={streetNameInputName}
                    name={streetNameInputName}
                    id={streetNameInputName}
                    label={streetNameInputLabel}
                    onFocus={onAutoFocus}
                    sx={{sx}}
                    // validate={validate}
                    validate={[required()]}
                    value={value}
                    {...params}
                />
            )}
            renderOption={(props, option) => {
                /** @var option.structured_formatting.main_text_matched_substrings **/
                /** @var option.structured_formatting.secondary_text **/

                const { key, ...optionProps } = props;
                if (option.key === 'manualInput') {
                    return (
                        <li key={Math.random()} {...optionProps}>
                            <Grid container sx={{ alignItems: 'center' }}>
                                <Grid item sx={{ display: 'flex', width: 44 }}>
                                    <LocationOnIcon sx={{ color: 'text.secondary' }} />
                                </Grid>
                                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                    <Box
                                        key={Math.random()}
                                        component="span"
                                        sx={{ fontWeight: 'bold' }}
                                    >
                                        {option.value}
                                    </Box>
                                    <Typography variant="body2" color="text.secondary">
                                        (user entered)
                                    </Typography>
                                </Grid>
                            </Grid>
                        </li>
                    );
                }

                const matches =
                    option.structured_formatting.main_text_matched_substrings || [];

                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [match.offset, match.offset + match.length]),
                );
                return (
                    <li key={Math.random()} {...optionProps}>
                        <Grid container sx={{ alignItems: 'center' }}>
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                                <LocationOnIcon sx={{ color: 'text.secondary' }} />
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                {parts.map((part) => (
                                    <Box
                                        key={Math.random()}
                                        component="span"
                                        sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                    >
                                        {part.text}
                                    </Box>
                                ))}
                                <Typography variant="body2" color="text.secondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
};