export const renderIvesClientAddress = (record) => {
    if (!record || !record?.ives_client) return '';

    /** @var record.ives_client **/
    return record.ives_client.street_name + '\n' + record.ives_client.city_name + ', ' + record.ives_client.state_abbreviation + ' ' + record.ives_client.zip;
}

export const renderTaxpayerCurrentAddress = (record) => {
    if (!record) return '';

    /** @var record.taxpayer_street_name **/
    return record.taxpayer_street_name + '\n' + record.taxpayer_city_name + ', ' + record.taxpayer_state_abbreviation + ' ' + record.taxpayer_zip;
}

export const renderTaxpayerPreviousAddress = (record) => {
    if (!record) return '';

    /** @var record.taxpayer_previous_street_name **/
    /** @var record.taxpayer_previous_city_name **/
    /** @var record.taxpayer_previous_state_abbreviation **/
    /** @var record.taxpayer_previous_zip **/
    return record.taxpayer_previous_street_name + '\n' + record.taxpayer_previous_city_name + ', ' + record.taxpayer_previous_state_abbreviation + ' ' + record.taxpayer_previous_zip;
}