export const DatagridRowSx = (record, index) => {
    let sX = { backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white"}

    const DataGridRowTextColor = getDatagridRowTextColor(record);

    if (DataGridRowTextColor) {
        sX["& .RaDatagrid-rowCell"] = { color: DataGridRowTextColor };
    }

    return sX;
};

export function getDatagridRowTextColor(record) {
    if (!record || (!record.ives_request_status && !record.expanded_ives_request_status)) {
        return;
    }

    let status;

    if ("ives_request_status" in record) {
        status = (typeof record.ives_request_status === 'object' && "name" in record.ives_request_status)
            ? record.ives_request_status.name
            : record.ives_request_status;
    } else {
        status = (typeof record.expanded_ives_request_status === 'object' && "name" in record.expanded_ives_request_status)
            ? record.expanded_ives_request_status.name
            : record.expanded_ives_request_status;
    }

    let DataGridRowTextColor;
    if (status === 'Complete') {
        DataGridRowTextColor = 'green';
    } else if (status.includes('Failed') || status.includes('Rejected')) {
        DataGridRowTextColor = '#cc3939';
    } else if (status.includes('Pending') || status.includes('Processing')) {
        DataGridRowTextColor = '#1976d2';
    }

    return DataGridRowTextColor;
}