import * as React from 'react';
import { useState } from 'react';
import {Login, TextInput, useLogin, useNotify, useRedirect} from 'react-admin';
import { styled } from "@mui/material/styles";
import { Form, useSafeSetState } from "ra-core";
import {Box, Button, CardContent, CircularProgress, Modal, Typography} from '@mui/material';
import {useFormContext} from "react-hook-form";
import {useMutation} from "react-query";
import {ClipLoader} from "react-spinners";
import {useSearchParams} from "react-router-dom";
import dataProvider from "../Providers/dataProvider";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
};

const MyLoginPage = ({ className }) => {
    const notify = useNotify();
    const [email, setEmail] = useState(() => (localStorage.checkbox ? localStorage.username : ""));
    const [password, setPassword] = useState(() => (localStorage.checkbox ? localStorage.password : ""));
    const [isChecked, setIsChecked] = useState(() => !!localStorage.checkbox);
    const [loading, setLoading] = useSafeSetState(false);
    const login = useLogin();

    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");

    const handleSubmit = () => {
        if (isChecked && email !== "") {
            localStorage.username = email;
            localStorage.password = password;
            localStorage.checkbox = isChecked ? "1" : "";
        }

        setLoading(true);
        login({ email, password }, '/')
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                            ? 'ra.auth.sign_in_error'
                            : error.message,
                    {
                        type: 'error',
                        messageArgs: {
                            _:
                                typeof error === 'string'
                                    ? error
                                    : error && error.message
                                        ? error.message
                                        : undefined,
                        },
                    }
                );
            });
    };

    return (
        <Login>
            <Box style={{ backgroundColor: "#353b46", width: "100%", textAlign: "center", paddingTop: 15 }}>
                <Typography variant="h4">
                    <span className="logo" style={{ color: "white" }} >True</span><span className="logo" style={{ color: "#a0dc29" }} >Data</span><span className="logo" style={{ color: "#247dd4" }} >.ai</span>
                </Typography>
            </Box>
            <StyledForm
                onSubmit={handleSubmit}
                mode="onChange"
                noValidate
                className={className}
            >
                <CardContent style={{backgroundColor: "#353b46"}} className={LoginFormClasses.content}>
                    <input
                        name="email"
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        className={LoginFormClasses.input}
                        autoComplete="username"
                    />
                    <input
                        name="password"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        className={LoginFormClasses.input}
                        autoComplete="current-password"
                    />
                    <input
                        type="checkbox"
                        checked={isChecked}
                        name="lsRememberMe"
                        onChange={(e) => setIsChecked(e.target.checked)}
                        style={{ top: 20 }}
                    />
                    <label style={{ lineHeight: 2.5, color: 'white', fontSize: 14 }}> Remember me</label>
                    <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={loading}
                        fullWidth
                        className={LoginFormClasses.button}
                    >
                        {loading ? (
                            <CircularProgress
                                className={LoginFormClasses.icon}
                                size={19}
                                thickness={3}
                            />
                        ) : (
                            "Sign In"
                        )}
                    </Button>
                </CardContent>
            </StyledForm>
            <Box style={{backgroundColor: "#353b46", width: "100%", textAlign: "center"}}>
                <ForgotPasswordModal textalign="center"/>
            </Box>
            <ResetPasswordModal token={token}/>
        </Login>
    )
};

const ForgotPasswordSubmitButton = ({ handleClose }) => {
    const notify = useNotify();
    const { getValues } = useFormContext();
    const [color] = useState("#1976d2");

    const port = window.location.port;
    const portString = port ? ':' + port : '';
    const link = window.location.protocol + '//' + window.location.hostname + portString + '/';
    const email = getValues('email');

    const { mutate, isSuccess, isLoading, isError } = useMutation(
        () => dataProvider.forgotPassword(email, link)
    );

    if (isLoading) {
        return (
            <Box>
                <ClipLoader color={color} display="block"/>
            </Box>
        )
    }

    if (isError) {
        notify('Password reset failed', { type: "error"});

        handleClose();
    }

    if (isSuccess) {
        notify('Password reset link sent', { type: "success"});

        handleClose(true);
    }

    const handleFormSubmit = () => {
        let regex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regex.test(email)) {
            notify('Invalid email', { type: "error"});
            return;
        }

        mutate();
    }

    return (
        <Button
            type="submit"
            textalign='center'
            onClick={handleFormSubmit}
            variant="contained"
            color="primary"
            className={LoginFormClasses.button}
        >
            Send password reset email
        </Button>
    );
};

const ForgotPasswordModal = () => {
    const [email, setEmail] = useState('');
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    }

    return (
        <div>
            <Button onClick={handleOpen}>Forgot Password</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" textalign='center'>
                        Password Reset
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Please enter your email address below.
                    </Typography>
                    <Form name="password-reset-form">
                        <TextInput
                            source='email'
                            name='email'
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            textalign='center' sx={{width: 300}}
                            required={true}
                        />
                        <ForgotPasswordSubmitButton handleClose={handleClose} />
                    </Form>
                </Box>
            </Modal>
        </div>
    );
};

const ResetPasswordSubmitButton = ({handleClose, buttonLabel}) => {
    const redirect = useRedirect();
    const notify = useNotify();
    const { getValues } = useFormContext();
    const [color] = useState("#1976d2");

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const newPassword = getValues('newPassword');
    const verifyNewPassword = getValues('verifyNewPassword');
    const { mutate, isSuccess, isLoading } = useMutation(
        () => dataProvider.resetPassword(token, newPassword),
        {
            onError: (error) => {
                notify('Password reset failed\n'+ error, { multiLine: true, type: "error"});
            }
        }
    );

    if (isLoading) {
        return (
            <Box>
                <ClipLoader color={color} display="block"/>
            </Box>
        )
    }

    if (isSuccess) {
        handleClose();
        const port = window.location.port;
        const portString = port ? ':' + port : '';
        const link = window.location.protocol + '//' + window.location.hostname + portString;
        notify('Password reset successfully. Please log in.', { type: "success"});
        redirect(link);
    }

    const handleFormSubmit = () => {
        if (newPassword !== verifyNewPassword) {
            notify('Passwords must match', {type: "info"});
            return;
        }

        const regex = new RegExp("^(?=.*)(?=.*[a-z])(?=.*[A-Z]).{8,32}$");
        const isValid = regex.test(newPassword);
        if (!isValid) {
            notify(
                'Passwords must be at least 8 characters long,\nmust be a combination of lowercase and uppercase letters,\nand must include at least one symbol.', 
                {multiLine: true, type: "info"}
            );
            return;
        }        
        
        mutate();
    };

    return (
        <Button
            type="submit"
            textalign='center'
            onClick={handleFormSubmit}
            variant="contained"
            color="primary"
            className={LoginFormClasses.button}
        >
            {buttonLabel}
        </Button>
    );
};

const ResetPasswordModal = ({token}) => {
    const redirect = useRedirect();
    const [newPassword, setNewPassword] = useState('');
    const [verifyNewPassword, setVerifyNewPassword] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();

    const [open, setOpen] = React.useState(false);
    const [initialOpen, setInitialOpen] = React.useState(false);
    
    let modalTitle = 'Password Reset';
    let modalCTA = 'Reset Password';

    if (token) {
        if (token.includes('WELCOME')) {
            modalTitle = 'Welcome';
            modalCTA = 'Create Password';
        }
        if (!initialOpen) {
            setInitialOpen(true);
            setOpen(true);
        }
    }
    const handleClose = () => {
        if (searchParams.has('token')) {
            searchParams.delete('token');
            setSearchParams(searchParams);
        }
        setOpen(false);
        const port = window.location.port;
        const portString = port ? ':' + port : '';
        const link = window.location.protocol + '//' + window.location.hostname + portString;
        redirect(link);
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" textalign='center'>
                    {modalTitle}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Please enter your new password below.
                </Typography>
                <Form name="password-reset-form">
                    <TextInput
                        source='newPassword'
                        name='newPassword'
                        type='password'
                        value={newPassword}
                        onChange={e => setNewPassword(e.target.value)}
                        textalign='center' sx={{width: 300}}
                        required={true}
                    />
                    <TextInput
                        source='verifyNewPassword'
                        name='verifyNewPassword'
                        type='password'
                        value={verifyNewPassword}
                        onChange={e => setVerifyNewPassword(e.target.value)}
                        textalign='center' sx={{width: 300}}
                        required={true}
                    />
                    <Box>
                        <Typography variant={"body2"}>
                            Passwords must be at least 8 characters long,
                            must be a combination of lowercase and uppercase letters,
                            and must include at least one symbol.
                        </Typography>
                    </Box>
                    <ResetPasswordSubmitButton handleClose={handleClose} buttonLabel={modalCTA} />
                </Form>
            </Box>
        </Modal>
    )
}

const PREFIX = 'RaLoginForm';

export const LoginFormClasses = {
    content: `${PREFIX}-content`,
    button: `${PREFIX}-button`,
    icon: `${PREFIX}-icon`,
    input: `${PREFIX}-input`,
};

const StyledForm = styled(Form, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    [`& .${LoginFormClasses.content}`]: {
        width: 300,
        bgColor: 'blue',
    },
    [`& .${LoginFormClasses.button}`]: {
        marginTop: theme.spacing(2),
    },
    [`& .${LoginFormClasses.icon}`]: {
        margin: theme.spacing(0.3),
    },
    [`& .${LoginFormClasses.input}`]: {
        width: 268,
    },
}));

export default MyLoginPage;