import {Box, Typography} from "@mui/material";
import React from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export const CustomTabPanel = ({ children, value, index }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

export const CustomTabProps = (index) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
};

export const CustomTabs = ({ children, ...props}) => {
    return (
        <Tabs {...props} >{children}</Tabs>
    );
};

export const CustomTab = ({ children, ...props }) => {
    return (
        <Tab {...props} >{children}</Tab>
    );
};