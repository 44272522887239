export const formatSocialSecurityNumber = (value) => {
    if (!value) return '';
    const currentValue = value.replace(/\D/g, '');
    const cvLength = currentValue.length;

    if (cvLength < 4) return currentValue;
    if (cvLength < 6) return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 5)}`;

    return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 5)}-${currentValue.slice(5, 9)}`;
}

export const parseSocialSecurityNumber = (value) => {
    if (!value) return '';
    let currentValue = value.replace(/\D/g, '');

    if (currentValue.length > 9) {
        currentValue = currentValue.slice(0, 9);
    }

    return currentValue;
}

export const renderSsnFromRecord = (record) => {
    if (!record || !record?.taxpayer_ssn) return '';

    /** @var record.taxpayer_ssn **/
    return `${record.taxpayer_ssn.slice(0, 3)}-${record.taxpayer_ssn.slice(3, 5)}-${record.taxpayer_ssn.slice(5, 9)}`;
}

export const renderPartialSsnFromRecord = (record) => {
    if (!record) return '';

    /** @var record.taxpayer_ssn **/
    return record.taxpayer_ssn.slice(5, 9);
}