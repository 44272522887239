import {SelectInput} from "react-admin";
import {useQuery} from "react-query";
import React from "react";
import dataProvider from "../../Providers/dataProvider";

export const IvesRequestStatusFilterSelectInput = (props) => {
    const { data, isLoading} = useQuery(
        ['getIvesRequestStatuses'],
        () => dataProvider.getIvesRequestStatuses()
    );

    const ivesRequestStatuses = data?.json?.statuses;

    return (
        <SelectInput
            key={Math.random()}
            label={props.label}
            choices={ivesRequestStatuses}
            name="ives_request_status_id"
            source="ives_request_status_id"
            isLoading={isLoading}
            alwaysOn={true}
        />
    );
};