import {Box, Grid, Typography} from "@mui/material";
import {required, SelectInput, useGetList} from "react-admin";
import React from "react";

export const RequestCreateIvesClientInput = ({ retryData }) => {
    const {data, isLoading} = useGetList('user/ives-clients');

    let ivesClients = [];
    let selectedIvesClientId = null;

    if (data) {
        ivesClients = data;

        if (ivesClients.length === 1) {
            selectedIvesClientId = ivesClients[0].id;
        }
    }

    if (retryData) {
        /** @var retryData.ives_client_id **/
        selectedIvesClientId = retryData.ives_client_id;
    }

    return (<Grid item md={3} sm={6} xs={12}>
        <Grid container direction="column" justifyContent="flex-start">
            <Grid item>
                <Box>
                    <Typography variant="h6">Client</Typography>
                </Box>
            </Grid>
            <Grid item alignItems="stretch">
                <SelectInput
                    name="ives_client_id"
                    source="ives_client_id"
                    optionText="name"
                    helperText={false}
                    validate={[required()]}
                    label="IVES Client"
                    choices={ivesClients}
                    isLoading={isLoading}
                    defaultValue={selectedIvesClientId}
                    sx={
                        {
                            width: "100%",
                            '& .MuiSelect-select': {
                                whiteSpace: "inherit"
                            }
                        }
                    }
                />
            </Grid>
        </Grid>
    </Grid>
    );
}