import * as React from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {Typography} from "@mui/material";


export const FormattedResultsFormSwitches = ({ allForms, selectedForms, setSelectedForms }) => {

    const handleChange = (event) => {
        setSelectedForms({
            ...selectedForms,
            [event.target.name]: event.target.checked,
        });
    };

    return (
        <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">Forms</FormLabel>
            <FormGroup>
                {
                    allForms.map(form => {
                        return (
                            <FormControlLabel
                                control={
                                    <Switch checked={selectedForms[form]} onChange={handleChange} name={form}/>
                                }
                                label={<Typography variant="body2" noWrap>{form}</Typography>}
                            />
                        );
                    })
                }
            </FormGroup>
        </FormControl>
    );
}