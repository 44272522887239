import {
    Show,
    List,
    Edit,
    Create,
    SimpleList,
    SearchInput,
    regex,
    FunctionField, CreateButton,
} from "react-admin";
import { SimpleShowLayout, TextField, DateField, required, SimpleForm, TextInput, EditButton} from "react-admin";
import { ShowButton } from "react-admin";
import {PageTitle} from "../../../Components/PageTitle";
import {Box, Grid, Typography, useMediaQuery} from "@mui/material";
import React from "react";
import {formatPhoneNumber, parsePhoneNumber, renderPhoneNumber} from "../../../Components/Functions/PhoneNumber";
import {EmptyList} from "../../../Components/EmpyList";
import {StateInput} from "../../../Components/Inputs/StateInput";
import {Datagrid} from "../../../Components/Datagrid";
import {TopToolbar} from "../../../Components/TopToolbar";

export const UserIvesClientList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List
            title={<PageTitle label="IVES Clients" />}
            actions={<ListActions />}
            filters={postFilters}
            resource="user/ives-clients"
            hasCreate={false}
            exporter={false}
            empty={<EmptyList resourceLabel="IVES Clients" createButton={true} />}
        >
            {isSmall ? (
                <SimpleList
                    hasBulkActions={false}
                    linkType="show"
                    primaryText={record => record.name}
                    secondaryText={record => record.phone_number}
                />
            ) : (
                <Datagrid bulkActionButtons={false} rowClick="show">
                    <TextField source="name" />
                    <FunctionField render={renderPhoneNumber} label="Phone" />
                    <TextField source="street_name" label="Address" />
                    <TextField source="city_name" label="City" />
                    <TextField source="state_abbreviation" label="State" />
                    <TextField source="zip" label="ZIP Code" />
                    <DateField showTime={true} source="created_at" label="Created"  />
                    <DateField showTime={true} source="updated_at" label="Updated"  />
                </Datagrid>
            )}
        </List>
    );
}

const ListActions = () => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
)

const postFilters = [
    <SearchInput placeholder="Name" source="name" name="name" alwaysOn={true} key={Math.random()} />,
];

export const UserIvesClientShow = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <Show title={<PageTitle />} actions={<ShowActions />}>
            {isSmall ? (
                <SimpleShowLayout>
                    <TextField source="name" />
                    <FunctionField render={renderPhoneNumber} label="Phone" />
                    <TextField source="street_name" label="Address" />
                    <TextField source="city_name" label="City" />
                    <TextField source="state_abbreviation" label="State" />
                    <TextField source="zip" label="ZIP Code" />
                    <DateField showTime={true} source="created_at" label="Created" />
                    <DateField showTime={true} source="updated_at" label="Updated" />
                </SimpleShowLayout>
            ) : (
                <Grid container>
                    <Grid item xs={6}>
                        <Box sx={{padding: 1}}>
                            <Typography variant="h6">Client</Typography>
                        </Box>
                        <SimpleShowLayout>
                            <TextField source="name"/>
                            <FunctionField render={renderPhoneNumber} label="Phone" />
                            <TextField source="street_name" label="Address" />
                            <TextField source="city_name" label="City" />
                            <TextField source="state_abbreviation" label="State" />
                            <TextField source="zip" label="ZIP Code" />
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{padding: 1}}>
                            <Typography variant="h6">Status</Typography>
                        </Box>
                        <SimpleShowLayout>
                            <DateField showTime={true} source="created_at" label="Created" />
                            <DateField showTime={true} source="updated_at" label="Updated" />
                        </SimpleShowLayout>
                    </Grid>
                </Grid>
            )}
        </Show>
    );
}

export const UserIvesClientCreate = () => {
    return (
        <Create title={<PageTitle label="Create IVES Client" />} redirect="show">
            <IvesClientForm />
        </Create>
    );
};

export const UserIvesClientEdit = () => {
    return (
        <Edit title={<PageTitle />} mutationMode="pessimistic" actions={<EditActions />} redirect="show">
            <IvesClientForm />
        </Edit>
    );
};

const IvesClientForm = () => (
    <SimpleForm>
        <TextInput source="name" validate={[required()]} name={"name"} style={{ width : '300px' }} />
        <TextInput
            source="phone_number"
            validate={[required()]}
            name={"phone_number"}
            style={{ width : '300px' }}
            format={formatPhoneNumber}
            parse={parsePhoneNumber}
        />
        <TextInput source="street_name" validate={[required()]} name={"street_name"} style={{ width : '300px' }} />
        <TextInput source="city_name" validate={[required()]} name={"city_name"} style={{ width : '300px' }} />
        <StateInput
            source="state_abbreviation"
            validate={[required()]}
            name={"state_abbreviation"}
            label="State"
            style={{ width : '300px' }}
        />
        <TextInput source="zip" validate={[required(), regex(/^\d{5}$/, 'Must be a valid ZIP Code')]} name={"zip"} style={{ width : '300px' }} label="ZIP Code" />
    </SimpleForm>
);

const ShowActions = () => (
    <TopToolbar backButton={true} >
        <EditButton />
    </TopToolbar>
);

const EditActions = () => (
    <TopToolbar backButton={true}>
        <ShowButton />
    </TopToolbar>
);