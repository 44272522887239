import React from "react";
import {
    List,
    NumberField,
    TextField,
} from "react-admin";
import {Datagrid} from "../../../../Components/Datagrid";

export const IvesRequestBillingMatrix = ({ institutionId }) => {
    if (!institutionId) {
        return <></>
    }

    return (
        <React.Fragment>
            <List
                resource="affiliate/ives-request-billing-matrix"
                filter={{ institution_id: institutionId }}
                empty={false}
                pagination={false}
                exporter={false}
                hasCreate={false}
                actions={false}
            >
                <Datagrid bulkActionButtons={false} rowClick={false}>
                    <TextField source="minimum" sortable={false} />
                    <TextField source="maximum" sortable={false} />
                    <NumberField source="year1_amount" label="1 Yr" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} sortable={false} />
                    <NumberField source="year2_amount" label="2 Yrs" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} sortable={false} />
                    <NumberField source="year3_amount" label="3 Yrs" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} sortable={false} />
                    <NumberField source="year4_amount" label="4 Yrs" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} sortable={false} />
                    <NumberField source="year5_amount" label="5 Yrs" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} sortable={false} />
                    <NumberField source="year6_amount" label="6 Yrs" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} sortable={false} />
                    <NumberField source="year7_amount" label="7 Yrs" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} sortable={false} />
                    <NumberField source="year8_amount" label="8 Yrs" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} sortable={false} />
                    <NumberField source="year9_amount" label="9 Yrs" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} sortable={false} />
                    <NumberField source="year10_amount" label="10 Yrs" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} sortable={false} />
                </Datagrid>
            </List>
        </React.Fragment>
    );
};