import {Show, List, Edit, Create, SearchInput} from "react-admin";
import { SimpleShowLayout, TextField, DateField, SimpleForm, TextInput, ArrayField, } from "react-admin";
import {PageTitle} from "../../../Components/PageTitle";
import {EditActions, ShowActions} from "../../../Components/Actions";
import React from "react";
import {Datagrid} from "../../../Components/Datagrid";

export const UserBranchList = () => {
    return (
        <List filters={postFilters} title="Branches" resource="user/branches" hasCreate={true} exporter={false}>
            <Datagrid bulkActionButtons={false} rowClick="show">
                <TextField source="name" />
                <DateField showTime={true} source="created_at" label="Created"  />
                <DateField showTime={true} source="updated_at" label="Updated"  />
            </Datagrid>
        </List>
    );
};

const postFilters = [
    <SearchInput placeholder="Name" source="name" name="name" alwaysOn={true} key={Math.random()} />,
];

export const UserBranchCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="name" required={true} name={"name"} style={{ width : '300px' }} />
        </SimpleForm>
    </Create>
);

export const UserBranchShow = () => (
    <Show title={<PageTitle />} actions={<ShowActions />}>
        <SimpleShowLayout>
            <TextField source="name"/>
            <DateField showTime={true} source="created_at" label="Created" />
            <DateField showTime={true} source="updated_at" label="Updated" />
            <ArrayField source="users">
                <Datagrid bulkActionButtons={false} rowClick={userRowClick}>
                    <TextField source="name" />
                    <TextField source="email" />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);

export const UserBranchEdit = () => (
    <Edit title={<PageTitle />} mutationMode="pessimistic" actions={<EditActions />} redirect="show">
        <SimpleForm>
            <TextInput source="name" required={true} name={"name"} style={{ width : '300px' }} />
        </SimpleForm>
    </Edit>
);

const userRowClick = (id) => {
    return "/user/users/" + id + "/show";
};