import {
    Show,
    SimpleShowLayout,
    List,
    TextField,
    DateField,
    required,
    SimpleForm,
    Edit,
    TextInput,
    Create,
    ReferenceField,
    NumberField,
    SelectInput,
    ReferenceArrayInput,
    NumberInput,
    Link,
    useRecordContext,
    FunctionField,
    useShowController,
} from "react-admin";
import {PageTitle} from "../../../Components/PageTitle";
import {EditActions, ShowActions} from "../../../Components/Actions";
import React from "react";
import {BranchFilterSelectInput} from "../../../Components/Filters/BranchFilterSelectInput";
import {BranchInput} from "../../../Components/Inputs/BranchInput";
import {Datagrid} from "../../../Components/Datagrid";

const BranchLink = () => {
    const record = useRecordContext();

    if (!record || !record?.line_item) {
        return <></>;
    }

    const link = '/invoices/' + record?.line_item.invoice_id + '/show/'
    return (
        <Link to={link}>{record?.line_item.invoice_id}</Link>
    )
}

export const BranchCreditList = () => {
    return (
        <List
            title={<PageTitle label="Branch Credits" />}
            filters={postFilters}
            resource="branch-credits"
            sort={{ field: 'created_at', order: 'DESC' }}
            hasCreate={true}
            empty={false}
            exporter={false}
        >
            <Datagrid bulkActionButtons={false} rowClick="show">
                <TextField source="branch.institution.name" label="Institution" />
                <TextField source="branch.name" label="Branch" />
                <TextField source="name" label="Name" sortable={false} />
                <NumberField source="amount" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} />
                <DateField showTime={true} source="created_at" label="Created"  />
                <DateField showTime={true} source="updated_at" label="Updated"  />
            </Datagrid>
        </List>
    );
};

const postFilters = [
    <BranchFilterSelectInput label="Branch" alwaysOn={true} key={Math.random()} type='admin' />,
];

export const BranchCreditCreate = () => {
    return (
        <Create>
            <SimpleForm>
                <ReferenceArrayInput name="Institution" source="branch.institution_id" reference="institutions" perPage={1000} sort={{ field: "name", order: "ASC" }}>
                    <SelectInput optionText="name" name="InstitutionSelect" label="Institution" validate={required()} style={{ width : '300px' }} />
                </ReferenceArrayInput>
                <BranchInput />
                <TextInput name="name" source="name" required={true} style={{ width : '300px' }} />
                <TextInput name="description" source="description" style={{ width : '300px' }} />
                <NumberInput name="amount" source="amount" required={true} style={{ width : '300px' }} />
            </SimpleForm>
        </Create>
    );
};

export const BranchCreditShow = () => {
    const record = useShowController().record;

    const showEditButton = !record?.line_item;

    return (
        <Show title={<PageTitle />} actions={<ShowActions showEditButton={showEditButton} />}>
            <SimpleShowLayout>
                <ReferenceField source="branch_id" reference="branches" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="name"/>
                <TextField source="description"/>
                <NumberField source="amount" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} />
                <FunctionField
                    label="Invoice"
                    render={() => <BranchLink />}
                />
                <DateField showTime={true} source="created_at" label="Created" />
                <DateField showTime={true} source="updated_at" label="Updated" />
            </SimpleShowLayout>
        </Show>
    );
};

export const BranchCreditEdit = () => (
    <Edit title={<PageTitle />} mutationMode="pessimistic" actions={<EditActions />} redirect="show">
        <SimpleForm>
            <TextInput name="name" source="name" required={true} style={{ width : '300px' }} />
            <TextInput name="description" source="description" style={{ width : '300px' }} />
            <NumberInput name="amount" source="amount" required={true} style={{ width : '300px' }} />
        </SimpleForm>
    </Edit>
);