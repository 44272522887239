import * as React from 'react';
import {DateField, FunctionField, List, SimpleList, TextField,} from "react-admin";
import {renderSsnFromRecord} from "../../../Components/Functions/SocialSecurityNumber";
import {renderYearsFromRecord} from "../../../Components/Functions/Years";
import {renderFormFromRecord} from "../../../Components/Functions/Form";
import {renderTaxpayerNameFromRecord} from "../../../Components/Functions/TaxpayerName";
import dataProvider from "../../../Providers/dataProvider";
import {Datagrid} from "../../../Components/Datagrid";

const IvesRequests = ({ condensed = false }) => {
    const role = dataProvider.getUserRole();

    let resource = 'ives-requests';
    if (role !== 'Admin') {
        resource = role.toLowerCase() + '/' + resource;
    }

    let ivesRequestStatusSource = "ives_request_status.name";
    if (role === 'User') {
        ivesRequestStatusSource = "ives_request_status";
    }

    const smallTertiaryText = (record) => {
        if (!record) {
            return '';
        }

        const years = record.ives_request_years.map(t => t.year).toString();

        /** @var record.ives_request_form **/
        const formName = record.ives_request_form?.name ?? '';

        return formName + ' - ' + years;
    }

    return (
        <List
            title=" "
            resource={resource}
            perPage={5}
            sort={{ field: 'id', order: 'DESC' }}
            hasCreate={false}
            pagination={false}
            exporter={false}
            empty={false}
            queryOptions={
                {
                    refetchInterval: 5000,
                }
            }
        >
            {condensed ? (
                <SimpleList
                    primaryText={(record) => record.ives_client.name}
                    secondaryText={renderTaxpayerNameFromRecord}
                    tertiaryText={smallTertiaryText}
                />
            ) : (
                <Datagrid bulkActionButtons={false} rowClick="show" >
                    <TextField source="id" label="ID" sortable={false} />
                    <TextField source="ives_client.name" label="Client" sortable={false} />
                    <FunctionField render={renderTaxpayerNameFromRecord} label="Taxpayer" sortable={false} />
                    <FunctionField render={renderSsnFromRecord} label="SSN" sortable={false} />
                    <FunctionField render={renderFormFromRecord} label="Form" sortable={false} />
                    <FunctionField render={renderYearsFromRecord} label="Years" sortable={false} />
                    <TextField source={ivesRequestStatusSource} label="Status" sortable={false} />
                    <DateField showTime={true} source="created_at" label="Created"  sortable={false} />
                </Datagrid>
            )}
        </List>
    );
};


export default IvesRequests;