import {SelectInput} from "react-admin";
import {useQuery} from "react-query";
import React from "react";
import dataProvider from "../../Providers/dataProvider";

export const ExpandedIvesRequestStatusFilterSelectInput = (props) => {
    const { data, isSuccess, isLoading, isError} = useQuery(
        ['getExpandedIvesRequestStatuses'],
        () => dataProvider.getExpandedIvesRequestStatuses()
    );

    const expandedIvesRequestStatuses = data?.json?.statuses;

    if (isLoading || isError || !expandedIvesRequestStatuses) {
        return <span key={Math.random()}>Loading...</span>
    }

    if (isSuccess) {
        return (
            <SelectInput
                key={Math.random()}
                label={props.label}
                choices={expandedIvesRequestStatuses}
                name="expanded_ives_request_status_id"
                source="expanded_ives_request_status_id"
                isLoading={isLoading}
                alwaysOn={true}
            />
        );
    }
};