import {useQuery} from "react-query";
import dataProvider from "../../../../Providers/dataProvider";
import {Box, Grid, Paper, Typography} from "@mui/material";
import {LineChart} from "@mui/x-charts";
import React from "react";

export const IvesRequestsByMonth = () => {
    const { data, isLoading} = useQuery(
        ['getIvesRequestsByMonth'],
        () => dataProvider.getIvesRequestsByMonth('user')
    );

    const report = data?.json;

    if (isLoading || !report) {
        return <span key={Math.random()}>Loading...</span>
    }

    const months = Object.keys(report.created);
    const created = Object.values(report.created);
    const failed = Object.values(report.failed);
    const completed = Object.values(report.completed);
    const expired = Object.values(report.expired);

    const colors = ['#1976d2', '#cc3939', 'green', 'black'];

    return (
        <Paper elevation={2}>
            <Box>
                <Typography variant="h6">
                    IVES Requests by Month
                </Typography>
            </Box>
            <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                <Grid item>
                    <LineChart
                        width={1200}
                        height={300}
                        colors={colors}
                        series={[
                            {data: created, label: 'Created'},
                            {data: failed, label: 'Failed'},
                            {data: completed, label: 'Completed'},
                            {data: expired, label: 'Expired'},
                        ]}
                        xAxis={[{scaleType: 'point', data: months}]}
                    />
                </Grid>
            </Grid>
        </Paper>
)
}