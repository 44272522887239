import React from "react";
import {IconButton, Tooltip, tooltipClasses, Typography} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import {styled} from "@mui/material/styles";

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

export const ToolTip = ({ title, text }) => {
    return (
        <HtmlTooltip
            title={
                <React.Fragment>
                    <Typography color="inherit">{title}</Typography>
                    <div dangerouslySetInnerHTML={{ __html: text}} />
                </React.Fragment>
            }
        >
            <IconButton>
                <HelpIcon fontSize="small"/>
            </IconButton>
        </HtmlTooltip>
    );
};