import * as React from 'react';
import {forwardRef} from 'react';
import {AppBar, Layout, UserMenu, useLogout, TitlePortal, useRedirect} from 'react-admin';
import MainMenu from '../Menus/MainMenu';
import {Box, MenuItem, Typography, useMediaQuery} from '@mui/material';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import TuneIcon from '@mui/icons-material/Tune';
import SecurityUpdateIcon from '@mui/icons-material/SecurityUpdate';
import {makeApiCall} from "../Providers/httpClient";
import {EnvironmentLabel} from "../Components/Labels/EnvironmentLabel";
import NotificationsDrawer from "../Components/Notifications/NotificationsDrawer";
import appPackage from '../../package.json';
import {useCacheBuster} from "react-cache-buster";

const MyLogoutButton = forwardRef((props, ref) => {
    const logout = useLogout();
    const handleClick = () => logout();

    return (
        <MenuItem onClick={handleClick} ref={ref}>
            <ExitIcon />&nbsp;&nbsp;Logout
        </MenuItem>
    );
});

const MyProfileButton = forwardRef((props, ref) => {
    const redirect = useRedirect();
    const handleClick = () => redirect('/user/profile/1/show');

    return (
        <MenuItem onClick={handleClick} ref={ref}>
            <TuneIcon />&nbsp;&nbsp;Profile
        </MenuItem>
    );
});

const UpdateButton = () => {
    const { checkCacheStatus } = useCacheBuster();
    const [ hideButton, setHideButton ] = React.useState(true);
    const [ running, setRunning ] = React.useState(false);
    const [ ran, setRan ] = React.useState(false);

    function reloadPage () {
        checkCacheStatus();
        window.location.reload(true);
    }

    const checkVersion = () => {
        if (!running) {
            setRunning(true);
            fetch(
                `/meta.json?ver=${Date.now()}`,
                {
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
                .then((response) => response.json())
                .then((meta) => {
                    if (meta.version !== appPackage.version) {
                        setTimeout(() => {
                            setHideButton(false);
                        }, 60000);
                    }
                    setRunning(false);
                    setTimeout(() => {
                        checkVersion();
                    }, 60000);
                });
        }
    }

    if (!ran) {
        setRan(true);
        checkVersion();
    }

    if (hideButton) {
        return <></>;
    }

    return (
        <MenuItem onClick={reloadPage} >
            <SecurityUpdateIcon />&nbsp;&nbsp;Update Available
        </MenuItem>
    );
};

const MyUserMenu = () => {
    const user = JSON.parse(localStorage.getItem('user'));

    return (
        <UserMenu>
            <MenuItem divider={true}>{user.name}</MenuItem>
            <MyProfileButton />
            <MyLogoutButton />
        </UserMenu>
    );
}

const MyAppBar = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const trueLabel = isSmall ? 'T' : 'True';
    const dataLabel = isSmall ? 'D' : 'Data';

    const showUpdateButton = process.env.REACT_APP_ENVIRONMENT === 'production';

    return (
        <AppBar userMenu={<MyUserMenu/>} sx={ { backgroundColor: "#353b46" } } >
            <TitlePortal />
            <Box>
                <Typography variant="h5" >
                    <span className="logo" style={{ color: "white" }} >{trueLabel}</span><span className="logo" style={{ color: "#a0dc29" }} >{dataLabel}</span><span className="logo" style={{ color: "#247dd4" }} >.ai</span>
                    <EnvironmentLabel />
                </Typography>
            </Box>
            <Box flex="1"/>
            { (showUpdateButton) ? (
                <UpdateButton />
                ) : (<></>)
            }
            <NotificationsDrawer />
            <Typography variant="h5" sx={{paddingRight: 1, paddingLeft: 1}}>|</Typography>
        </AppBar>
    )
};

const MyLayout = (props) => {
    const onMouseDownCaptureHandler = () => {
        return makeApiCall(`/activity`, {method: 'GET'}).then().catch(); 
    };
    
    return (
        <Layout onMouseDownCapture={onMouseDownCaptureHandler} {...props} appBar={MyAppBar} menu={MainMenu}/>
    );
}

export default MyLayout;