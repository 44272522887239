import {FunctionField, Labeled, useRecordContext} from "react-admin";
import React from "react";
import {ToolTip} from "../../../../Components/ToolTip";
import {IvesRequestStatusTooltipText} from "../../../../Components/IvesRequestStatusTooltipText";
import {getDatagridRowTextColor} from "../../../../Components/Functions/DatagridRowSx";

export const RequestStatusField = () => {
    const record = useRecordContext();

    /** @var record.ives_request_status **/
    if (!record || !record.ives_request_status) {
        return '';
    }

    const status = record.ives_request_status.name;
    const tooltipText = IvesRequestStatusTooltipText[status];

    return (
        <>
            <Labeled>
                <FunctionField 
                    render={(record) => {return record.ives_request_status.name;}}
                    label="Status"
                    component="pre"
                    sx={{ color: getDatagridRowTextColor(record), fontWeight: 'bold' }}
                />
            </Labeled>
            <ToolTip
                title={status}
                text={tooltipText}
            />
        </>
    );
};