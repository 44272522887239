import {List, ReferenceField, useGetList, useRecordContext, useResourceContext} from "react-admin";
import { TextField } from "react-admin";
import React, {useState} from "react";
import { useParams } from "react-router-dom";
import {Button, Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {PageTitle} from "../../../Components/PageTitle";
import {Datagrid} from "../../../Components/Datagrid";

/** @var record.id */
/** @var record.message */

export const LogsList = () => {
    return (
        <List title={<PageTitle label="Logs" />} resource="logs" pagination={false} sort={{ field: 'created_at', order: 'DESC' }} hasCreate={false} exporter={false}>
            <Datagrid bulkActionButtons={false} rowClick={RowClick}>
                <TextField source="id" label="ID" sortable={false} />
                <TextField source="name" label="Log" />
            </Datagrid>
        </List>
    );
};

const RowClick = (id, resource, record) => {
    /** @var slug */
    return "/logs/" + record.slug + '/show/';
}

export const LogsShow = () => {
    let { id } = useParams();
    const [page] = useState(1);
    const [perPage] = useState(10);
    let resource = useResourceContext();
    resource = resource + '/' + id;

    const { error} = useGetList(
        resource, {
            pagination: { page, perPage },
            sort: { field: 'id', order: 'DESC' }
        }
    );

    if (error) {
        return <div><span key={Math.random()}>{error}</span></div>
    }

    return (
        <List title={<PageTitle label="Log Entries" />} resource={resource} exporter={false} >
            <Datagrid bulkActionButtons={false}>
                <TextField source="id" />
                <ReferenceField source="user_id" reference="users" label="User" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <MessageTextField source="record" />
            </Datagrid>
        </List>
    );
};

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

function SimpleDialog(props) {
    const record = useRecordContext(props);
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Log Entry {record.id}
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <span key={Math.random()}>{record.message}</span>
            </DialogContent>
        </Dialog>
    );
}

const MessageTextField = (props) => {
    const record = useRecordContext(props);
    const [open, setOpen] = React.useState(false);

    const croppedMessage = record.message.substring(0, 200);
    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <span key={Math.random()}>
            <SimpleDialog
                open={open}
                onClose={handleClose}
            />
            {croppedMessage}
            <Button
                onClick={handleClickOpen}
            >See more</Button>
        </span>
    )
};