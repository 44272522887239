import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {Badge, MenuItem} from "@mui/material";
import {useRedirect} from "react-admin";
import {useMutation, useQuery} from "react-query";
import dataProvider from "../../Providers/dataProvider";
import {NotificationsDrawerContent} from "./NotificationsDrawerContent";
import NotificationMessage from "./NotificationMessage";

export default function NotificationsDrawer() {
    const [initialLoad, setInitialLoad] = React.useState(true);
    const [pauseLoad, setPauseLoad] = React.useState(false);
    const [notificationsDrawerOpen, setNotificationsDrawerOpen] = React.useState(false);
    const [dataForList, setDataForList] = React.useState([]);
    const [newNotificationCount, setNewNotificationCount] = React.useState(0);
    const [openMessage, setOpenMessage] = React.useState(false);
    const [messageContent, setMessageContent] = React.useState('');
    const redirect = useRedirect();

    const setTabTitle = ( count ) => {
        let title = "TrueData";

        const userSettings = JSON.parse(localStorage.getItem('user'));
        if (userSettings) {
            if (userSettings.environment === 'Sandbox') {
                title += " Sandbox"
            } else {
                title += " Admin"
            }
        }

        if (count > 0) {
            title = "(" + count + ") " + title;
        }

        document.title = title;
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setNotificationsDrawerOpen( open );
    };

    const handleCloseMessage = () => {
        setOpenMessage(false);
    }

    const { mutate: markAsRead } = useMutation({
            mutationFn: (notificationId) => {
                return dataProvider.markNotificationAsRead(notificationId);
            }
        }
    );

    useQuery({
            refetchInterval: 4000,
            queryKey: ['getNotifications'],
            queryFn: () => {
                return dataProvider.getNotifications();
            },
            onSuccess: (rawNotificationData) => {
                if (!pauseLoad) {
                    if (rawNotificationData.json !== dataForList) {
                        setDataForList(rawNotificationData.json);
                    }
                }
            }
        }
    );

    if (initialLoad) {
        setInitialLoad(false);
        setTabTitle(0);
    }

    const markAsReadAndRedirect = (notification, link) => {
        notification.read = 1;
        updateNotificationInState(notification);
        markAsRead(notification.id);

        if (!notification.entity_id) {
            return;
        }

        if (link) {
            localStorage.setItem("show-green-arrow", JSON.stringify(true));
            redirect(link);
        }

        setNotificationsDrawerOpen(false);
    };

    const updateNotificationInState = (notification) => {
        setPauseLoad(true);
        const notifications = [...dataForList];
        const oldNotification = notifications.find((obj) => obj.id === notification.id);
        Object.assign(oldNotification, notification);
        setDataForList(notifications);
        setTimeout(() => {
            setPauseLoad(false);
        }, 4001);
    };

    let updatedNotificationCount = 0;
    if (dataForList) {
        dataForList.forEach(notification => {
            if (notification.read === 0) {
                updatedNotificationCount++;
            }
        });
    }

    if (newNotificationCount !== updatedNotificationCount) {
        setNewNotificationCount(updatedNotificationCount);
        setTabTitle(updatedNotificationCount);
    }

    return (
        <div>
            <NotificationMessage
                content={messageContent}
                open={openMessage}
                handleClose={handleCloseMessage}
            />
            <React.Fragment key={"right"}>
                <Divider orientation="vertical" />
                <MenuItem onClick={toggleDrawer( true)} >
                    <Badge badgeContent={newNotificationCount} color="primary">
                        <NotificationsIcon />
                    </Badge>
                </MenuItem>
                <Drawer
                    anchor={'right'}
                    open={notificationsDrawerOpen}
                    onClose={toggleDrawer(false)}
                >
                    <NotificationsDrawerContent
                        dataForList={dataForList}
                        markAsReadAndRedirect={markAsReadAndRedirect}
                        updateNotificationInState={updateNotificationInState}
                        setOpenMessage={setOpenMessage}
                        setMessageContent={setMessageContent}    
                        toggleDrawer={toggleDrawer}
                    />                        
                </Drawer>
            </React.Fragment>
        </div>
    );
}