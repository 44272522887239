import {
    Show,
    Edit,
    BooleanField,
    BooleanInput,
    TextField,
    useNotify,
    useRedirect,
    SaveButton,
    Toolbar,
    TabbedForm,
    TabbedShowLayout,
    useShowController,
    useEditController,
} from "react-admin";
import { SimpleShowLayout, TextInput, EditButton} from "react-admin";
import { ShowButton } from "react-admin";
import {PageTitle} from "../../../Components/PageTitle";
import {Box, Grid, Typography, TextField as TF} from "@mui/material";
import React, {useState, useEffect} from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {useMutation} from "react-query";
import dataProvider from "../../../Providers/dataProvider";
import RefreshIcon from '@mui/icons-material/Refresh';
import LoadingButton from "@mui/lab/LoadingButton";
import {TopToolbar} from "../../../Components/TopToolbar";

export const UserIvesSettingsShow = () => {
    /** @var showController.record.taxpayer_notification_email_preview */
    /** @var showController.record.taxpayer_email_notification_template */
    /** @var showController.record.should_notify_taxpayers_via_email */
    /** @var showController.record.should_use_custom_taxpayer_email_notification_template */
    /** @var showController.record.should_notify_user_via_email_when_complete */
    const showController = useShowController();
    let taxpayer_notification_email_subject = '';
    let taxpayer_notification_email_preview = '';
    let taxpayer_notification_email_preview_opacity = 0;
    if (showController?.record?.taxpayer_email_notification_template) {
        taxpayer_notification_email_preview = showController.record.taxpayer_notification_email_preview;
        taxpayer_notification_email_subject = 'SUBJECT: ' + JSON.parse(showController.record.taxpayer_email_notification_template).subject;
        if (showController.record.should_notify_taxpayers_via_email) {
            taxpayer_notification_email_preview_opacity = 1;
        } else {
            taxpayer_notification_email_preview_opacity = 0.2;
        }
    }
    return (
        <Show title={<PageTitle />} actions={<ShowActions />}>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label="Taxpayer Notifications">
                    <Grid container>
                        <Grid item xs={6}>
                            <Box sx={{padding: 1}}>
                                <Typography variant="h6">Email</Typography>
                            </Box>
                            <SimpleShowLayout>
                                <BooleanField source="should_notify_taxpayers_via_email" />
                                <BooleanField source="should_use_custom_taxpayer_email_notification_template" />
                                { showController?.record?.should_use_custom_taxpayer_email_notification_template && (
                                <Box>
                                    <Typography className="RaLabeled-label"><span>Email template</span></Typography>
                                    <div style={{ border: '1px solid black', opacity: taxpayer_notification_email_preview_opacity }} >
                                        <Typography variant="h6">{taxpayer_notification_email_subject}</Typography>
                                        <div dangerouslySetInnerHTML={{ __html: taxpayer_notification_email_preview }} />
                                    </div>
                                </Box>
                                )}
                            </SimpleShowLayout>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{padding: 1}}>
                                <Typography variant="h6">SMS (coming soon)</Typography>
                            </Box>
                            <SimpleShowLayout>
                                <BooleanField source="should_notify_taxpayers_via_sms" label="Should notify taxpayers via sms (coming soon)" />
                            </SimpleShowLayout>
                        </Grid>
                    </Grid>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Owner Notifications">
                    <BooleanField source="should_notify_user_via_email_when_complete" label="Notify me via email when results are ready"/>
                    <TextField source="notification_endpoint" />
                    <TextField source="notification_token" />
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </Show>
    );
}

export const UserIvesSettingsEdit = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const editController = useEditController();
    const [pageLoaded, setPageLoaded] = useState(false);
    const [subject, setSubject] = useState('');
    const [greeting, setGreeting] = useState('');
    const [content, setContent] = useState('');
    const [callToActionLabel, setCallToActionLabel] = useState('');
    const [callToActionLink, setCallToActionLink] = useState('');
    const [signature, setSignature] = useState('');
    const [newSegments, setNewSegments] = useState('');
    const [customTaxpayerEmailNotificationTemplateVisibility, setCustomTaxpayerEmailNotificationTemplateVisibility] = useState('hidden');

    const onSuccess = () => {
        notify('Settings updated successfully', { type: "success"});
        redirect('/user/ives-settings/1/show');
    };

    if (!pageLoaded && editController.record) {
        const initialSegments = JSON.parse(editController.record.taxpayer_email_notification_template);

        setSubject(initialSegments.subject);
        setGreeting(initialSegments.greeting);
        setContent(initialSegments.content);
        setCallToActionLabel(initialSegments.call_to_action_label);
        setCallToActionLink(initialSegments.call_to_action_link);
        setSignature(initialSegments.signature);

        setPageLoaded(true);
    }

    useEffect(() => {
        setNewSegments(
            {
                'subject': subject,
                'greeting': greeting,
                'content': content,
                'call_to_action_label': callToActionLabel,
                'call_to_action_link': callToActionLink,
                'signature': signature
            }
        );

    }, [subject, greeting, content, callToActionLabel, callToActionLink, signature, setNewSegments]);
    
    const CustomTaxpayerEmailNotificationTemplateMonitor = () => {
        const shouldUseCustomTaxpayerEmailNotificationTemplate = useWatch({ name: 'should_use_custom_taxpayer_email_notification_template' });
        setCustomTaxpayerEmailNotificationTemplateVisibility(shouldUseCustomTaxpayerEmailNotificationTemplate ? 'visible' : 'hidden');

        return (
            <></>
        );
    }

    return (
        <Edit title={<PageTitle />} mutationMode="pessimistic" mutationOptions={{ onSuccess }} actions={<EditActions />} redirect="show" >
            <TabbedForm toolbar={<TabbedFormToolbar />} shouldUnregister>
                <TabbedForm.Tab label="Taxpayer Email Notifications">
                    <Grid container >
                        <Grid item xs={12}>
                            <Box>
                                <BooleanInput name="should_notify_taxpayers_via_email" source="should_notify_taxpayers_via_email"  />
                                <BooleanInput name="should_use_custom_taxpayer_email_notification_template" source="should_use_custom_taxpayer_email_notification_template" />
                            </Box>
                            <Box>
                                <CustomTaxpayerEmailNotificationTemplateMonitor />
                                <Grid container spacing={1} sx={{ visibility: customTaxpayerEmailNotificationTemplateVisibility }} >
                                    <Grid item xs={4}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <TemplateInput name="subject" label="Subject" value={subject} onChange={setSubject} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TemplateInput name="greeting" label="Greeting" value={greeting} onChange={setGreeting} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TemplateInput name="content" label="Main Content" value={content} onChange={setContent} multiline={true} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TemplateInput name="call_to_action_label" label="Button Label" value={callToActionLabel} onChange={setCallToActionLabel} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TemplateInput name="call_to_action_link" label="Button Link" value={callToActionLink} onChange={setCallToActionLink} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TemplateInput name="signature" label="Signature" value={signature} onChange={setSignature} />
                                            </Grid>
                                            <Grid item xs={12} sx={{ border: '1px black solid', padding: '2px' }}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}><Typography variant="h6">Dynamic Variables</Typography></Grid>
                                                    <Grid item xs={4}><Box>@taxpayer_name@</Box></Grid>
                                                    <Grid item xs={6}><Box>Full name of tax payer</Box></Grid>
                                                    <Grid item xs={4}><Box>@client_name@</Box></Grid>
                                                    <Grid item xs={6}><Box>Full name of client</Box></Grid>
                                                    <Grid item xs={4}><Box>@link@</Box></Grid>
                                                    <Grid item xs={6}><Box>Link to IRS Taxpayer Authorization inbox</Box></Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TaxpayerEmailNotificationTemplate newSegments={newSegments} pageLoaded={pageLoaded} />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TaxpayerEmailNotificationTemplatePreview newSegments={newSegments} />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Taxpayer Sms Notifications (coming soon)">
                    <BooleanInput label="Should notify taxpayers via sms (coming soon)" name="should_notify_taxpayers_via_sms" source="should_notify_taxpayers_via_sms" disabled={true} />
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Owner Notifications">
                    <BooleanInput label="Notify my via email when results are ready" name="should_notify_user_via_email_when_complete" source="should_notify_user_via_email_when_complete" />
                    <TextInput source="notification_endpoint" name="notification_endpoint" style={{ width : '600px' }} />
                    <TextInput source="notification_token" name="notification_token" style={{ width : '600px' }} />
                </TabbedForm.Tab>
            </TabbedForm>
        </Edit>
    );
};

const TemplateInput = ({ name, label, value, multiline = false, onChange}) => {
    return (
        <TF
            name={name}
            label={label}
            required={true}
            value={value}
            fullWidth={true}
            helperText={false}
            multiline={multiline}
            onChange={e => onChange(e.target.value)}
        />
    );
}

const TaxpayerEmailNotificationTemplate = ({ newSegments, pageLoaded }) => {
    const { setValue } = useFormContext();
    
    useEffect(() => {
        if (pageLoaded) {
            setValue(
                'taxpayer_email_notification_template',
                JSON.stringify(newSegments, null, 2),
                {
                    shouldDirty: true,
                    shouldValidate: true
                }
            );
        }

    }, [newSegments, setValue, pageLoaded]);

    return (
        <TextInput
            name="taxpayer_email_notification_template"
            source="taxpayer_email_notification_template"
            sx={{ visibility: 'hidden' }}
            disabled={true}
            fullWidth={true}
        />
    );
}

const TaxpayerEmailNotificationTemplatePreview = ({ newSegments }) => {
    const notify = useNotify();
    const [initialLoad, setInitialLoad] = useState(true);
    const [taxpayerNotificationEmailPreview, setTaxpayerNotificationEmailPreview] = useState('');
    const [taxpayerNotificationEmailSubject, setTaxpayerNotificationEmailSubject] = useState('');
    const [taxpayerNotificationEmailPreviewOpacity, setTaxpayerNotificationEmailPreviewOpacity] = useState(0.0);
    const { mutate, isLoading } = useMutation(
        () => dataProvider.getTaxpayerNotificationEmailPreview(newSegments),
        {
            onSuccess: (data) => {
                setTaxpayerNotificationEmailPreview(data.json);
                setTaxpayerNotificationEmailSubject(newSegments?.subject);
                setTaxpayerNotificationEmailPreviewOpacity(1.0);
            },
            onError: () => {
                notify('Could not generate preview', { type: "error"});
                setTaxpayerNotificationEmailPreviewOpacity(0.0);
            }
        }
    );

    const refreshPreview = () => {
        setTaxpayerNotificationEmailPreview('');
        setTaxpayerNotificationEmailSubject('');
        setTaxpayerNotificationEmailPreviewOpacity(0.1);
        mutate();
    }

    if (initialLoad && newSegments) {
        setInitialLoad(false);
        mutate();
    }

    const RefreshButton = () => {
        return (
            <LoadingButton
                textalign="center"
                onClick={refreshPreview}
                endIcon={<RefreshIcon />}
                loading={isLoading}
                size="small"
                sx={{lineHeight: 1.5}}
                aria-label="refresh"
            >
                Refresh
            </LoadingButton>
        );
    }

    return (
        <Box>
            <Grid container>
                <Grid item xs={11}>
                    <Typography className="RaLabeled-label"><span>PREVIEW</span></Typography>
                </Grid>
                <Grid item xs={1}>
                    <RefreshButton />
                </Grid>
            </Grid>
            <div style={{ border: '1px solid black', opacity: taxpayerNotificationEmailPreviewOpacity }} >
                <Typography variant="h6">{taxpayerNotificationEmailSubject}</Typography>
                <div dangerouslySetInnerHTML={{ __html: taxpayerNotificationEmailPreview }} />
            </div>
        </Box>
    );
}

const TabbedFormToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const ShowActions = () => (
    <TopToolbar backButton={true}>
        <EditButton />
    </TopToolbar>
);

const EditActions = () => (
    <TopToolbar>
        <ShowButton />
    </TopToolbar>
);